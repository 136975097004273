/**
 * Copyright © 2021, AMN Healthcare, Inc. All rights reserved.
 */

import React from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import {
  MenuContent,
  MenuItem,
  MenuSeperator,
  MenuTrigger,
  MenuPopover,
} from '@/ui/menu';
import {
  MdInfoOutline,
  MdSettings,
  MdLogout,
  MdOutlineSwapHoriz,
} from 'react-icons/md';
import {
  openSessionSocket,
  closeSessionSocket,
} from '@/protected/apis/protectedApiWebsockets';
import { Button } from '@/ui/button';
import { toast } from 'sonner';
import { svenErrorMessage } from '@/api/getError';
import { HeaderNavLink, Header } from '@/shared/Header';
import { BasicLayout } from '@/shared/BasicLayout';
import { useAuthProfile } from './AuthProfileContext';
import { cn } from '@/utils/cn';
import { AccountSelectionProvider } from './AccountSelectionContext';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ZodError } from 'zod';
import {
  Button as RACButton,
  OverlayArrow,
  Tooltip,
  TooltipTrigger,
} from 'react-aria-components';
import { useObservableState } from 'observable-hooks';
import { sessionWebsocketStatus$ } from '@/protected/apis/protectedApiWebsockets';
import { NotificationsButton } from './NotificationsButton';
import { preloadInteropComponents } from './Interop/InteropPlatformProvider';
import { fetchIsAuthenticated } from '@/protected/apis/protectedApiFetchers';
import { of, mergeMap, repeat, from } from 'rxjs';
import { useTabLock } from '@/shared/useTabLock';
import { TabLocked } from './TabLocked';
import { ErrorBoundary } from 'react-error-boundary';
import { logBoundaryError } from '@/utils/logBoundaryError';
import { AlertMessage } from '@/ui/alert-message';
import { ProtectedFooter } from './shared/ProtectedFooter';
import { createActorContext } from '@xstate/react';
import {
  cancelSessionMachine,
  chatSessionMachine,
  createCallStateMachine,
} from '@/machines/callStateMachine';
import { StateFrom } from 'xstate';
import { hrefBase } from '@/constants';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: (failureCount, error) => {
        if (error instanceof ZodError) {
          return false;
        }

        return failureCount < 3;
      },
    },
  },
});

function ProtectedPageFallback() {
  return (
    <div className="container py-4">
      <AlertMessage title="An unexpected error has ocurred">
        <div>
          An unexpected error has ocurred. Please reload the app to continue.
        </div>
      </AlertMessage>
    </div>
  );
}

function SocketDisconnectedWarning() {
  const [show, setShow] = React.useState(false);
  const result = useObservableState(sessionWebsocketStatus$);

  // Give the app some time to set up.
  // This pause could help prevent a flash of the connected indicator on page load.
  React.useEffect(() => {
    setTimeout(() => setShow(true), 5_000);
  }, []);

  const isConnected = result?.type === 'connected';

  if (!show || isConnected) return null;

  return (
    <div className="flex items-center space-x-1 rounded-sm border border-red-200 bg-red-500 px-1 py-0.5 text-sm text-red-100">
      <TooltipTrigger delay={500}>
        <RACButton className="cursor-help">
          <MdInfoOutline />
        </RACButton>
        <Tooltip>
          <OverlayArrow>
            <svg width={8} height={8}>
              <path d="M0 0,L4 4,L8 0" />
            </svg>
          </OverlayArrow>
          <div className="space-y-0.5 text-xs">
            <p>You've been disconnected from the platform.</p>
          </div>
        </Tooltip>
      </TooltipTrigger>
      <span className="sr-only">
        Platform warning: disconnected from server.
      </span>
      <span role="alert">Connecting</span>
    </div>
  );
}

function ResumeBanner() {
  const { send } = CallStateContext.useActorRef();

  // @ts-ignore:next-line
  // window.send = send;

  // FIXME veryify when xstate gets typegen
  const isDetached = CallStateContext.useSelector((s) =>
    s.matches({ Session: { some: { active: 'detached' } } })
  );

  const isRejecting = CallStateContext.useSelector((s) =>
    s.matches({ Session: { some: { active: { detached: 'rejecting' } } } })
  );

  const isAccepting = CallStateContext.useSelector((s) =>
    s.matches({ Session: { some: { active: { detached: 'accepting' } } } })
  );

  const isDisabled = isAccepting || isRejecting;

  if (!isDetached) return null;

  return (
    <section
      className="w-full border-b border-black/[0.2] bg-white"
      aria-labelledby="ResumeBanner--message"
    >
      <div
        className="mx-auto flex w-fit flex-row items-center space-x-4 p-1 text-black"
        role="alert"
      >
        <div className="font-medium" id="ResumeBanner--message">
          You have an existing session. Continue on this device?
        </div>
        <div className="flex items-center space-x-2">
          <Button
            type="button"
            size="sm"
            onPress={() => send({ type: 'session.attach' })}
            isDisabled={isDisabled}
          >
            Continue
            <span className="sr-only">into session</span>
          </Button>
          <Button
            type="button"
            size="sm"
            variant="outline"
            onPress={() => send({ type: 'session.reject' })}
            isDisabled={isDisabled}
          >
            End Session
          </Button>
        </div>
      </div>
    </section>
  );
}

function BrandingDraftMode() {
  if (!window.svenDraft) return null;

  return (
    <div className="border-b border-b-orange-400 bg-orange-200 p-1 text-sm font-medium text-black">
      <div className="container text-center">
        Your branded items are in draft mode.{' '}
        <Button
          onPress={() => {
            window.location.replace(hrefBase);
          }}
          variant="link"
          size="sm"
          className="p-0 font-bold"
        >
          Exit draft mode
        </Button>
        .
      </div>
    </div>
  );
}

// Show a warning when CafeX isn't available.
//
// We need to contact CafeX to see if there is a better way to load
// the libraries.  It's currently loaded by linking to the script
// tags.  Ideally, it'd be managed by pnpm so that we can lazy load
// them.
function CafeXBanner() {
  const [show] = React.useState(!Boolean(window.UC));

  const reload = React.useCallback(() => window.location.reload(), []);

  if (!show) return null;

  return (
    <div className="bg-yellow-200 p-1 text-sm font-medium text-black">
      <div className="container text-center">
        System message: Calls are currently unavailable. Please{' '}
        <Button
          onPress={reload}
          variant="link"
          size="sm"
          className="p-0 font-bold"
        >
          try again
        </Button>{' '}
        later.
      </div>
    </div>
  );
}

enum UserMenu {
  signOut = 'sign-out',
  switchAccounts = 'switch-accounts',
}

function ProtectedHeader() {
  const profile = useAuthProfile();
  const navigate = useNavigate();

  // We track that we're in an attached session to hide all
  // links that could allow the user to navigate off of the
  // in-session page containing the video
  const hasAttachedSession = CallStateContext.useSelector((s) =>
    s.matches({ Session: { some: { active: 'attached' } } })
  );

  const handleAction = (action: React.Key) => {
    switch (action) {
      case UserMenu.signOut: {
        navigate('/consumer/request-logout');
        return;
      }

      case UserMenu.switchAccounts: {
        navigate('/accounts/select');
        return;
      }
    }
  };

  return (
    <Header
      navLinks={
        <ul
          className={cn(
            'box-border flex list-none items-center space-x-1',
            hasAttachedSession && 'hidden'
          )}
        >
          <li>
            <HeaderNavLink
              to="dashboard"
              className="[&.active]:bg-branded1 [&.active]:text-branded1-foreground [&.active]:focus-visible:ring-white"
            >
              Languages
            </HeaderNavLink>
          </li>
          <li>
            <HeaderNavLink to="history">Session History</HeaderNavLink>
          </li>
          <li>
            <HeaderNavLink to="support">Support</HeaderNavLink>
          </li>
          <li>
            <HeaderNavLink to="settings">Settings</HeaderNavLink>
          </li>
        </ul>
      }
      notificationButton={
        !hasAttachedSession ? <NotificationsButton /> : undefined
      }
      misc={<SocketDisconnectedWarning />}
      userButton={
        <MenuTrigger>
          <RACButton className="flex h-full w-full flex-row items-center gap-3 px-4 pr-6 focus:outline-none focus-visible:ring-2 focus-visible:ring-inset focus-visible:ring-white ">
            <MdSettings size="1.5rem" aria-hidden />
            <span className="sr-only">Settings and more for</span>
            <span className="truncate">{profile.user.username}</span>
          </RACButton>
          <MenuPopover>
            <MenuContent onAction={handleAction}>
              <MenuItem id={UserMenu.signOut}>
                Sign out
                <MdLogout aria-hidden />
              </MenuItem>
              {!hasAttachedSession &&
                profile.login_enterprise?.must_choose_subaccount && (
                  <>
                    <MenuSeperator />
                    <MenuItem id={UserMenu.switchAccounts}>
                      Change accounts
                      <MdOutlineSwapHoriz aria-hidden />
                    </MenuItem>
                  </>
                )}
            </MenuContent>
          </MenuPopover>
        </MenuTrigger>
      }
    />
  );
}

function ProtectedPageImpl() {
  React.useEffect(() => {
    openSessionSocket(
      `wss://${window.location.host}/ws/sessionupdates?subscribe-user`
    );

    return () => {
      closeSessionSocket();
    };
  }, []);

  return (
    <BasicLayout
      header={
        <>
          <BrandingDraftMode />
          <CafeXBanner />
          <ResumeBanner />
          <ProtectedHeader />
        </>
      }
      body={
        <>
          <ErrorBoundary
            FallbackComponent={ProtectedPageFallback}
            onError={logBoundaryError}
          >
            <Outlet />
            <ProtectedFooter supportSwitchAccount />
          </ErrorBoundary>
        </>
      }
    />
  );
}

const MemoedProtectedPageImpl = React.memo(ProtectedPageImpl);

export const callStateMachine = createCallStateMachine();
export const CallStateContext = createActorContext(callStateMachine);
export type CallState = StateFrom<typeof callStateMachine>;

// Polls an API to check if the user is still logged in.  If not, we
// redirect them to the logout page.
function AutoLogoutMonitor() {
  const isInAttachedSession = CallStateContext.useSelector((s) =>
    s.matches({ Session: { some: { active: 'attached' } } })
  );

  // only run when not in an attached session
  React.useEffect(() => {
    if (isInAttachedSession) return;

    const sub = of(true)
      .pipe(
        // For this to work (and autologout), fetchIsAuthenticated
        // must use the autoLogoutApi; alternatively, we could
        // suscribe to { error } and check for 403
        mergeMap(() => from(fetchIsAuthenticated())),
        repeat({ delay: 15_000 })
      )
      .subscribe();

    return () => {
      sub.unsubscribe();
    };
  }, [isInAttachedSession]);

  return null;
}

export function ProtectedPage() {
  const navigate = useNavigate();

  const isTabLocked = useTabLock();

  React.useEffect(() => {
    // CafeX is our primary video provider, so let's ensure
    // we have the code available as soon as possible.
    preloadInteropComponents('CafeX');
  }, []);

  if (isTabLocked) return <TabLocked />;

  return (
    <AccountSelectionProvider>
      <CallStateContext.Provider
        logic={callStateMachine.provide({
          actors: {
            cancelSessionMachine: cancelSessionMachine.provide({
              actions: {
                handleLeaveSessionFailure: ({ event }) => {
                  if (event.type === 'error.platform.endingSession') {
                    const { error, reason } = event.data;
                    const message = svenErrorMessage(error);

                    const title =
                      reason === 'cancel'
                        ? 'Failed to cancel session'
                        : 'Failed to end session';

                    toast.error(title, {
                      description: message,
                    });
                  }
                },
              },
            }),
            chatSessionMachine: chatSessionMachine.provide({
              actions: {
                logError: (data: any) => {
                  console.error('chatSession error', data);
                },
              },
            }),
          },
          actions: {
            logError: (data: any) => {
              console.error('callState error', data);
            },
            handleAcceptSessionFailure: ({ event }) => {
              if (event.type === 'error.platform.acceptSession') {
                const error = event.data;
                const message = svenErrorMessage(error);

                toast.error('Failed to accept session', {
                  description: message,
                });
              }
            },
            handleRejectSessionFailure: ({ event }) => {
              if (event.type === 'error.platform.rejectSession') {
                const error = event.data;
                const message = svenErrorMessage(error);

                toast.error('Failed to end session', {
                  description: message,
                });
              }
            },
            handleCancelSessionFailure: ({ event }) => {
              if (event.type === 'error.platform.cancelSession') {
                const error = event.data;
                const message = svenErrorMessage(error);

                toast.error('Failed to cancel session', {
                  description: message,
                });
              }
            },
            handleConnectionLoss: ({ event }) => {
              if (event.type === 'session.connectionLoss') {
                toast('Connection loss', {
                  description: event.message.message,
                });
              }
            },
            onAttach: () => {
              navigate('/consumer/dashboard');
            },
          },
        })}
      >
        <QueryClientProvider client={queryClient}>
          <MemoedProtectedPageImpl />
          <AutoLogoutMonitor />
        </QueryClientProvider>
      </CallStateContext.Provider>
    </AccountSelectionProvider>
  );
}
