/**
 * Copyright © 2021, AMN Healthcare, Inc. All rights reserved.
 */

import {
  type LegDetails,
  type LegDetail,
} from '@/protected/apis/protectedApiSchemas';

// immutable
export function updateLeg(newLeg: LegDetail, legs?: LegDetails): LegDetails {
  if (legs === undefined) {
    return [newLeg];
  }

  let matched = false;
  let result: LegDetails = [];

  legs.forEach((existingLeg) => {
    if (existingLeg.id === newLeg.id) {
      // insert the new leg
      result.push(newLeg);
      matched = true;
    } else {
      result.push(existingLeg);
    }
  });

  if (!matched) {
    return [...result, newLeg];
  } else {
    return result;
  }
}

// merges in new legs.  if the legs don't exist, then we mark them
// as ended by updating the end_timestamp.
// immutable.
export function mergeLegs(
  newLegs: LegDetails,
  oldLegs?: LegDetails,
  endTimestamp: Date = new Date()
): LegDetails {
  if (oldLegs === undefined) {
    return newLegs;
  }

  let result: LegDetails = [];
  let newIDs = new Map(newLegs.map((leg) => [leg.id, leg]));

  oldLegs.forEach((leg) => {
    const foundLeg = newIDs.get(leg.id);

    if (foundLeg) {
      newIDs.delete(leg.id);
      result.push(foundLeg);
    } else {
      // mark the existing leg as finished
      result.push({ ...leg, end_timestamp: endTimestamp.toISOString() });
    }
  });

  return result.concat([...newIDs.values()]);
}
