/**
 * Copyright © 2021, AMN Healthcare, Inc. All rights reserved.
 */

import React from 'react';
import { cn } from '@/utils/cn';
import { Button } from '@/ui/button';
import { MdCheck } from 'react-icons/md';
import { MediaDiagnosticsCamera } from '@/shared/MediaDiagnosticsCamera';
import { MediaDiagnosticsMicrophone } from '@/shared/MediaDiagnosticsMicrophone';

type MediaSectionProps = {
  label: string;
  complete: boolean;
  children: React.ReactNode;
  active: boolean;
};
function MediaSection({
  label,
  complete,
  children,
  active,
}: MediaSectionProps) {
  return (
    <div className="rounded-sm border border-gray-200 bg-gray-100">
      <div
        className={cn(
          'flex w-full items-center space-x-1 bg-gray-100 px-2 py-1 font-medium',
          complete && 'text-green-700'
        )}
      >
        {complete && (
          <div>
            <span className="sr-only">Passed</span>
            <MdCheck size="1.2rem" aria-hidden />
          </div>
        )}
        <div className="flex-auto">{label}</div>
      </div>
      {active && <div className="border-t border-gray-300 p-2">{children}</div>}
    </div>
  );
}

enum Step {
  microphone = 1,
  camera = 2,
  finished = 3,
}

export function MediaDiagnostics() {
  const [step, setStep] = React.useState<Step>(Step.microphone);

  return (
    <div className="divide-y-gray-300 space-y-4">
      <div className="space-y-2">
        <h2 className="text-lg font-semibold">Media Diagnostics</h2>
        <p className="text-slate-600">
          This page checks that your browser can access to your camera and
          microphone.
        </p>
      </div>

      <MediaSection
        label="Microphone"
        active={step === Step.microphone}
        complete={step > Step.microphone}
      >
        <div className="space-y-2">
          <MediaDiagnosticsMicrophone />

          <p id="md--microphone-question" className="text-sm">
            Do you see your mic volume shown above?
          </p>

          <div>
            <Button
              type="button"
              id="md--microphone-button"
              onPress={() => setStep(Step.camera)}
              aria-labelledby="md--microphone-question md--microphone-button"
            >
              Yes
            </Button>
          </div>
        </div>
      </MediaSection>

      <MediaSection
        label="Camera"
        active={step === Step.camera}
        complete={step > Step.camera}
      >
        <div className="space-y-2">
          <div className="max-w-lg">
            <MediaDiagnosticsCamera supportsWidescreen={false} />
          </div>

          <p id="md--camera-question" className="text-sm">
            Do you see your camera shown above?
          </p>

          <div>
            <Button
              type="button"
              id="md--camera-button"
              onPress={() => setStep(Step.finished)}
              aria-labelledby="md--camera-question md--camera-button"
            >
              Yes
            </Button>
          </div>
        </div>
      </MediaSection>

      {step === Step.finished && (
        <div className="space-y-2">
          <p className="font-medium">All tests passed</p>
          <Button onPress={() => setStep(Step.microphone)} type="button">
            Restart checks
          </Button>
        </div>
      )}
    </div>
  );
}
