/**
 * Copyright © 2021, AMN Healthcare, Inc. All rights reserved.
 */

import { Label, Text } from 'react-aria-components';
import { MdEmergency } from 'react-icons/md';
import { cn } from '@/utils/cn';

// Requires react-aria-components field provider

type FieldLabelProps = {
  text: string;
  isRequired?: boolean;
  className?: string;
};

function LabelRequiredIndicator() {
  return (
    <>
      <MdEmergency size="0.9em" aria-hidden />
      <span className="sr-only">Required</span>
    </>
  );
}

const labelBaseClass = 'font-medium';

export function FieldLabel({ text, isRequired, className }: FieldLabelProps) {
  return (
    <Label className={cn(labelBaseClass, 'flex space-x-1', className)}>
      {text}
      {isRequired && <LabelRequiredIndicator />}
    </Label>
  );
}

type FieldDescriptionProps = { children: string; className?: string };
export function FieldDescription({
  children,
  className,
}: FieldDescriptionProps) {
  return (
    <Text
      slot="description"
      className={cn('text-sm text-muted-foreground', className)}
    >
      {children}
    </Text>
  );
}

type FieldErrorMessageProps = { children: string; className?: string };
export function FieldErrorMessage({
  children,
  className,
}: FieldErrorMessageProps) {
  return (
    <Text slot="errorMessage" className={cn('text-sm text-red-500', className)}>
      {children}
    </Text>
  );
}
