/**
 * Copyright © 2021, AMN Healthcare, Inc. All rights reserved.
 */

import React from 'react';
import VolumeMeter from '@/shared/VolumeMeter';
import { useAudioStream } from '@/shared/useAudioStream';
import { DeviceAlert } from '@/shared/DeviceAlert';

type MicrophoneMeterProps = { stream: MediaStream };
function MicrophoneMeter({ stream }: MicrophoneMeterProps) {
  return <VolumeMeter stream={stream} label="Microphone volume meter" />;
}

export function MediaDiagnosticsMicrophone() {
  const stream = useAudioStream();

  if (stream.type === 'pending') {
    return <p className="loading-ellipsis">Accessing microphone</p>;
  }

  if (stream.type === 'failure') {
    return (
      <DeviceAlert
        title="Unable to access microphone"
        errorName={stream.error.name}
        deviceName="microphone"
      />
    );
  }

  if (stream.type === 'success') {
    return <MicrophoneMeter stream={stream.stream} />;
  }
}
