/**
 * Copyright © 2021, AMN Healthcare, Inc. All rights reserved.
 */

import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Button } from '@/ui/button';
import { svenErrorMessage } from '@/api/getError';
import { HTTPError } from '@/api/api';
import api from '@/api/api';
import { AuthCardLayout } from './AuthCardLayout';

type LogoutRequest =
  | { type: 'pending' }
  | { type: 'success' }
  | { type: 'failure'; message: string };

// This logout page takes an optional `reason` query string parameter.
// This is used to support AUTHTOKENINVALID
export function Logout() {
  const navigate = useNavigate();

  const [logoutRequest, setLogoutRequest] = React.useState<LogoutRequest>({
    type: 'pending',
  });

  const [searchParams] = useSearchParams();
  const reason = searchParams.get('reason');

  const logout = () => {
    setLogoutRequest({ type: 'pending' });
  };

  const gotoLogin = React.useCallback(() => {
    navigate('/public/login', { replace: true, state: { reason: reason } });
  }, [navigate, reason]);

  React.useEffect(() => {
    async function run(request: LogoutRequest) {
      switch (request.type) {
        case 'success': {
          gotoLogin();
          return;
        }
        case 'pending': {
          try {
            await api.post(`/api/logout`);
            setLogoutRequest({ type: 'success' });
          } catch (error) {
            if (error instanceof HTTPError && error.response.status === 403) {
              // already logged out
              setLogoutRequest({ type: 'success' });
            } else {
              setLogoutRequest({
                type: 'failure',
                message: svenErrorMessage(error),
              });
            }
          }
        }
      }
    }

    run(logoutRequest);
  }, [logoutRequest, gotoLogin]);

  if (logoutRequest.type === 'failure') {
    return (
      <AuthCardLayout header="Logout failed">
        <p>{logoutRequest.message}</p>
        <div className="flex w-full justify-end">
          <Button type="button" onPress={() => logout()}>
            Log out
          </Button>
        </div>
      </AuthCardLayout>
    );
  }

  return (
    <AuthCardLayout header="Logging out">
      <p>Please wait while we attempt to log you out.</p>
    </AuthCardLayout>
  );
}
