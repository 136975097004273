/**
 * Copyright © 2021, AMN Healthcare, Inc. All rights reserved.
 */

import { Button } from '@/ui/button';
import { useNavigate } from 'react-router-dom';
import * as ContainerLayout from '@/shared/ContainerLayout';

// We could make the button a link, but we don't have a component
// for that yet.  And since we don't allow for multiple tabs, we
// just made it a button.

export function ProtectedNotFound() {
  const navigate = useNavigate();

  const goHome = () => navigate('/consumer/dashboard');

  return (
    <ContainerLayout.Root aria-label="Page not found">
      <h2 className="text-2xl font-medium">Page not found</h2>
      <p className="text-muted-foreground">
        The page you are looking for could not be found.
      </p>
      <Button onPress={goHome} type="button">
        Go home
      </Button>
    </ContainerLayout.Root>
  );
}
