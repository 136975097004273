/**
 * Copyright © 2021, AMN Healthcare, Inc. All rights reserved.
 */

import React from 'react';
import type { InteropExport } from './interopTypes';
import loadable, {
  type LoadableLibrary,
  type OptionsWithoutResolver,
} from '@loadable/component';
import { getPlatformType } from './getPlatformType';

type InteropPlatformValue = ReturnType<typeof getPlatformType>;

const loadableOptions: OptionsWithoutResolver<never> = {
  ssr: false,
};

type Result = { components: InteropExport };

const interopComponents: Record<
  InteropPlatformValue,
  LoadableLibrary<Result>
> = {
  CafeX: loadable.lib(() => import('./CafeX'), loadableOptions),
};

export function preloadInteropComponents(platform: InteropPlatformValue) {
  const components = interopComponents[platform];
  components.preload();
}

type InteropContextValue = {
  platform: InteropPlatformValue;
  components: InteropExport;
};

let InteropPlatformContext = React.createContext<
  InteropContextValue | undefined
>(undefined);

// Helper to figure out what platform is in use
function useInteropPlatform() {
  const context = React.useContext(InteropPlatformContext);
  if (context === undefined) {
    throw new Error(
      'useInteropPlatform must be used within a InteropPlatformProvider'
    );
  }
  return context;
}

type InteropProviderProps = {
  children: React.ReactNode;
  platform: InteropPlatformValue;
  fallback: JSX.Element;
};

// DynamicInterop will throw and an ErrorBoundary will have to catch
// when there is an issue; therefore, what will be rendered is either the
// fallback or the interop platform context provider.
function InteropPlatformProvider({
  children,
  fallback,
  platform,
}: InteropProviderProps) {
  const DynamicInterop = interopComponents[platform];

  return (
    <DynamicInterop fallback={fallback}>
      {({ components }) => (
        <InteropPlatformContext.Provider
          value={{ platform, components: components }}
        >
          {children}
        </InteropPlatformContext.Provider>
      )}
    </DynamicInterop>
  );
}

export { useInteropPlatform, InteropPlatformProvider };
export type { InteropProviderProps, InteropPlatformValue };
