/**
 * Copyright © 2021, AMN Healthcare, Inc. All rights reserved.
 */

import { LeaveSessionButton } from './ui/LeaveSessionButton';
import { SessionSidebarLayout } from './SessionSidebarLayout';
import { InfoMessage } from '@/ui/info-message';

export function SessionAttachedTransferring() {
  return (
    <SessionSidebarLayout
      title="Transfer in progress..."
      sidebar={<LeaveSessionButton>Leave session</LeaveSessionButton>}
    >
      <InfoMessage title="Transferring">
        Please wait while we transfer your session to another interpreter.
      </InfoMessage>
    </SessionSidebarLayout>
  );
}
