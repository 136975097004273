/**
 * Copyright © 2021, AMN Healthcare, Inc. All rights reserved.
 */

import { useSliderState, type SliderState } from 'react-stately';
import React from 'react';
import type { AriaSliderProps, AriaSliderThumbProps } from 'react-aria';
import { mergeRefs } from '@react-aria/utils';
import { MdOutlineStar, MdEmergency } from 'react-icons/md';
import { cn } from '@/utils/cn';

import {
  mergeProps,
  useFocusRing,
  useNumberFormatter,
  useSlider,
  useSliderThumb,
  VisuallyHidden,
} from 'react-aria';
import { useSlotId } from '@react-aria/utils';

// TODO: improve me.
// TODO: have provider set labelprops so we can use Field components

function roundNumber(
  value: number,
  step: number = 1.0,
  roundingFn: (val: number) => number = Math.ceil
) {
  const inv = 1.0 / (step || 1.0);
  return roundingFn(value * inv) / inv;
}

type RatingFieldProps = {
  label: string;
  formatOptions?: Intl.NumberFormatOptions;
  isRequired?: boolean;
  description?: string | null;
  errorMessage?: string;
  size?: string;
  isInvalid?: boolean;
} & Omit<AriaSliderProps<number>, 'minValue' | 'maxValue' | 'steps'>;

export function RatingFieldInner(
  props: RatingFieldProps,
  ref: React.ForwardedRef<HTMLInputElement>
) {
  let trackRef = React.useRef<HTMLDivElement>(null);
  let numberFormatter = useNumberFormatter(props.formatOptions);
  let state = useSliderState({
    ...props,
    step: 0.5, // TODO: dynamic
    minValue: 0, // TODO: dynamic
    maxValue: 5, // TODO: dynamic
    numberFormatter,
  });
  let { groupProps, trackProps, labelProps, outputProps } = useSlider(
    props,
    state,
    trackRef
  );
  const size = props.size || '1em';
  const { description, errorMessage, isInvalid } = props;

  const isFieldInvalid = Boolean(errorMessage) || isInvalid;

  let descriptionId = useSlotId([
    Boolean(description),
    Boolean(errorMessage),
    isFieldInvalid,
  ]);
  let errorMessageId = useSlotId([
    Boolean(description),
    Boolean(errorMessage),
    isFieldInvalid,
  ]);

  let { role, ...fieldProps } = groupProps;
  fieldProps = mergeProps(fieldProps, {
    'aria-describedby':
      [
        descriptionId,
        // Use aria-describedby for error message because aria-errormessage is unsupported using VoiceOver or NVDA. See https://github.com/adobe/react-spectrum/issues/1346#issuecomment-740136268
        errorMessageId,
        props['aria-describedby'],
      ]
        .filter(Boolean)
        .join(' ') || undefined,
  });

  return (
    <div
      {...fieldProps}
      role={role}
      className={`group flex w-full flex-col`}
      data-invalid={isFieldInvalid}
    >
      {/* Create a container for the label and output element. */}
      {props.label && (
        <div className="flex items-center space-x-2">
          <label {...labelProps} className="flex space-x-1 font-medium">
            {props.label}
            {props.isRequired && (
              <>
                <MdEmergency size="0.9em" aria-hidden />
                <span className="sr-only">Required</span>
              </>
            )}
          </label>
          <output {...outputProps} className="text-sm text-muted-foreground">
            {state.getThumbValueLabel(0)}
          </output>
        </div>
      )}
      {/* The track element holds the visible track line and the thumb. */}
      <div
        {...trackProps}
        ref={trackRef}
        className={`relative w-fit touch-none ${
          state.isDisabled ? 'opacity-50' : ''
        }`}
      >
        <Thumb
          index={0}
          state={state}
          trackRef={trackRef}
          size={size}
          ref={ref}
        />
      </div>
      <div className="flex flex-col space-y-0.5">
        {description && (
          <div id={descriptionId} className="text-sm text-muted-foreground">
            {description}
          </div>
        )}
        {errorMessage && (
          <div id={errorMessageId} className="text-sm text-red-500">
            {errorMessage}
          </div>
        )}
      </div>
    </div>
  );
}

type ThumbProps = {
  state: SliderState;
  trackRef: React.RefObject<HTMLDivElement>;
  size: string;
} & AriaSliderThumbProps;

function ThumbInner(
  props: ThumbProps,
  ref: React.ForwardedRef<HTMLInputElement>
) {
  let { size, state, trackRef, index } = props;
  let inputRef = React.useRef(null);
  let { thumbProps, inputProps, isDisabled } = useSliderThumb(
    {
      index,
      trackRef,
      inputRef,
    },
    state
  );

  const val = state.getThumbValue(0);

  let { focusProps, isFocusVisible } = useFocusRing();
  let { style, ...thumbPropsWithoutStyle } = thumbProps;

  const finalInputRef = mergeRefs(inputRef, ref);

  return (
    <div
      {...thumbPropsWithoutStyle}
      data-focus-visible={isFocusVisible}
      className={cn(
        'w-fit overflow-hidden rounded-md border border-transparent focus:outline-none focus-visible:outline-none group-data-[invalid="true"]:border-red-300 rac-focus-visible:ring-2 rac-focus-visible:ring-ring rac-focus-visible:ring-offset-2'
      )}
    >
      <div className="flex flex-row overflow-hidden">
        {Array.from({ length: 5 }).map((_, idx) => (
          <div
            key={idx}
            className="relative"
            onPointerDown={(e) => {
              if (isDisabled) return;

              const rect = e.currentTarget.getBoundingClientRect();
              const v = (e.clientX - rect.left) / rect.width;
              state.setThumbValue(0, roundNumber(v + idx, 0.5));
            }}
          >
            <MdOutlineStar
              aria-hidden
              size={size}
              className={cn(
                'stroke-gray-800 [&>path]:stroke-1',
                '[clip-path:polygon(0%_0%,50%_0%,50%_100%,0%_100%)]',
                val + 0.5 < idx + 1 ? 'fill-slate-200' : 'fill-yellow-500'
              )}
            />
            <MdOutlineStar
              aria-hidden
              size={size}
              className={cn(
                'stroke-gray-800 [&>path]:stroke-1',
                'absolute top-0 [clip-path:polygon(50%_0%,100%_0%,100%_100%,50%_100%)]',
                val < idx + 1 ? 'fill-slate-200' : 'fill-yellow-500'
              )}
            />
          </div>
        ))}
      </div>
      <VisuallyHidden>
        <input ref={finalInputRef} {...mergeProps(inputProps, focusProps)} />
      </VisuallyHidden>
    </div>
  );
}

const Thumb = React.forwardRef(ThumbInner);
const RatingField = React.forwardRef(RatingFieldInner);

export { RatingField };
