/**
 * Copyright © 2021, AMN Healthcare, Inc. All rights reserved.
 */

import {
  sessionUpdateMessageSchema,
  sessionLegUpdateMessageSchema,
  sessionLegJoinedMessageSchema,
  conferenceLegJoinedMessageSchema,
  sessionLegDroppedMessageSchema,
  sessionChatMessageSchema,
  clearChatMessageSchema,
  qUnavailableMessageSchema,
  sessionVolumeAdjustmentSchema,
  connectionLossMessageSchema,
  oidcResumeAttemptedMessageSchema,
} from './protectedApiSchemas';

export function parseWebsocketMessage(msg: any) {
  const json = JSON.parse(msg);

  switch (json.action_type) {
    case 'SESSIONUPDATE':
      return sessionUpdateMessageSchema.parse(json);

    case 'QUNAVAILABLE':
      return qUnavailableMessageSchema.parse(json);

    case 'SESSIONLEGUPDATE':
      return sessionLegUpdateMessageSchema.parse(json);

    case 'SESSIONLEGJOINED':
      return sessionLegJoinedMessageSchema.parse(json);

    case 'CONFERENCELEGJOINED':
      return conferenceLegJoinedMessageSchema.parse(json);

    case 'SESSIONLEGDROPPED':
      return sessionLegDroppedMessageSchema.parse(json);

    case 'SESSIONCHAT':
      return sessionChatMessageSchema.parse(json);

    case 'CLEARCHATMESSAGE':
      return clearChatMessageSchema.parse(json);

    case 'SESSIONLEGVOLUMEADJUSTMENT':
      return sessionVolumeAdjustmentSchema.parse(json);

    case 'CONNECTION_LOSS':
      return connectionLossMessageSchema.parse(json);

    case 'OIDCRESUMEATTEMPTED':
      return oidcResumeAttemptedMessageSchema.parse(json);

    default:
      throw new Error('Unhandled message');
  }
}
