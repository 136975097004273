/**
 * Copyright © 2021, AMN Healthcare, Inc. All rights reserved.
 */

import { useSessionErrorDialog } from './SessionErrorDialogContext';
import { ConfirmDialog } from '@/ui/confirm-dialog';

export type DialogState = {
  body: React.ReactNode;
  title: string;
  requiresReload: boolean;
};

export function SessionErrorDialog() {
  const [state, dispatch] = useSessionErrorDialog();

  if (!state.contents) {
    return null;
  }

  const { title, body, requiresReload } = state.contents;

  return (
    <ConfirmDialog
      title={title}
      content={body}
      isOpen={true}
      onCancel={() => dispatch({ type: 'close' })}
      onConfirm={() => {
        if (requiresReload) {
          window.location.reload();
        } else {
          dispatch({ type: 'close' });
        }
      }}
      confirmName={requiresReload ? 'Reload' : 'Ok'}
    />
  );
}
