/**
 * Copyright © 2021, AMN Healthcare, Inc. All rights reserved.
 */

import React from 'react';
import type { ItemProps, SelectProps } from 'react-aria-components';
import {
  Select,
  Button,
  SelectValue,
  Popover,
  ListBox,
  Item,
} from 'react-aria-components';
import { MdOutlineUnfoldMore, MdCheck } from 'react-icons/md';
import { FieldLabel, FieldErrorMessage, FieldDescription } from './field';
import { cn } from '@/utils/cn';

type LabelProps = { isRequired?: boolean };

interface MySelectProps<T extends object>
  extends Omit<SelectProps<T>, 'children'> {
  label: string | ((props: LabelProps) => React.ReactNode);
  description?: string | null;
  errorMessage?: string;
  isRequired?: boolean;
  children: React.ReactNode | ((item: T) => React.ReactNode);
  renderEmptyState?: () => React.ReactNode;
  inline?: boolean;
  items: Iterable<T>;
}

export function SelectFieldItem(props: ItemProps & { children: string }) {
  return (
    <Item
      {...props}
      textValue={props.children}
      className={({ isFocused }) => `
        group/item relative cursor-default select-none rounded py-2 pl-10 pr-4 outline-none
        ${isFocused ? 'bg-accent text-white' : 'text-gray-900'}
      `}
    >
      {({ isSelected }) => (
        <>
          <span
            className={`block truncate ${
              isSelected ? 'font-medium' : 'font-normal'
            }`}
          >
            {props.children}
          </span>
          {isSelected && (
            <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-accent group-data-[focused]/item:text-white">
              <MdCheck className="h-5 w-5" aria-hidden="true" />
            </span>
          )}
        </>
      )}
    </Item>
  );
}

export function StyledSelectFieldItem(
  props: ItemProps & { children: React.ReactNode; textValue: string }
) {
  return (
    <Item
      {...props}
      textValue={props.textValue}
      className={({ isFocused }) => `
        group/item relative cursor-default select-none rounded py-2 pl-10 pr-4 outline-none
        ${isFocused ? 'bg-accent text-white' : 'text-gray-900'}
      `}
    >
      {({ isSelected }) => (
        <>
          <span
            className={`block truncate ${
              isSelected ? 'font-medium' : 'font-normal'
            }`}
          >
            {props.children}
          </span>
          {isSelected && (
            <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-accent group-data-[focused]/item:text-white">
              <MdCheck className="h-5 w-5" aria-hidden="true" />
            </span>
          )}
        </>
      )}
    </Item>
  );
}

function SelectFieldInner<T extends object>(
  {
    label,
    description,
    errorMessage,
    isRequired,
    children,
    renderEmptyState,
    inline = false,
    items,
    isInvalid,
    ...props
  }: MySelectProps<T>,
  ref: React.ForwardedRef<HTMLButtonElement>
) {
  const Label = label;

  const isFieldInvalid = Boolean(errorMessage) || isInvalid;

  return (
    <Select
      {...props}
      isInvalid={isFieldInvalid}
      className={(x) =>
        cn(
          // x.isFocused && 'group/select-focused',
          // x.isFocusVisible && 'group/select-focus-visible',
          // x.isDisabled && 'group/select-focus-disabled',
          'group/select flex flex-col space-y-1',
          props.className
        )
      }
    >
      <div
        className={cn(
          'w-full',
          inline ? 'flex flex-row items-center space-x-2' : 'space-y-1'
        )}
      >
        {label instanceof Function ? (
          <Label isRequired={isRequired} />
        ) : (
          <FieldLabel isRequired={isRequired} text={label} />
        )}
        <Button
          type="button"
          className={cn(
            'relative m-0 flex h-10 w-full cursor-default appearance-none items-center rounded-md border border-input bg-white bg-opacity-90 py-2 pl-3 pr-2 text-left text-sm text-gray-700 outline-none transition placeholder:text-muted-foreground focus:outline-none disabled:cursor-not-allowed disabled:opacity-50 data-[pressed]:bg-opacity-100 sm:text-sm',
            // hover
            'rac-hover:border-input-hover',
            // focus
            'group-data-[focused]/select:!border-input-focus',
            // focus visible
            'group-data-[focus-visible]/select:ring-1 group-data-[focus-visible]/select:ring-input-focus group-data-[focus-visible]/select:ring-offset-0',
            // invalid
            isFieldInvalid &&
              'border-input-invalid rac-hover:border-input-invalidHover'
          )}
          ref={ref}
        >
          <SelectValue className="flex-1 truncate data-[placeholder]:italic" />
          <MdOutlineUnfoldMore
            className="h-5 w-5 text-gray-500"
            aria-hidden="true"
          />
        </Button>
      </div>
      {description && <FieldDescription>{description}</FieldDescription>}
      {errorMessage && <FieldErrorMessage>{errorMessage}</FieldErrorMessage>}
      <Popover className="max-h-60 w-[--trigger-width] overflow-auto rounded-md bg-white text-base shadow-lg ring-1 ring-black ring-opacity-5 fill-mode-forwards data-[entering]:animate-in data-[exiting]:animate-out data-[entering]:fade-in data-[exiting]:fade-out sm:text-sm">
        <ListBox
          className="p-1 outline-none [--focus-bg:theme(colors.purple.600)]"
          renderEmptyState={renderEmptyState}
          items={items}
        >
          {children}
        </ListBox>
      </Popover>
    </Select>
  );
}

export const SelectField = React.forwardRef(SelectFieldInner) as <
  T extends Object,
>(
  props: MySelectProps<T> & { ref?: React.ForwardedRef<HTMLButtonElement> }
) => ReturnType<typeof SelectFieldInner>;
//export const SelectField = React.forwardRef(SelectFieldInner);
