/**
 * Copyright © 2021, AMN Healthcare, Inc. All rights reserved.
 */

import { createMachine, assign, fromPromise, log } from 'xstate';
import { APIError } from '@/api/api';
import * as backend from '@/protected/apis/protectedApiFetchers';
import { svenErrorMessage } from '@/api/getError';

type Answers = Record<string, string | number>;
type FinishedEvents =
  | { type: 'finish' }
  | { type: 'retry' }
  | { type: 'answer'; answers: Answers }
  | { type: 'error.platform.postFeedbackSubmit'; data: APIError };

type FeedbackResponse = Awaited<
  ReturnType<typeof backend.fetchPostSessionFeedback>
>;

type FinishedContext = {
  withQuestionsTimeout: number;
  withoutQuestionsTimeout: number;
  expireTime: Date | undefined;
  feedback: FeedbackResponse | undefined;
  answerFailure?: string;
};

export const sessionFinishedMachine = createMachine(
  {
    id: 'sessionFinishedMachine',
    initial: 'loading',
    entry: 'setExpireTime',
    after: {
      WITH_QUESTIONS_DEFAULT_DELAY: { target: '.finished' },
    },
    on: {
      finish: '.finished',
    },
    states: {
      loading: {
        invoke: {
          id: 'postFeedbackSearch',
          src: fromPromise(() => backend.fetchPostSessionFeedback()),
          onDone: [
            {
              target: 'feedback_needed',
              actions: 'setFeedback',
              guard: 'hasQuestions',
            },
            {
              target: 'feedback_not_needed',
            },
          ],
          onError: {
            actions: log(({ event }) => event, 'Failed to load feedback'),
            target: 'loading_failed',
          },
        },
      },
      loading_failed: {
        on: {
          retry: 'loading',
        },
      },
      feedback_needed: {
        initial: 'idle',
        states: {
          idle: {
            on: {
              answer: 'answering',
            },
          },
          answering: {
            entry: 'clearAnswerFailure',
            invoke: {
              id: 'postFeedbackSubmit',
              src: fromPromise(({ input }: { input: Answers }) =>
                backend.submitPostSessionFeedback(input)
              ),
              input: ({
                event,
              }: {
                event: Extract<FinishedEvents, { type: 'answer' }>;
              }) => event.answers,
              onDone: 'answered',
              onError: {
                actions: [
                  'setAnswerFailure',
                  log(
                    ({ event }) => event,
                    'Failed to submit feedback answers'
                  ),
                ],
                target: 'failure',
              },
            },
          },
          answered: {
            entry: 'reload',
          },
          failure: {},
        },
      },
      feedback_not_needed: {
        entry: 'adjustExpireTime',
        after: {
          // this will reduce the time (if it's actually smaller
          // than the default timeout)
          NO_QUESTIONS_DELAY: { target: 'finished' },
        },
      },
      finished: {
        entry: 'reload',
      },
    },
    context: ({
      input,
    }: {
      input: {
        withQuestionsTimeout: number;
        withoutQuestionsTimeout: number;
      };
    }) => ({
      feedback: undefined,
      withQuestionsTimeout: input.withQuestionsTimeout,
      withoutQuestionsTimeout: input.withoutQuestionsTimeout,
      expireTime: undefined,
    }),
    types: {
      context: {} as FinishedContext,
      events: {} as FinishedEvents,
    },
  },
  {
    // String delays configured here
    delays: {
      WITH_QUESTIONS_DEFAULT_DELAY: ({ context }) => {
        return context.withQuestionsTimeout;
      },
      NO_QUESTIONS_DELAY: ({ context }) => {
        return context.withoutQuestionsTimeout;
      },
    },
    actions: {
      setAnswerFailure: assign(({ context, event }) => {
        if (event.type === 'error.platform.postFeedbackSubmit') {
          const message = svenErrorMessage(event.data);
          return {
            answerFailure: message,
          };
        }

        return context;
      }),
      clearAnswerFailure: assign(() => {
        return {
          answerFailure: undefined,
        };
      }),
      setExpireTime: assign(({ context }) => {
        return {
          expireTime: new Date(Date.now() + context.withQuestionsTimeout),
        };
      }),
      adjustExpireTime: assign(({ context }) => {
        const newTime = Date.now() + context.withoutQuestionsTimeout;

        if (!context.expireTime) {
          return { expireTime: new Date(newTime) };
        }

        return {
          expireTime: new Date(Math.min(newTime, context.expireTime.getTime())),
        };
      }),
      setFeedback: assign(({ event }) => {
        const data = (event as any).output as FeedbackResponse;
        return { feedback: data };
      }),
      reload: () => window.location.reload(),
    },
    guards: {
      hasQuestions: ({ event }) => {
        const data = (event as any).output as FeedbackResponse;
        return data.questions.length > 0;
      },
    },
  }
);
