/**
 * Copyright © 2021, AMN Healthcare, Inc. All rights reserved.
 */

import api from '@/api/api';
import {
  enterpriseLanguagesSchema,
  legDetailsSchema,
  postSessionFeedbackSchema,
  sessionDetailsSchema,
  recentSessionsSchema,
  notificationsSchema,
  cafeXTokenSchema,
  fetchSessionChatSchema,
  StatusFlag,
} from './protectedApiSchemas';
import type {
  EnterpriseLanguage,
  SessionDetails,
  LegDetails,
} from './protectedApiSchemas';
import { z } from 'zod';
import { hrefBase } from '@/constants';

// All 403s will cause a redirect to the following URL.
// Override by setting autoLogoutUrl to undefined
// in any of the apis below.
const autoLogoutApi = api.extend({
  autoLogoutUrl: `${hrefBase}/logout?reason=timeout`,
});

/*
 * Enqueues a request to an inteprreter
 * -  402 message -> inactive
 * -  400 message -> session in progress
 * -  400 message -> Request button does not exist
 * -  400 message -> no device for user
 * -  201 (no content) -> success
 */
export async function enqueueVideoSession(
  id: number,
  preferredVideoResolution?: number
) {
  return await autoLogoutApi.post('/api/enqueue', {
    json: {
      enterprise_request_button_pk: id,
      preferred_video_resolution: preferredVideoResolution,
    },
  });
}

export async function enqueueAudioSession() {
  return await autoLogoutApi.post('/api/me/opi');
}

export async function enqueueSupportSession() {
  return await autoLogoutApi.post('/api/me/support');
}

/*
 * Updates the status on a session
 */
export async function updateSessionStatus(
  statusFlag: number,
  reasonCode?: number
) {
  await api.patch('/api/me/session/current', {
    json: {
      status_flag: statusFlag,
      end_reason: reasonCode,
    },
  });
}

/*
 * Cancels a session
 */
export async function cancelSession() {
  return await updateSessionStatus(StatusFlag.CANCELED);
}

/*
 * Disconnects a session
 */
export async function endSession() {
  return await updateSessionStatus(StatusFlag.DISCONNECTED);
}

/*
 * Fetches the current session and legs, returning not-found,
 * when no legs are found
 */

type SessionLookupResult =
  | { type: 'not-found' }
  | { type: 'found'; session: SessionDetails; legs: LegDetails };

export async function fetchCurrentSession(
  signal?: AbortSignal
): Promise<SessionLookupResult> {
  const sessionResponse = await api.get('/api/me/session/current', {
    signal,
    validationSchema: sessionDetailsSchema,
    retry: 1,
  });

  if (sessionResponse.status === 204) {
    return { type: 'not-found' };
  }

  const session =
    await sessionResponse.json<z.infer<typeof sessionDetailsSchema>>();

  const legs = await api
    .get('/api/me/session/current/legs?detached=true', {
      validationSchema: legDetailsSchema,
      retry: 1,
    })
    .json<z.infer<typeof legDetailsSchema>>();

  return {
    type: 'found',
    session,
    legs,
  };
}

/*
 * Sends presence pings to the backend to keep the
 * client's session alive.
 */
export async function sendPresencePing() {
  await api.put('/api/me/presence');
}

/*
 * Fetches the enterprise's request buttons
 */
export async function fetchLanguages(): Promise<EnterpriseLanguage[]> {
  return await autoLogoutApi
    .get('/api/me/enterprise/languages', {
      validationSchema: enterpriseLanguagesSchema,
    })
    .json<z.infer<typeof enterpriseLanguagesSchema>>();
}

/*
 * Fetches the post session feedback work
 */
export async function fetchPostSessionFeedback() {
  return await autoLogoutApi
    .get('/api/me/enterprise/questions/session_id_post', {
      validationSchema: postSessionFeedbackSchema,
    })
    .json<z.infer<typeof postSessionFeedbackSchema>>();
}

/*
 * Submits the post session feedback work
 */
export async function submitPostSessionFeedback(
  answers: Record<string, string | number>
) {
  await autoLogoutApi.post('/api/me/enterprise/questions/post/answers', {
    json: answers,
  });
}

/*
 * Session history
 */
export async function fetchRecentSessions() {
  return await autoLogoutApi
    .get('/api/me/device/sessions', {
      validationSchema: recentSessionsSchema,
    })
    .json<z.infer<typeof recentSessionsSchema>>();
}

/*/
 * Session history
 */
export async function fetchNotifications(localTimeZone: string) {
  return await autoLogoutApi
    .get(`/lo_api/notification_center/me/notifications?tz=${localTimeZone}`, {
      validationSchema: notificationsSchema,
    })
    .json<z.infer<typeof notificationsSchema>>();
}

/*
 * Record that the IVVR was watched.  Note that this is probably not needed,
 * but we'd have to check with BI.
 */
export async function logIVVRWatched(ivvrId: number) {
  return await autoLogoutApi.put('/api/me/session/current/ivvr', {
    json: {
      ivvr_pk: ivvrId,
      watched: true,
    },
  });
}

/*
 * Fetch the CafeX token
 */
export async function fetchCafeXToken() {
  return await autoLogoutApi
    .get('/api/me/cafex/token', {
      validationSchema: cafeXTokenSchema,
      retry: 0,
    })
    .json<z.infer<typeof cafeXTokenSchema>>();
}

/*
 * Update the user's audio preference
 */
export async function setLegAudioPreference(legId: number, isMuted: boolean) {
  const formData = new FormData();
  formData.append('is_audio_muted', isMuted ? 'true' : 'false');

  return await autoLogoutApi.patch(`/api/me/session/current/leg/${legId}`, {
    body: formData,
  });
}

/*
 * Update the user's video preference
 */
export async function setLegVideoPreference(legId: number, isMuted: boolean) {
  const formData = new FormData();
  formData.append('is_video_muted', isMuted ? 'true' : 'false');

  return await autoLogoutApi.patch(`/api/me/session/current/leg/${legId}`, {
    body: formData,
  });
}

/*
 * Fetch if the user is in an active session
 */
export async function fetchIsAuthenticated() {
  return await autoLogoutApi.get('/api/me/active', {
    retry: 0,
  });
}

/*
 * Fetch if the user is in an active session
 */
export async function fetchSessionChat() {
  const chatResponse = await api.get('/api/me/session/current/chat', {
    validationSchema: fetchSessionChatSchema,
  });

  if (chatResponse.status === 204) {
    return { type: 'not-found' as const };
  }

  const chat =
    await chatResponse.json<z.infer<typeof fetchSessionChatSchema>>();

  return { type: 'found' as const, chat: chat };
}

export async function notifyResumeAttempted(webResumeUUID: string) {
  await autoLogoutApi.post('/api/me/session/current/resume/attempts', {
    json: {
      web_resume_uuid: webResumeUUID,
    },
  });
}
