/**
 * Copyright © 2021, AMN Healthcare, Inc. All rights reserved.
 */

import React from 'react';
import { useLocation } from 'react-router-dom';
import { BrandedAMNImage } from '@/shared/BrandedAMNImage';
import { TextField } from '@/ui/fields/text-field';
import { Button } from '@/ui/button';
import { AlertMessage } from '@/ui/alert-message';
import { useForm, Controller } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useNavigate } from 'react-router-dom';
import api from '@/api/api';
import { svenErrorMessage } from '@/api/getError';
import { z } from 'zod';
import { getLicense } from './license';
import { getLogoutMessage } from './getLogoutMessage';
import { InfoMessage } from '@/ui/info-message';

function ssoErrorToMessage(code: string) {
  switch (code) {
    case 'no_code':
      // someone access the redirect url without any params (should not happen)
      return 'Please use the login form to sign-in.';
    case 'error':
      // sso provider returns an error
      return 'An error with the identity provider while attempting to sign-in.';
    case 'verification_failed':
      // backend can't validate the token or has an error
      return 'An error occurred while trying to validate your user.';
    case 'user_failed':
      // backend did not create the user for some reason (unsure, but it's a case)
      return 'An error occurred while trying to access your user.';
    default:
      // if we ever add an error code without a mapping
      return 'Unable to login with single sign-on. Please try again.';
  }
}

const loginSchema = z.object({
  username: z.string().min(1, { message: 'Required' }),
});

type RedirectToSSO = {
  username: string;
  authEndpoint: string;
  clientId: string;
};
function redirectToSSO({ username, authEndpoint, clientId }: RedirectToSSO) {
  // backend is going to exchange the access token and set a session cookie for us
  let params: Record<string, string> = {
    client_id: clientId,
    state: clientId, // backend needs this here
    response_type: 'code',
    redirect_uri: window.location.origin + '/oidc_api/code',
  };

  if (!authEndpoint.includes('.onelogin.com')) {
    params['login_hint'] = username;
    params['scope'] = 'offline_access profile email openid';
  } else {
    params['scope'] = 'profile email openid';
  }

  window.location.assign(authEndpoint + '?' + new URLSearchParams(params));
}

const apiSchema = z.discriminatedUnion('sso', [
  z.object({ sso: z.literal(false) }),
  z.object({
    sso: z.literal(true),
    authorization_endpoint: z.string(),
    client_id: z.string(),
    allowed_hosts: z.array(z.string()),
  }),
]);

type FormInput = z.input<typeof loginSchema>;
type FormOutput = z.output<typeof loginSchema>;

type LoginResult =
  | { type: 'idle' }
  | { type: 'toSignIn'; username: string }
  | { type: 'toSSO'; ssoInfo: RedirectToSSO };

export function Login() {
  const [licenseResult] = React.useState(() => getLicense());
  const location = useLocation();
  const reason = location.state?.reason;
  const ssoError = location.state?.ssoError;
  const logoutReason = getLogoutMessage(reason);

  const ssoErrorMessage = ssoError ? ssoErrorToMessage(ssoError) : undefined;

  const [loginResult, setLoginResult] = React.useState<LoginResult>({
    type: 'idle',
  });

  const navigate = useNavigate();

  React.useEffect(() => {
    if (licenseResult.type === 'found') {
      if (!window.hasSeenSignIn) {
        // redirect to sign-in
        navigate('/public/signin', { state: { reason: reason } });
        return;
      }
    }
  }, [licenseResult, navigate, reason]);

  const { setError, handleSubmit, control, formState } = useForm<
    FormInput,
    any,
    FormOutput
  >({
    shouldFocusError: true,
    resolver: zodResolver(loginSchema),
    defaultValues: {
      username:
        licenseResult.type === 'found' ? licenseResult.license.username : '',
    },
  });

  const onSubmit = async (data: FormOutput) => {
    try {
      // hit an endpoint that determines of our username is
      // should authenticate via sso or username/password
      const result = await api
        .post('/oidc_api/username', {
          json: {
            username: data.username,
            sven_app: 0, // for AI
            platform_type: 1, // for WEB
          },
          validationSchema: apiSchema,
        })
        .json<z.infer<typeof apiSchema>>();

      if (result.sso) {
        // Inject the sso allowed hosts into electron.
        if (window.tronApi && window.tronApi.allow) {
          window.tronApi.allow(result.allowed_hosts);
        }

        setLoginResult({
          type: 'toSSO',
          ssoInfo: {
            username: data.username,
            authEndpoint: result.authorization_endpoint,
            clientId: result.client_id,
          },
        });
        return;
      }

      if (data.username.includes('@')) {
        // sso is false, but the user included an @ in their username.
        // requirements are to present the following error message:
        setError('username', {
          type: 'server',
          message: 'Email address not found.',
        });
        return;
      }

      setLoginResult({ type: 'toSignIn', username: data.username });
    } catch (error) {
      const message = svenErrorMessage(error);
      setError('root', { type: 'server', message });
    }
  };

  // route the user where they need to go based on the api success result
  React.useEffect(() => {
    if (loginResult.type === 'toSSO') {
      // transitions to the user's sso endpoint
      redirectToSSO(loginResult.ssoInfo);
    }

    if (loginResult.type === 'toSignIn') {
      // transition to sign in with username
      navigate('/public/signin', { state: { username: loginResult.username } });
    }
  }, [navigate, loginResult]);

  return (
    <div className="space-y-2">
      <BrandedAMNImage className="mx-auto" />

      <form
        className="mx-auto max-w-sm space-y-2"
        onSubmit={handleSubmit(onSubmit)}
      >
        {logoutReason && <InfoMessage>{logoutReason}</InfoMessage>}

        {ssoErrorMessage && (
          <AlertMessage title="Single sign-on error">
            <p>{ssoErrorMessage}</p>
          </AlertMessage>
        )}

        {formState.errors.root?.message && (
          <AlertMessage title="Failed to continue">
            <p>{formState.errors.root?.message}</p>
          </AlertMessage>
        )}

        <div>
          <Controller
            render={({ field: { ...field }, fieldState: { error } }) => (
              <TextField
                type="text"
                isRequired
                label="Username or email"
                placeholder="Enter your username"
                errorMessage={error?.message}
                autoFocus
                autoComplete="username"
                {...field}
              />
            )}
            control={control}
            name="username"
          />

          {/* needed for some password managers to detect they can autofill the username */}
          <input
            className="sr-only"
            name="password"
            type="password"
            id="login--autofill-hint"
            tabIndex={-1}
            aria-hidden="true"
          />
        </div>
        <div className="text-right">
          <Button
            type="submit"
            variant="secondary"
            isDisabled={formState.isSubmitting || loginResult.type !== 'idle'}
          >
            Continue
          </Button>
        </div>
      </form>
    </div>
  );
}
