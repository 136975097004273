/**
 * Copyright © 2021, AMN Healthcare, Inc. All rights reserved.
 */

import React from 'react';

import {
  Row,
  Column,
  Cell,
  Table,
  TableBody,
  TableHeader,
  type CellProps,
  type RowProps,
  type ColumnProps,
} from 'react-aria-components';
import { MdArrowUpward } from 'react-icons/md';
import { cn } from '@/utils/cn';

function StyledColumn(
  props: Omit<ColumnProps, 'children'> & { children: React.ReactNode }
) {
  return (
    <Column
      {...props}
      className={cn(
        'sticky top-0 cursor-default whitespace-nowrap border-b border-slate-300 bg-slate-800 px-4 py-2 text-left text-sm font-semibold text-white outline-none data-[focus-visible]:outline data-[focus-visible]:outline-2 data-[focus-visible]:-outline-offset-4 data-[focus-visible]:outline-slate-600',
        props.className
      )}
    >
      {({ allowsSorting, sortDirection }) => (
        <span className="flex items-center">
          {props.children}
          {allowsSorting && (
            <span aria-hidden="true" className="ml-1 h-4 w-4">
              {sortDirection && (
                <MdArrowUpward
                  className={`h-4 w-4 transition ${
                    sortDirection === 'descending' ? 'rotate-180' : ''
                  }`}
                />
              )}
            </span>
          )}
        </span>
      )}
    </Column>
  );
}

function StyledRow<T extends object>(props: RowProps<T>) {
  return (
    <Row<T>
      {...props}
      className={cn(
        'group cursor-default outline-none even:bg-slate-200 aria-selected:bg-slate-600 aria-selected:text-white data-[focus-visible]:outline data-[focus-visible]:outline-2 data-[focus-visible]:-outline-offset-4 data-[focus-visible]:outline-slate-600 aria-selected:data-[focus-visible]:outline-white',
        props.className
      )}
    />
  );
}

function StyledCell(props: CellProps) {
  return (
    <Cell
      {...props}
      className={`px-4 py-2 text-sm ${props.className} data-[focus-visible]:outline data-[focus-visible]:outline-2 data-[focus-visible]:-outline-offset-4 data-[focus-visible]:outline-slate-600 group-aria-selected:data-[focus-visible]:outline-white`}
    />
  );
}

export {
  StyledRow as Row,
  StyledColumn as Column,
  StyledCell as Cell,
  Table,
  TableBody,
  TableHeader,
};
