/**
 * Copyright © 2021, AMN Healthcare, Inc. All rights reserved.
 */

// Adapted from https://webaudio.github.io/web-audio-api/#vu-meter-mode

export default function vuMeterProcessor() {
  const SMOOTHING_FACTOR = 0.94;

  registerProcessor(
    'vumeter',
    class extends AudioWorkletProcessor {
      constructor(options) {
        super();
        this._volume = 0;
        this._peak = 0;
        this._updateIntervalInMS = options.processorOptions.updateIntervalInMS;
        this._nextUpdateFrame = this._updateIntervalInMS;
        this.port.onmessage = event => {
          if (event.data.updateIntervalInMS)
            this._updateIntervalInMS = event.data.updateIntervalInMS;
        };
      }

      get intervalInFrames() {
        // sampleRate is a read-only property of a BaseAudioContext
        // that is set only during its instantiation
        // eslint-disable-next-line no-undef
        return (this._updateIntervalInMS / 1000) * sampleRate;
      }

      process(inputs) {
        const input = inputs[0];
        // Note that the input will be down-mixed to mono; however, if no inputs are
        // connected then zero channels will be passed in.
        if (input.length > 0) {
          const samples = input[0];

          let peak = 0;
          let sum = 0;
          let rms = 0;

          // Calculated the squared-sum and peaking
          for (let i = 0; i < samples.length; ++i) {
            let mx = Math.abs(samples[i]);
            sum += samples[i] * samples[i];
            peak = Math.max(peak, mx);
          }

          // Calculate the RMS level and update the volume.
          rms = Math.sqrt(sum / samples.length);
          this._volume = Math.max(rms, this._volume * SMOOTHING_FACTOR);
          this._peak = Math.max(peak, this._peak * SMOOTHING_FACTOR);

          // Update and sync the volume property with the main thread.
          this._nextUpdateFrame -= samples.length;
          if (this._nextUpdateFrame < 0) {
            this._nextUpdateFrame += this.intervalInFrames;
            this.port.postMessage({
              type: 'volume-update',
              volume: this._volume,
              peak: this._peak,
            });
          }
        }

        return true;
      }
    }
  );
}
