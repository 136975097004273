/**
 * Copyright © 2021, AMN Healthcare, Inc. All rights reserved.
 */

import { CallStateContext } from '../ProtectedPage';
import { SessionLayout } from './SessionLayout';
import { Button } from '@/ui/button';
import { useEnqueuedLanguage } from './useEnqueuedLanguage';
import { AlertMessage } from '@/ui/alert-message';

export function SessionEnqueueFailed() {
  const { send } = CallStateContext.useActorRef();
  const language = useEnqueuedLanguage();

  const errorMessage = CallStateContext.useSelector(
    (state) => state.context.enqueueError
  );

  return (
    <SessionLayout>
      <div className="flex h-full w-full justify-between space-x-4">
        <div className="items-left flex h-full flex-auto flex-col space-y-3">
          <h3 className="flex-none text-xl font-medium">
            Finding to find match
          </h3>
          <div className="h-full w-full flex-auto space-y-2">
            <AlertMessage title="Failed to match interpreter">
              <p>{errorMessage}</p>
            </AlertMessage>
            {language && <div className="italic">Requested {language}</div>}
          </div>
        </div>
        <div className="flex flex-none flex-col items-center gap-2">
          <Button
            onPress={() => send({ type: 'enqueue.start_over' })}
            type="button"
          >
            Go to languages
          </Button>
        </div>
      </div>
    </SessionLayout>
  );
}
