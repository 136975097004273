/**
 * Copyright © 2021, AMN Healthcare, Inc. All rights reserved.
 */

import ky, { HTTPError, TimeoutError } from 'ky';
import type { NormalizedOptions } from 'ky';
import Cookies from 'js-cookie';
import { ZodError } from 'zod';
import { noticeError } from '@/utils/noticeError';

class ParsedError extends HTTPError {
  json: unknown;

  constructor(
    response: Response,
    request: Request,
    options: NormalizedOptions,
    json: unknown
  ) {
    super(response, request, options);
    this.name = 'ParsedError';
    this.json = json;
  }
}

const api = ky.extend({
  retry: { limit: 0 },
  hooks: {
    beforeRequest: [
      (request) => {
        request.headers.set('X-CSRFToken', Cookies.get('csrftoken') || '');
      },
    ],
    afterResponse: [
      async (request, options, response) => {
        if (response) {
          if (response.status >= 400) {
            let json: unknown = null;

            try {
              json = await response.json();
            } catch (err) {}

            if (json !== null && typeof json === 'object') {
              if (options.autoLogoutUrl && response.status === 403) {
                window.location.assign(options.autoLogoutUrl);
              }
              throw new ParsedError(response, request, options, json);
            }
          }

          if (
            !response.ok ||
            !options.validationSchema ||
            response.status === 204
          ) {
            return response;
          }

          try {
            const data = await response.json();
            return new Response(
              JSON.stringify(options.validationSchema.parse(data))
            );
          } catch (err) {
            if (err && err instanceof ZodError) {
              noticeError(err, { url: request.url });
            }
            throw err;
          }
        }
      },
    ],
  },
});

type APIError = ParsedError | HTTPError | TimeoutError | ZodError;

export { ParsedError, HTTPError, TimeoutError };
export type { APIError };

export default api;
