/**
 * Copyright © 2021, AMN Healthcare, Inc. All rights reserved.
 */

import { NavLink } from 'react-router-dom';
import type { NavLinkProps } from 'react-router-dom';
import { cn } from '@/utils/cn';

export function HeaderNavLink(props: NavLinkProps) {
  return (
    <NavLink
      {...props}
      className={cn(
        'block whitespace-nowrap rounded-t-md px-3 py-2 text-gray-200 hover:bg-black/10 hover:text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-inset focus-visible:ring-white [&.active]:bg-gray-100 [&.active]:text-black [&.active]:focus-visible:ring-black',
        props.className
      )}
    />
  );
}

type HeaderProps = {
  navLinks?: React.ReactNode;
  userButton?: React.ReactNode;
  notificationButton?: React.ReactNode;
  misc?: React.ReactNode;
};
export function Header({
  navLinks,
  userButton,
  notificationButton,
  misc,
}: HeaderProps) {
  return (
    <header className="relative flex h-10 flex-row items-center gap-4 bg-branded2 text-white">
      <div className="container mx-auto flex items-center space-x-2">
        <nav aria-label="Main" className="">
          {navLinks}
        </nav>
        {misc}
      </div>
      <div className="absolute right-0 top-0 flex h-full items-center space-x-2">
        {notificationButton}
        <div className="flex h-full min-w-[200px] max-w-[300px] items-center bg-branded2-bold">
          {userButton}
        </div>
      </div>
    </header>
  );
}
