/**
 * Copyright © 2021, AMN Healthcare, Inc. All rights reserved.
 */

import React from 'react';
import type { AuthProfile } from '@/auth/authApiSchemas';

const AuthProfileContext = React.createContext<AuthProfile | undefined>(
  undefined
);

export function AuthProfileProvider({
  children,
  profile,
}: React.PropsWithChildren<{ profile: AuthProfile }>) {
  return (
    <AuthProfileContext.Provider value={profile}>
      {children}
    </AuthProfileContext.Provider>
  );
}

export function useAuthProfile() {
  const auth = React.useContext(AuthProfileContext);

  if (auth === undefined) {
    throw new Error(
      'useAuthProfile must be used in the context of an AuthProfileProvider'
    );
  }

  return auth;
}
