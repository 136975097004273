/**
 * Copyright © 2021, AMN Healthcare, Inc. All rights reserved.
 */

import React from 'react';
import type { Session } from '@/machines/callStateMachine';
import {
  type LegDetail,
  type SessionDetails,
  type SessionTypeEnum,
  SessionType,
} from '@/protected/apis/protectedApiSchemas';
import { useAuthProfile } from '@/protected/AuthProfileContext';
import * as R from 'remeda';

export function isActiveLeg(leg: LegDetail) {
  return leg.end_timestamp === null;
}

export const isLegInTypes = (legTypes: string[], leg: LegDetail) => {
  return legTypes.includes(leg.type);
};

export const isInternLeg = (leg: LegDetail) => {
  return leg.type === 'MI';
};

export const isConferenceLeg = (leg: LegDetail) => {
  return isLegInTypes(['CV', 'CA', 'CT', 'BL'], leg);
};

export const isAudioSession = (session: SessionDetails) => {
  return (
    [
      SessionType.AUDIOONLY,
      SessionType.AUDIOOUT,
      SessionType.RELAY,
      SessionType.CSR,
      SessionType.SUPPORT,
    ] as SessionTypeEnum[]
  ).includes(session.session_type);
};

const SessionContext = React.createContext<Session | undefined>(undefined);

export function useSession() {
  const session = React.useContext(SessionContext);

  if (session === undefined) {
    throw new Error('useSession must be used within a SessionProvider');
  }

  return session;
}

function useDeepCompareMemoize<T>(value: T) {
  const ref = React.useRef<T>();

  if (!R.equals(value, ref.current)) {
    ref.current = value;
  }

  return ref.current;
}

type UseLegResult = {
  legs: Record<number, LegDetail>;
  all: number[];
  active: number[];
  me: number | null;
  target: number | null;
};

const emptyUserLegResult: UseLegResult = {
  legs: {},
  all: [],
  active: [],
  me: null,
  target: null,
};

export function useLegs(): UseLegResult {
  const { legDetails } = useSession();

  const profile = useAuthProfile();

  // TODO is memo'ing necessary?  Will need to see how the legs object changes
  // when session (or non-leg) updates happen within the call machine
  // we could also move this logic to the call machine???
  const memoedLegs = useDeepCompareMemoize(legDetails);
  const username = profile.user.username;

  return React.useMemo(() => {
    if (!memoedLegs) return emptyUserLegResult;

    let legs: Record<number, LegDetail> = {};
    let all: number[] = [];
    let active: number[] = [];
    let me: number | null = null;
    let target: number | null = null;

    // sort by the most recent legs
    const sortedLegs = R.sortBy(memoedLegs, [
      (leg) => new Date(leg.start_timestamp),
      'desc',
    ]);

    sortedLegs.forEach((leg) => {
      // make a record of the current leg
      legs[leg.id] = leg;

      // add it to all the legs
      all.push(leg.id);

      // if it's an active leg
      if (isActiveLeg(leg)) {
        active.push(leg.id);

        // our leg is the one where the usernames match
        if (leg.user && leg.user.username === username) {
          me = leg.id;
          return;
        }

        // it's important that the legs are sorted by start date, desc,
        // for this to work:
        if (target === null) {
          // if we don't have a target yet, record the target
          target = leg.id;
        } else if (isConferenceLeg(leg) && !isConferenceLeg(legs[target]!)) {
          // if we have a target, but this leg is a conference leg, and the
          // target leg is not a conference leg, then give priority to the
          // conference leg.
          target = leg.id;
        }
      }
    });

    return {
      legs: legs,
      all: all,
      active: active,
      me: me,
      target: target,
    };
  }, [memoedLegs, username]);
}

export type SessionProviderProps = {
  session: Session;
  children: React.ReactNode;
};

export function SessionProvider({ session, children }: SessionProviderProps) {
  return (
    <SessionContext.Provider value={session}>
      {children}
    </SessionContext.Provider>
  );
}
