/**
 * Copyright © 2021, AMN Healthcare, Inc. All rights reserved.
 */

import React from 'react';
import { type LegDetail } from '@/protected/apis/protectedApiSchemas';
import { useLegs } from '@/protected/Session/SessionContext';
import { toast } from 'sonner';
import {
  setLegAudioPreference,
  setLegVideoPreference,
} from '@/protected/apis/protectedApiFetchers';
import { svenErrorMessage } from '@/api/getError';

type MediaPreference = {
  wantsAudio: boolean;
  wantsVideo: boolean;
  canHaveVideo: boolean;
};

type MediaPreferenceState = MediaPreference | undefined;

type MediaPreferenceAction =
  | { type: 'updateAudio'; wantsAudio: boolean }
  | { type: 'updateVideo'; wantsVideo: boolean }
  | {
      type: 'initialize';
      wantsAudio: boolean;
      wantsVideo: boolean;
      canHaveVideo: boolean;
    };

function mediaPreferenceReducer(
  state: MediaPreferenceState,
  action: MediaPreferenceAction
): MediaPreferenceState {
  switch (action.type) {
    case 'updateAudio':
      if (state === undefined) return undefined;
      return {
        ...state,
        wantsAudio: action.wantsAudio,
      };
    case 'updateVideo':
      if (state === undefined) return undefined;
      return {
        ...state,
        wantsVideo: state.canHaveVideo ? action.wantsVideo : false,
      };
    case 'initialize':
      return {
        wantsAudio: action.wantsAudio,
        wantsVideo: action.canHaveVideo ? action.wantsVideo : false,
        canHaveVideo: action.canHaveVideo,
      };
  }
}

type MediaPreferenceActions = {
  updateAudio: (wantsAudio: boolean) => void;
  updateVideo: (wantsVideo: boolean) => void;
};

type MediaPreferenceContextValue = [
  MediaPreferenceState,
  MediaPreferenceActions,
];

let MediaPreferenceContext = React.createContext<
  MediaPreferenceContextValue | undefined
>(undefined);

// Helper to figure out what platform is in use
function useMediaPreference() {
  const context = React.useContext(MediaPreferenceContext);
  if (context === undefined) {
    throw new Error(
      'useMediaPreference must be used within a MediaPreferenceProvider'
    );
  }
  return context;
}

type MediaPreferenceProviderProps = {
  children: React.ReactNode;
};

function getMediaPreference(leg: LegDetail | undefined): MediaPreferenceState {
  if (leg === undefined) return undefined;

  return {
    wantsAudio: !leg.is_audio_muted,
    wantsVideo: !leg.is_video_muted,
    canHaveVideo: true,
  };
}

// This Provider is responsible for managing our media preferences.
// We use the preferences from our initial leg, then we are directly
// updating this provider's state and using an effect to sync the
// state with the backend.
//
// The backend sync is needed so that others can see when our user
// is muted or in privacy based on the leg values.  Note that this
// is mostly needed for CafeX, as other media providers can just look
// at the webrtc media track values.
function MediaPreferenceProvider({ children }: MediaPreferenceProviderProps) {
  const legs = useLegs();

  const myLegId = legs.me;
  const ourLeg = myLegId ? legs.legs[myLegId] : undefined;

  const [state, dispatch] = React.useReducer(
    mediaPreferenceReducer,
    getMediaPreference(ourLeg)
  );

  // once we get our leg, update the state
  if (state === undefined && ourLeg !== undefined) {
    const result = getMediaPreference(ourLeg);

    if (result) {
      dispatch({
        type: 'initialize',
        ...result,
      });
    }
  }

  // Optimistically write the preference to the backend.  We then need to inform the
  // backend that a user has muted their media so that other participants and
  // future RTR can see the status change.  The preference is also used when the
  // user refreshes their browser.  setXMuted will show a notification on
  // failure.

  const updateAudio = React.useCallback(
    async (wantsAudio: boolean) => {
      if (myLegId === null) {
        return;
      }

      dispatch({ type: 'updateAudio', wantsAudio });

      try {
        await setLegAudioPreference(myLegId, !wantsAudio);
      } catch (error) {
        toast.error('Error saving audio preference', {
          description: svenErrorMessage(error),
        });
      }
    },
    [myLegId]
  );

  const updateVideo = React.useCallback(
    async (wantsVideo: boolean) => {
      if (myLegId === null) {
        return;
      }

      dispatch({ type: 'updateVideo', wantsVideo });

      try {
        await setLegVideoPreference(myLegId, !wantsVideo);
      } catch (error) {
        toast.error('Error saving video preference', {
          description: svenErrorMessage(error),
        });
      }
    },
    [myLegId]
  );

  const value = React.useMemo((): MediaPreferenceContextValue => {
    const actions = { updateVideo, updateAudio };

    return [state, actions];
  }, [state, updateAudio, updateVideo]);

  return (
    <MediaPreferenceContext.Provider value={value}>
      {children}
    </MediaPreferenceContext.Provider>
  );
}

export { useMediaPreference, MediaPreferenceProvider };
export type { MediaPreferenceProviderProps };
