/**
 * Copyright © 2021, AMN Healthcare, Inc. All rights reserved.
 */

// All calls are CafeX for now.
export function getPlatformType(sessionType: number) {
  switch (sessionType) {
    default:
      return 'CafeX' as const;
  }
}
