/**
 * Copyright © 2021, AMN Healthcare, Inc. All rights reserved.
 */

import React from 'react';
import { useSearchParams, useHref } from 'react-router-dom';

// We use this route for all logout requests, which
// will force a reload to ...
// 1) Easily let the user know when their network is down
//    and they are thus unable to logout.
// 2) Reset all in-app state.

export function RequestLogout() {
  const [searchParams] = useSearchParams();
  const redirectTo = useHref('/logout');
  const reason = searchParams.get('reason');

  React.useEffect(() => {
    // Can clean up any cache here if needed.

    if (reason && ['forced', 'expired', 'timeout'].includes(reason)) {
      window.location.replace(`${redirectTo}?reason=${reason}`);
    } else {
      window.location.replace(`${redirectTo}`);
    }
  }, [reason, redirectTo]);

  return (
    <div className="container mx-auto">
      <div className="space-y-2 p-2">
        <h3 className="loading-ellipsis text-xl font-medium">Logging out</h3>
        <p>Please wait while we attempt to log you out.</p>
      </div>
    </div>
  );
}
