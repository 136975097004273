/**
 * Copyright © 2021, AMN Healthcare, Inc. All rights reserved.
 */

import type { HTMLAttributes } from 'react';
import { cn } from '@/utils/cn';

import {
  Header as AriaHeader,
  Menu as AriaMenu,
  MenuTrigger as AriaMenuTrigger,
  Section as AriaSection,
  Item,
  Popover,
  Separator,
} from 'react-aria-components';

import type { MenuProps, PopoverProps, ItemProps } from 'react-aria-components';

const MenuTrigger = AriaMenuTrigger;
const Section = AriaSection;

const MenuPopover = ({
  children,
  className,
  ...props
}: PopoverProps & { className?: string }) => (
  <Popover
    {...props}
    className="data-[entering]:animate-fade data-[exiting]:animate-fadeOut overflow-auto rounded-xl border border-border bg-white shadow-xl "
  >
    {children}
  </Popover>
);

const MenuContent = <T extends object>({
  children,
  className,
  ...props
}: MenuProps<T> & { className?: string }) => (
  <AriaMenu
    {...props}
    className={cn('flex h-fit w-56 flex-col gap-1 p-2 outline-none', className)}
  >
    {children}
  </AriaMenu>
);

const MenuItem = ({
  children,
  className,
  ...props
}: ItemProps & { className?: string }) => (
  <Item
    {...props}
    className={cn(
      'relative flex cursor-pointer items-center justify-between rounded-md p-2 text-black outline-none data-[focused]:bg-accent data-[focused]:text-accent-foreground',
      className
    )}
  >
    {children}
  </Item>
);

const MenuHeader = ({
  children,
  className,
  ...props
}: HTMLAttributes<HTMLElement> & { className?: string }) => (
  <AriaHeader {...props} className={cn('p-2', className)}>
    {children}
  </AriaHeader>
);

const MenuSeperator = ({
  children,
  className,
  ...props
}: HTMLAttributes<HTMLElement> & { className?: string }) => (
  <Separator
    {...props}
    className={cn('mx-2 my-2 h-[1px] bg-gray-200', className)}
  />
);

export {
  MenuContent,
  MenuHeader,
  MenuItem,
  MenuSeperator,
  MenuTrigger,
  MenuPopover,
  Section,
};
