/**
 * Copyright © 2021, AMN Healthcare, Inc. All rights reserved.
 */

import {
  Modal as AriaModal,
  Heading as AriaHeading,
  Dialog as AriaDialog,
  DialogTrigger,
  Button,
} from 'react-aria-components';
import { cn } from '@/utils/cn';
import { MdClose } from 'react-icons/md';

function Modal({
  className,
  ...props
}: React.ComponentProps<typeof AriaModal>) {
  return (
    <AriaModal
      {...props}
      className={cn(
        'mx-auto w-full max-w-4xl rounded-lg border-2 bg-white p-0 text-left align-middle shadow-sm outline-none',
        className
      )}
    />
  );
}

function ModalDialog({
  className,
  ...props
}: React.ComponentProps<typeof AriaDialog>) {
  return (
    <AriaDialog
      className={cn(
        'relative box-border flex w-full flex-col rounded-xl outline-none',
        className
      )}
      {...props}
    >
      {({ close }) => (
        <>
          <Button
            type="button"
            className="tap-highlight-transparent data-[focus-visible=true]:outline-focus absolute right-1 top-1 select-none appearance-none rounded-full bg-white p-2 text-gray-800 outline-none hover:bg-gray-100 active:bg-gray-200 rac-focus-visible:ring-2 rac-focus-visible:ring-ring"
            onPress={close}
          >
            <MdClose title="Dismiss dialog" />
          </Button>
          <div className="p-6">
            {typeof props.children === 'function'
              ? props.children({ close })
              : props.children}
          </div>
        </>
      )}
    </AriaDialog>
  );
}

function ModalHeading({
  className,
  ...props
}: React.ComponentProps<typeof AriaHeading>) {
  return (
    <AriaHeading {...props} className={cn('text-xl font-medium', className)} />
  );
}

function ModalBody() {
  return null;
}

const Root = Modal;
const Heading = ModalHeading;
const Body = ModalBody;
const Dialog = ModalDialog;
const ModalDialogTrigger = DialogTrigger;

export {
  Root,
  Modal,
  ModalHeading,
  Heading,
  Dialog,
  ModalDialog,
  DialogTrigger,
  ModalDialogTrigger,
  ModalBody,
  Body,
};
