/**
 * Copyright © 2021, AMN Healthcare, Inc. All rights reserved.
 */

import React from 'react';

type UseCameraResult =
  | { type: 'off' }
  | { type: 'pending' }
  | { type: 'on'; stream: MediaStream }
  | { type: 'failure'; errorName: string };

type UseCameraProps = {
  on: boolean;
  constraints: MediaStreamConstraints;
};
export function useCamera({
  on,
  constraints,
}: UseCameraProps): UseCameraResult {
  const [request, setRequest] = React.useState<UseCameraProps>({
    on,
    constraints,
  });

  const [result, setResult] = React.useState<[UseCameraResult, UseCameraProps]>(
    [{ type: 'off' }, request]
  );

  // To handle race condition
  React.useEffect(() => {
    if (result[0].type === 'pending') {
      return;
    }

    const lastRequest = result[1];

    if (lastRequest.constraints !== constraints || lastRequest.on !== on) {
      setRequest({ on, constraints });
    }
  }, [result, on, constraints]);

  React.useEffect(() => {
    let active = true;
    let mediaStream: MediaStream | undefined = undefined;

    if (!request.on) {
      setResult([{ type: 'off' }, request]);
      return;
    }

    setResult([{ type: 'pending' }, request]);

    navigator.mediaDevices
      .getUserMedia(request.constraints)
      .then((stream) => {
        mediaStream = stream;

        if (active) {
          setResult([{ type: 'on', stream: stream }, request]);
        } else {
          stream.getTracks().forEach((track) => {
            track.stop();
          });
        }
      })
      .catch((err) => {
        if (active) {
          setResult([{ type: 'failure', errorName: err.name }, request]);
        }
      });

    return () => {
      active = false;

      if (mediaStream) {
        mediaStream.getTracks().forEach((track) => {
          track.stop();
        });
      }
    };
  }, [request]);

  return result[0];
}
