/*
 * Copyright © 2021, AMN Healthcare, Inc. All rights reserved.
 */

import React from 'react';
import { useAuthProfile } from '@/protected/AuthProfileContext';
import { useLocalStorage } from '@/shared/usePersistedStorage';
import { z } from 'zod';
import { StorageKeys } from '@/constants';
import * as R from 'remeda';

type Option = {
  id: React.Key;
  name: string;
  order: number;
  isDefault: boolean;
};

type Item = {
  id: number;
  width: number;
  height: number;
  name: string;
  order: number;
};

function createResolutionOption(item: Item, isDefault: boolean): Option {
  var suffix = isDefault ? ' [default]' : '';

  return {
    id: item.id,
    name: `${item.name} (${item.width}x${item.height})${suffix}`,
    order: item.order,
    isDefault,
  };
}

const resolutionSchema = z.number().nullable();

// Returns the preferred video resolution, that's been saved in
// the user's local storage.
//
// If their prefernce has been removed from the list, then we
// use the default instead.
//
// Returns the selection, a setter, and a list of preferred options.
export function usePreferredVideoResolution() {
  const profile = useAuthProfile();

  const preferredVideoResolution =
    profile.enterprise.preferred_video_resolution;

  const defaultSelection = preferredVideoResolution?.id ?? null;

  const [selection, setSelection] = useLocalStorage<number | null>(
    StorageKeys.preferredVideoResolution,
    defaultSelection,
    resolutionSchema
  );

  const options = React.useMemo((): Option[] => {
    if (!preferredVideoResolution) {
      return [];
    }

    const options = [
      createResolutionOption(preferredVideoResolution, true),
      ...preferredVideoResolution.alternatives.map((resolution) => {
        return createResolutionOption(resolution, false);
      }),
    ];

    // Honestly not sure why we require uniq here, but it was
    // in the old AI Web.  It could be in the event that our
    // selection is in the alternatives.
    return R.pipe(
      options,
      R.uniqBy((option) => option.id),
      R.sortBy((x) => x.id)
    );
  }, [preferredVideoResolution]);

  // Ensure the default preference is selected when nothing matches.
  const selectedKey = React.useMemo(() => {
    if (selection === null) return defaultSelection;

    if (options.some((option) => option.id === selection)) {
      return selection;
    }

    return defaultSelection;
  }, [options, selection, defaultSelection]);

  return [selectedKey, setSelection, options] as const;
}
