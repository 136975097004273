/**
 * Copyright © 2021, AMN Healthcare, Inc. All rights reserved.
 */

import React from 'react';
import type { Question } from '@/protected/apis/protectedApiSchemas';
import { TextField } from '@/ui/fields/text-field';
import { RatingField } from '@/ui/fields/rating-field';
import { SelectField, SelectFieldItem } from '@/ui/fields/select-field';
import { useFormContext, Controller } from 'react-hook-form';
import { type AnswerData, type FormInput } from '../SessionFinished';
import { AnswerType } from '@/protected/apis/protectedApiSchemas';
import { Key } from 'react-aria';

const noAnswerOption = { id: 'null', name: '-- No Answer --' };
const trueAnswerOption = { id: 'true', name: 'Yes' };
const falseAnswerOption = { id: 'false', name: 'No' };

const requiredBooleanOptions = [trueAnswerOption, falseAnswerOption];
const optionalBooleanOptions = [noAnswerOption, ...requiredBooleanOptions];

const transform = {
  [AnswerType.Rating]: {
    toForm: (value: number): AnswerData => {
      return {
        type: AnswerType.Rating,
        value: value,
      };
    },
    toController: (value: AnswerData | undefined): number => {
      if (value === undefined) return 0;

      if (value.type === AnswerType.Rating) {
        return value.value;
      }

      return 0;
    },
  },
  [AnswerType.Date]: {
    toForm: (value: string): AnswerData => {
      return {
        type: AnswerType.Date,
        value: value,
      };
    },
    toController: (value: AnswerData | undefined): string => {
      if (value === undefined) return '';

      if (value.type === AnswerType.Date) {
        return value.value;
      }

      return '';
    },
  },

  [AnswerType.Text]: {
    toForm: (value: string): AnswerData => {
      return {
        type: AnswerType.Text,
        value: value,
      };
    },
    toController: (value: AnswerData | undefined): string => {
      if (value === undefined) return '';

      if (value.type === AnswerType.Text) {
        return value.value;
      }

      return '';
    },
  },
  [AnswerType.Choice]: {
    toForm: (value: Key): AnswerData => {
      return {
        type: AnswerType.Choice,
        value: String(value),
      };
    },
    toController: (value: AnswerData | undefined): Key => {
      if (value === undefined) return '';

      if (value.type === AnswerType.Choice) {
        return value.value;
      }

      return '';
    },
  },
  [AnswerType.Boolean]: {
    toForm: (value: Key): AnswerData => {
      return {
        type: AnswerType.Boolean,
        value: String(value),
      };
    },
    toController: (value: AnswerData | undefined): Key => {
      if (value === undefined) return '';

      if (value.type === AnswerType.Boolean) {
        return value.value;
      }

      return '';
    },
  },
};

const requiredMessage = 'Required';

const answerValidation = (mandatory: boolean) => {
  return {
    required: (v: AnswerData | undefined) => {
      if (!mandatory) return;

      if (!v) return requiredMessage;

      switch (v.type) {
        case AnswerType.Boolean:
        case AnswerType.Date:
        case AnswerType.Choice:
        case AnswerType.Text:
          return v.value.length === 0 ? requiredMessage : undefined;
        case AnswerType.Rating:
          return v.value === 0 ? requiredMessage : undefined;
      }
    },
  };
};

type QuestionsProps = {
  questions: Question[];
};
export function Questions({ questions }: QuestionsProps) {
  const { control } = useFormContext<FormInput>();

  const choices = React.useMemo(() => {
    let result: Record<number, Array<{ id: string; name: string }>> = {};

    questions.forEach((question) => {
      if (question.answer_type === AnswerType.Choice) {
        result[question.pk] = question.mandatory ? [] : [noAnswerOption];

        question.available_choices.forEach((choice) =>
          result[question.pk]!.push({
            id: String(choice.pk),
            name: choice.text,
          })
        );
      }
    });

    return result;
  }, [questions]);

  return (
    <div className="space-y-4">
      <div className="divide-y divide-slate-200">
        {questions.map((question) => (
          <div key={question.answer_slug} className="py-3 first:pt-0 last:pb-0">
            {question.answer_type === AnswerType.Choice && (
              <Controller
                render={({
                  field: { value, onChange, ...field },
                  fieldState: { error },
                }) => (
                  <SelectField
                    label={question.question}
                    isRequired={question.mandatory}
                    description={question.help_text}
                    errorMessage={error?.message}
                    items={choices[question.pk] || []}
                    onSelectionChange={(val) =>
                      onChange(transform[AnswerType.Choice].toForm(val))
                    }
                    selectedKey={transform[AnswerType.Choice].toController(
                      value
                    )}
                    {...field}
                  >
                    {(item) => <SelectFieldItem>{item.name}</SelectFieldItem>}
                  </SelectField>
                )}
                control={control}
                name={question.answer_slug}
                rules={{ validate: answerValidation(question.mandatory) }}
              />
            )}
            {question.answer_type === AnswerType.Text && (
              <Controller
                render={({
                  field: { onChange, value, ...field },
                  fieldState: { error },
                }) => (
                  <TextField
                    type="text"
                    isRequired={question.mandatory}
                    label={question.question}
                    description={question.help_text}
                    errorMessage={error?.message}
                    maxLength={255}
                    onChange={(val) =>
                      onChange(transform[AnswerType.Text].toForm(val))
                    }
                    value={transform[AnswerType.Text].toController(value)}
                    {...field}
                  />
                )}
                control={control}
                name={question.answer_slug}
                rules={{ validate: answerValidation(question.mandatory) }}
              />
            )}
            {question.answer_type === AnswerType.Rating && (
              <Controller
                render={({
                  field: { value, onChange, ...field },
                  fieldState: { error },
                }) => (
                  <RatingField
                    isRequired={question.mandatory}
                    label={question.question}
                    description={question.help_text}
                    errorMessage={error?.message}
                    size="3em"
                    onChange={(value) =>
                      onChange(transform[AnswerType.Rating].toForm(value))
                    }
                    value={transform[AnswerType.Rating].toController(value)}
                    {...field}
                  />
                )}
                control={control}
                name={question.answer_slug}
                rules={{
                  validate: answerValidation(question.mandatory),
                }}
              />
            )}
            {question.answer_type === AnswerType.Boolean && (
              <Controller
                render={({
                  field: { value, onChange, ...field },
                  fieldState: { error },
                }) => (
                  <SelectField
                    label={question.question}
                    isRequired={question.mandatory}
                    description={question.help_text}
                    errorMessage={error?.message}
                    items={
                      question.mandatory
                        ? requiredBooleanOptions
                        : optionalBooleanOptions
                    }
                    onSelectionChange={(val) =>
                      onChange(transform[AnswerType.Boolean].toForm(val))
                    }
                    selectedKey={transform[AnswerType.Boolean].toController(
                      value
                    )}
                    {...field}
                  >
                    {(item) => <SelectFieldItem>{item.name}</SelectFieldItem>}
                  </SelectField>
                )}
                control={control}
                name={question.answer_slug}
                rules={{ validate: answerValidation(question.mandatory) }}
              />
            )}
            {question.answer_type === AnswerType.Date && (
              <Controller
                render={({
                  field: { onChange, value, ...field },
                  fieldState: { error },
                }) => (
                  <TextField
                    type="date"
                    isRequired={question.mandatory}
                    label={question.question}
                    description={question.help_text}
                    errorMessage={error?.message}
                    maxLength={255}
                    onChange={(val) =>
                      onChange(transform[AnswerType.Date].toForm(val))
                    }
                    value={transform[AnswerType.Date].toController(value)}
                    {...field}
                  />
                )}
                control={control}
                name={question.answer_slug}
                rules={{ validate: answerValidation(question.mandatory) }}
              />
            )}
          </div>
        ))}
      </div>
    </div>
  );
}
