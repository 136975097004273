/**
 * Copyright © 2021, AMN Healthcare, Inc. All rights reserved.
 */

import React from 'react';
import { cn } from '@/utils/cn';
import { type IVVR } from '@/protected/apis/protectedApiSchemas';
import { useLocalStorage } from '@/shared/usePersistedStorage';
import { z } from 'zod';
import { Slider, SliderTrack, SliderThumb } from 'react-aria-components';
import { Button } from '@/ui/button';
import {
  MdVolumeDown,
  MdVolumeMute,
  MdVolumeOff,
  MdVolumeUp,
} from 'react-icons/md';

type IVVRVideoProps = {
  className?: string;
  ivvr: IVVR;
};

const volumeSchema = z.number();

// Plays the IVVR.  If autoPlay policy becomes an issue, or we need
// to record when the user watches the ivvr, then we can use the code
// below, but to get to this page the user must first interact with
// the app.
export function IVVRVideo({ className, ivvr }: IVVRVideoProps) {
  const videoRef = React.useRef<HTMLVideoElement>(null);

  const { h264_file: h264, webm_file: webm } = ivvr;

  const [volume, setVolume] = useLocalStorage(
    'aiweb:ivvr-volume',
    100,
    volumeSchema
  );

  const handleChange = React.useCallback(
    (newVolume: number) => {
      setVolume(newVolume);
    },
    [setVolume]
  );
  //
  // Map volume domain to the appropriate icon
  let VolumeIcon = MdVolumeUp;
  if (volume > 75) {
    VolumeIcon = MdVolumeUp;
  } else if (volume === 0) {
    VolumeIcon = MdVolumeOff;
  } else if (volume < 10) {
    VolumeIcon = MdVolumeMute;
  } else {
    VolumeIcon = MdVolumeDown;
  }

  const toggleVolume = () => {
    if (volume > 0) {
      setVolume(0);
    } else {
      setVolume(100);
    }
  };

  React.useEffect(() => {
    if (videoRef.current) {
      videoRef.current.volume = volume / 100;
    }
  }, [volume]);

  return (
    <div className={cn(className, 'relative w-fit bg-black')}>
      <video
        controls={false}
        autoPlay
        loop
        className="object-contain"
        ref={videoRef}
      >
        <source src={h264} type="video/mp4" />
        <source src={webm} type="video/webm" />
      </video>
      {ivvr.message && (
        <div className="max-w-full bg-black p-2 text-sm text-white break-anywhere">
          {ivvr.message}
        </div>
      )}
      <div className="flex w-full items-center space-x-4 border-t border-t-gray-700 bg-black px-2 py-1 text-white">
        <Button
          onPress={toggleVolume}
          size="sm"
          variant="ghost"
          aria-label="Toggle volume"
        >
          <VolumeIcon />
        </Button>
        <Slider
          value={volume}
          onChange={handleChange}
          minValue={0}
          maxValue={100}
          step={5}
          orientation="horizontal"
          className="w-12"
          aria-label="Volume"
        >
          <SliderTrack className="relative h-2 w-full">
            {({ state }) => (
              <>
                <div className="absolute top-[50%] h-1 w-full translate-y-[-50%] transform rounded-full bg-white bg-opacity-40" />
                <div
                  className="absolute top-[50%] h-1 translate-y-[-50%] transform rounded-full bg-white"
                  style={{ width: state.getThumbPercent(0) * 100 + '%' }}
                />
                <SliderThumb className="top-[50%] h-4 w-4 rounded-full border border-purple-800/75 bg-white outline-none transition-colors data-[dragging]:bg-purple-100/80 data-[focus-visible]:ring-2 data-[focus-visible]:ring-black" />
              </>
            )}
          </SliderTrack>
        </Slider>
      </div>
    </div>
  );
}
