/**
 * Copyright © 2021, AMN Healthcare, Inc. All rights reserved.
 */

import { CallStateContext } from '../ProtectedPage';
import { Button } from '@/ui/button';
import { IVVRVideo } from './ui/IVVRVideo';
import { SessionSidebarLayout } from './SessionSidebarLayout';

export function SessionEnqueueSuspended() {
  const { send } = CallStateContext.useActorRef();

  const suspension = CallStateContext.useSelector((s) => {
    return s.context.enqueueSuspension;
  });

  const ivvr = suspension?.ivvr;
  const title = suspension?.message || 'Your service has been suspended';

  return (
    <SessionSidebarLayout
      title={title}
      sidebar={
        <Button onPress={() => send({ type: 'session.reload' })} type="button">
          Ok
        </Button>
      }
    >
      {ivvr && <IVVRVideo ivvr={ivvr} />}
    </SessionSidebarLayout>
  );
}
