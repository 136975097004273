/**
 * Copyright © 2021, AMN Healthcare, Inc. All rights reserved.
 */

type BasicLayoutProps = {
  header: React.ReactNode;
  body: React.ReactNode;
};

export function BasicLayout({ header, body }: BasicLayoutProps) {
  return (
    <div className="relative min-h-full overflow-hidden">
      <div className="absolute bottom-0 left-0 right-0 top-0 isolate flex min-h-full flex-col overflow-hidden">
        <div className="flex-none">{header}</div>
        <div className="grid flex-auto grid-rows-[1fr_auto] overflow-y-scroll bg-gray-100">
          {body}
        </div>
      </div>
    </div>
  );
}
