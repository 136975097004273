/**
 * Copyright © 2021, AMN Healthcare, Inc. All rights reserved.
 */

import React from 'react';

type GenericErrorBodyProps = { code: string };
export function GenericErrorBody({ code }: GenericErrorBodyProps) {
  return (
    <div className="space-y-2">
      <p>
        If the problem persists after reloading, please end the session and
        report to the Agent Support Channel.
      </p>
      <p>Error Code: {code}</p>
      <p>Would you like to reload the app and try again?</p>
    </div>
  );
}

export type DialogState = {
  body: React.ReactNode;
  title: string;
  requiresReload: boolean;
};

type SessionErrorDialogState = {
  contents: DialogState | null;
};

type BaseOpen = {
  title: string;
  requiresReload: boolean;
};

type OpenOptions =
  | (BaseOpen & { body: string; code?: never })
  | (BaseOpen & { body?: never; code: string });

type SessionErrorDialogAction =
  | { type: 'open'; contents: OpenOptions }
  | { type: 'close' };

function dialogReducer(
  state: SessionErrorDialogState,
  action: SessionErrorDialogAction
): SessionErrorDialogState {
  switch (action.type) {
    case 'open':
      const { body, code, title, requiresReload } = action.contents;
      return {
        ...state,
        contents: {
          body: code ? <GenericErrorBody code={code} /> : body,
          title,
          requiresReload,
        },
      };
    case 'close':
      return {
        ...state,
        contents: null,
      };
  }
}

type SessionErrorDialogContextValue = [
  SessionErrorDialogState,
  React.Dispatch<SessionErrorDialogAction>,
];

let SessionErrorDialogContext = React.createContext<
  SessionErrorDialogContextValue | undefined
>(undefined);

// Helper to figure out what platform is in use
function useSessionErrorDialog() {
  const context = React.useContext(SessionErrorDialogContext);
  if (context === undefined) {
    throw new Error(
      'useSessionErrorDialog must be used within a SessionErrorDialogProvider'
    );
  }
  return context;
}

type SessionErrorDialogProviderProps = {
  children: React.ReactNode;
};

function SessionErrorDialogProvider({
  children,
}: SessionErrorDialogProviderProps) {
  const [state, dispatch] = React.useReducer(dialogReducer, {
    contents: null,
  });

  const value = React.useMemo((): SessionErrorDialogContextValue => {
    return [state, dispatch];
  }, [state, dispatch]);

  return (
    <SessionErrorDialogContext.Provider value={value}>
      {children}
    </SessionErrorDialogContext.Provider>
  );
}

export { useSessionErrorDialog, SessionErrorDialogProvider };
export type { SessionErrorDialogProviderProps };
