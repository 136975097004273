/**
 * Copyright © 2021, AMN Healthcare, Inc. All rights reserved.
 */

import {
  createMachine,
  assign,
  fromPromise,
  fromEventObservable,
  stop,
  sendTo,
  sendParent,
  pure,
} from 'xstate';
import type { ActorRefFrom } from 'xstate';
import {
  type SessionDetails,
  type LegDetail,
  type LegDetails,
  type StatusFlagEnum,
  type IVVR,
  qUnavailableMessageSchema,
  connectionLossMessageSchema,
  oidcResumeAttemptedMessageSchema,
  OidcResumeAttempted,
  QueueRequestType,
  StatusFlag,
} from '@/protected/apis/protectedApiSchemas';
import {
  sessionWebsocketConnection$,
  sessionWebsocketStatus$,
} from '@/protected/apis/protectedApiWebsockets';
import type { SessionWebsocketMessages } from '@/protected/apis/protectedApiWebsockets';
import { map, filter, distinctUntilKeyChanged } from 'rxjs/operators';
import { APIError, HTTPError, ParsedError } from '@/api/api';
import { mergeLegs, updateLeg } from './callStateUtils';
import * as backend from '@/protected/apis/protectedApiFetchers';
import { svenErrorMessage } from '@/api/getError';
import { Interval } from 'luxon';
import { z } from 'zod';
import { contextId } from '@/constants';

// NOTE:
// Due to a lack of proper typescript support we must
// cast our error types (see the failure actions); therefore,
// do not change the invoking `id`s without also changing
// the types.

function differenceInSeconds(lhs: Date, rhs: Date) {
  return ~~Interval.fromDateTimes(rhs, lhs).length('seconds');
}

const hasHTTPStatus = (error: unknown, status: number) =>
  Boolean(error) &&
  error instanceof HTTPError &&
  error.response.status === status;

// Chat message from the interpreter
export type Chat = {
  message: string;
  timestamp: Date;
};

// Our session object, which is the heart of this application
export type Session = {
  sessionDetails: SessionDetails;
  legDetails?: LegDetails;
  // Is the user ready to connect their media
  readyToConnect: boolean;
  chat: Chat | null;
  // Has the user explicitly attached their session
  isResumed: boolean;
  // Has the oidcresumeattempted message come in
  oidcResumeAttempted: OidcResumeAttempted | null;
};

// Keep track of where the data came from
type UpdateSource = 'socket' | 'xhr';

// Websocket actor actions
type SocketActions =
  // Websocket came online
  | { type: 'socket.online' }
  // Websocket went offline
  | { type: 'socket.offline' };

// Session actions
type SessionActions =
  // Reload the page
  | { type: 'session.reload' }
  // Attach (or join) the session
  | { type: 'session.attach' }
  // Reject the detached session
  | { type: 'session.reject' }
  // No session was found by our check session actor
  | { type: 'session.notFound' }
  // Request to leave the session
  | { type: 'session.leaveSession' }
  // Request to cancel the session
  | { type: 'session.cancelSession' }
  // Update the session (from websocekt or xhr)
  | {
      type: 'session.updateSession';
      session: SessionDetails;
      legs?: LegDetails;
      source: UpdateSource;
    }
  // Update the legs (from websocket)
  | {
      type: 'session.updateLegs';
      legs: LegDetails;
      session_id: number;
      source: UpdateSource;
    }
  // A message told us the session is ready to connect media
  | {
      type: 'session.readyToConnect';
      session_id: number;
    }
  // The user had their session leg end (SESSIONLEGDROPPED)
  | {
      type: 'session.sessionLegDropped';
      session_id: number;
      leg: LegDetail;
    }
  // The queue request is unavailable (QUNAVIALABLE)
  | {
      type: 'session.qUnavailable';
      message: z.infer<typeof qUnavailableMessageSchema>;
    }
  // The SSO user is trying to login on another device
  | {
      type: 'session.oidcResumeAttempted';
      message: z.infer<typeof oidcResumeAttemptedMessageSchema>;
    }
  // An interpreter lost their connection
  | {
      type: 'session.connectionLoss';
      message: z.infer<typeof connectionLossMessageSchema>;
    }
  // We have a new chat message
  | {
      type: 'session.updateChat';
      timestamp: Date;
      sessionId: number;
      message: string;
      source: UpdateSource;
    }
  // We no longer have a chat message
  | {
      type: 'session.clearChat';
      timestamp: Date;
      source: UpdateSource;
    };

type EnqueueActions =
  // The has enqueued a request (or requested a language interpreter, audio, or support)
  | { type: 'enqueue.enqueue'; request: EnqueueRequest }
  // The user has requested to start the process over
  | { type: 'enqueue.start_over' };

// FIXME:: Pending xstate v5 typescript support.

// Temporarily define the invoke (xhr) failure types.
type FailureActions =
  | { type: 'error.platform.acceptSession'; data: APIError }
  | { type: 'error.platform.rejectSession'; data: APIError }
  | { type: 'error.platform.cancelSession'; data: APIError }
  | { type: 'error.platform.enqueueSession'; data: APIError }
  | { type: 'error.platform.endSession'; data: APIError };

// Map the session websocket messages to our xstate actions
function mapSessionSocketMessages(
  message: SessionWebsocketMessages
): SessionActions | null {
  const { action_type } = message;

  switch (action_type) {
    // The backend has updated the session
    case 'SESSIONUPDATE':
      return {
        type: 'session.updateSession',
        session: message.session,
        source: 'socket',
      };
    // The backend has given us a new set of legs
    case 'SESSIONLEGUPDATE':
      return {
        type: 'session.updateLegs',
        legs: [...message.inactive_legs, ...message.legs],
        session_id: message.session_id,
        source: 'socket',
      };
    // The backend has told us we can join media.  Note that
    // we could miss this message, which is why we automatically,
    // assume this came in when we refresh the page and get the session
    // info over an XHR message.
    case 'SESSIONLEGJOINED':
    case 'CONFERENCELEGJOINED':
      return {
        type: 'session.readyToConnect',
        session_id: message.session_id,
      };
    // Our user has had their leg dropped.
    case 'SESSIONLEGDROPPED':
      return {
        type: 'session.sessionLegDropped',
        session_id: message.session_id,
        leg: message.session_leg,
      };
    // The Queue Request is now unavailable.
    case 'QUNAVAILABLE':
      return {
        type: 'session.qUnavailable',
        message: message,
      };
    // We have a chat message from the interpreter.
    case 'SESSIONCHAT':
      return {
        type: 'session.updateChat',
        timestamp: new Date(message.chat.sent_timestamp),
        sessionId: message.session_id,
        message: message.chat.message,
        source: 'socket',
      };
    // We no longer have a chat message from the interpreter.
    case 'CLEARCHATMESSAGE':
      return {
        type: 'session.clearChat',
        timestamp: new Date(),
        source: 'socket',
      };
    // The interpreter has lost their internet connection.
    case 'CONNECTION_LOSS':
      return {
        type: 'session.connectionLoss',
        message: message,
      };
    // The SSO user has attempted to login on another device.
    case 'OIDCRESUMEATTEMPTED':
      return {
        type: 'session.oidcResumeAttempted',
        message: message,
      };
    default:
      return null;
  }
}

// -----------------------------------------------------------------------------
// Presence ping session machine
// -----------------------------------------------------------------------------

// Repeatedly send (xhr) presence pings to the server so that
// it knows our "client" user is still online.  This should
// only run while in session.  If these stop, then the
// backend will kill the session.
const pingMachine = createMachine({
  id: 'pingMachine',
  initial: 'pinging',
  states: {
    repeating: {
      after: {
        15_000: 'pinging',
      },
    },
    pinging: {
      invoke: {
        id: 'makePingRequest',
        src: fromPromise(() => backend.sendPresencePing()),
        onDone: 'repeating',
        onError: 'repeating',
      },
    },
  },
});

// -----------------------------------------------------------------------------
// Leave session machine
// -----------------------------------------------------------------------------

// Whether or not we are canceling or ending the session.
type EndSessionReason = 'end' | 'cancel';

// End the session (either end or cancel)
type EndSessionActions = { type: 'endSession'; reason: EndSessionReason };

// Temporarily define the invoke (xhr) failure types.
type EndSessionFailureActions = {
  type: 'error.platform.endingSession';
  data: { error: APIError; reason: EndSessionReason };
};

// Our cancel machine actions
type CancelMachineActions = EndSessionActions | EndSessionFailureActions;

// Our state machine for canceling or ending the session
export const cancelSessionMachine = createMachine({
  id: 'cancelMachine',
  initial: 'idle',
  states: {
    // Wait for an action to end the session
    idle: {
      on: {
        endSession: 'ending',
      },
    },
    // Pending to end the session
    ending: {
      // Invoke the xhr request to end the session
      invoke: {
        id: 'endingSession',
        src: fromPromise(async ({ input }: { input: EndSessionReason }) => {
          try {
            if (input === 'cancel') {
              await backend.cancelSession();
              return { type: 'waiting' };
            }
            await backend.endSession();
            return { type: 'waiting' };
          } catch (error) {
            // 404 means, we are no longer in session.
            if (hasHTTPStatus(error, 404)) {
              return { type: 'notFound' };
            }

            // Rewthrow with the type of reject, so we can have
            // a more descriptive error to the user.
            return Promise.reject({ error, reason: input });
          }
        }),
        input: ({ event }: { event: EndSessionActions }) => event.reason,
        onDone: [
          {
            // We are no longer in session.
            // We send a session.notFound to the parent so they
            // can transition to the right place, which is unknown.expired
            target: 'waiting',
            actions: sendParent(() => {
              return { type: 'session.notFound' };
            }),
            guard: ({ event }) => {
              return event.output.type === 'notFound';
            },
          },
          { target: 'waiting' },
        ],
        onError: {
          // An error occured, show a toast.
          target: 'idle',
          actions: 'handleLeaveSessionFailure',
        },
      },
    },
    // Wait for the callMachine to handle the socket update and transition the user
    // to the right state (of no longer being in session.)
    waiting: {},
  },
  types: {
    events: {} as CancelMachineActions,
  },
});

// -----------------------------------------------------------------------------
// Session chat message machine
// -----------------------------------------------------------------------------

// When this machine is spawned, it checks for an active chat message.
export const chatSessionMachine = createMachine(
  {
    id: 'chatSession',
    initial: 'fetching',
    states: {
      fetching: {
        invoke: {
          id: 'fetchSessionChat',
          src: fromPromise(() => backend.fetchSessionChat()),
          onDone: {
            target: 'finished',
            actions: sendParent(({ event }): SessionActions => {
              const data = (event as any).output as Awaited<
                ReturnType<typeof backend.fetchSessionChat>
              >;

              if (data.type === 'found') {
                // send the parent the new chat message
                return {
                  type: 'session.updateChat',
                  timestamp: new Date(data.chat.sent_timestamp),
                  sessionId: data.chat.session_id,
                  message: data.chat.message,
                  source: 'xhr',
                };
              } else {
                // send the parent an instruction to clear the chat
                return {
                  type: 'session.clearChat',
                  timestamp: new Date(),
                  source: 'xhr',
                };
              }
            }),
          },
          onError: { target: 'failure', actions: 'logError' },
        },
      },
      finished: {},
      failure: {},
    },
    context: {},
    types: {},
  },
  {
    actions: {
      logError: (data: any) => {
        console.error('Error while checking for chat', data);
      },
    },
  }
);

// -----------------------------------------------------------------------------
// Check for session machine
// -----------------------------------------------------------------------------

type CheckForSessionContext = {};

type CheckForSessionActions =
  | { type: 'syncSession.search' }
  | { type: 'syncSession.stop' };

// Listens for syncSession commands from the parent.
// Will reply back with a session update or session not found.
// This machine is long lived.
export const checkForSessionMachine = createMachine(
  {
    id: 'checkForSession',
    initial: 'idle',
    on: {
      'syncSession.search': '.fetching',
      'syncSession.stop': '.idle',
    },
    states: {
      idle: {},
      fetching: {
        invoke: {
          id: 'checkForSessionRequest',
          src: fromPromise(() => backend.fetchCurrentSession()),
          onDone: {
            target: 'finished',
            actions: sendParent(({ event }) => {
              const data = (event as any).output as Awaited<
                ReturnType<typeof backend.fetchCurrentSession>
              >;

              if (data.type === 'found') {
                return {
                  type: 'session.updateSession',
                  session: data.session,
                  legs: data.legs,
                  source: 'xhr',
                };
              } else {
                return { type: 'session.notFound' };
              }
            }),
          },
          onError: { target: 'retry', actions: 'logError' },
        },
      },
      finished: {},
      retry: {
        after: {
          10_000: 'fetching',
        },
      },
    },
    context: {},
    types: {
      context: {} as CheckForSessionContext,
      events: {} as CheckForSessionActions,
    },
  },
  {
    actions: {
      logError: (data: any) => {
        console.error('Error while checking for session', data);
      },
    },
  }
);

// -----------------------------------------------------------------------------
// Socket Message Machine
// -----------------------------------------------------------------------------

// This machine subscribes to the session websocket and transforms all messages
// to call machine actions
const socketMessageMachine = fromEventObservable(() => {
  // Filter on only the websocket messages this machine cares
  // about.  Remap the action_type to a type xstate can work
  // with.
  return sessionWebsocketConnection$.pipe(
    map((message) => mapSessionSocketMessages(message)),
    filter(Boolean)
  );
});

// -----------------------------------------------------------------------------
// Socket Status Machine
// -----------------------------------------------------------------------------

// This machine subscribes to the session websocket status and lets us know if
// the socket is online or offline.
const socketStatusMachine = fromEventObservable(() => {
  return sessionWebsocketStatus$.pipe(
    map((message) => {
      if (message.type === 'connected') {
        return { type: 'socket.online' };
      } else {
        return { type: 'socket.offline' };
      }
    }),
    distinctUntilKeyChanged('type')
  );
});

// -----------------------------------------------------------------------------
// Call State Machine
// -----------------------------------------------------------------------------

type CallStateEvents =
  | EnqueueActions
  | SocketActions
  | SessionActions
  | FailureActions;

// This is our main state machine.  It lets us know where the user should be in
// the application.
export const createCallStateMachine = () => {
  return createMachine(
    {
      id: 'callState',
      initial: 'Session',
      on: {
        // Our socket has connected, check for an existing session.
        'socket.online': { actions: 'runSyncSession' },
        // Our socket has disconnected, stop the check.
        'socket.offline': { actions: 'stopSyncSession' },
      },
      states: {
        Session: {
          initial: 'checking',
          states: {
            // This is the initial step and we don't know where we are yet,
            // so we are checking for the status (or really just waiting)
            // for the check session machine to send us a message.
            checking: {
              description: 'Waiting to learn if the user is in a session',
              on: {
                // TODO: we could also check for post session work, but I'm
                // not sure that's necessary.
                'session.updateSession': {
                  target: '#active',
                  guard: 'isSessionActive',
                  actions: 'newSession',
                },
                'session.notFound': {
                  target: '#none',
                },
              },
            },
            // We have no session.
            none: {
              id: 'none',
              description: 'The user is not is session.',
              initial: 'Enqueue',
              states: {
                // Ready to enqueue the session.
                Enqueue: {
                  description:
                    'Ready to enqueue the session for an interpreter (or support)',
                  initial: 'idle',
                  states: {
                    // A request is being made for an interpreter (or support)
                    enqueing: {
                      // Worth noting that if an xhr response shares similar content
                      // to a websocket message, then the websocket message will often
                      // beat the xhr response.
                      description: 'Enqueue the request.',
                      initial: 'invoking',
                      states: {
                        // Make the xhr audio, support, or video request.
                        invoking: {
                          invoke: {
                            id: 'enqueueSession',
                            src: fromPromise(
                              ({ input }: { input: EnqueueRequest }) => {
                                switch (input.type) {
                                  case 'audio':
                                    return backend.enqueueAudioSession();
                                  case 'support':
                                    return backend.enqueueSupportSession();
                                  case 'video':
                                    return backend.enqueueVideoSession(
                                      input.buttonId,
                                      input.preferredVideoResolution
                                    );
                                }
                              }
                            ),
                            input: ({
                              context,
                            }: {
                              context: CallSessionContext;
                            }) => context.enqueueRequest!,
                            onDone: {
                              target: 'waiting',
                            },
                            onError: [
                              {
                                target: '#none.Enqueue.suspended',
                                actions: ['setEnqueueSuspension', 'logError'],
                                guard: 'isEnqueueSuspended',
                              },
                              {
                                target: '#none.Enqueue.failed',
                                actions: ['setEnqueueError', 'logError'],
                              },
                            ],
                          },
                        },
                        // We are waiting for a websocket message to take us
                        // to the right place, or for the user to cancel the request.
                        waiting: {
                          initial: 'idle',
                          states: {
                            idle: {
                              on: {
                                'session.cancelSession': 'canceling',
                              },
                            },
                            canceling: {
                              invoke: {
                                id: 'cancelSession',
                                src: fromPromise(() => backend.cancelSession()),
                                onDone: {
                                  // We wait for the session update; otherwise,
                                  // we could probably transition to finished
                                },
                                onError: {
                                  // NOTE user will be stuck on this page
                                  // until the backend allows them to cancel.
                                  target: 'idle',
                                  actions: 'handleCancelSessionFailure',
                                },
                              },
                            },
                          },
                        },
                      },
                      on: {
                        // We could handle qunavailable here, but since
                        // we're the only people on the call at this time,
                        // we'll also get a sessionupdate set to unavailable;
                        // therefore, let's use that instead (and not process
                        // session.qUnavailable.
                        //
                        // We got a session update while we've enqueued a call.
                        // Transition accordingly.
                        'session.updateSession': [
                          {
                            target: '#unavailable',
                            guard: 'isSessionUnavailable',
                            actions: 'newSession',
                          },
                          {
                            target: '#active',
                            guard: 'isSessionActive',
                            actions: 'newSession',
                          },
                          {
                            target: '#finished',
                            guard: 'isSessionFinished',
                            actions: 'newSession',
                          },
                          {
                            target: '#finished',
                            guard: 'isSessionCanceled',
                            actions: 'newSession',
                          },
                        ],
                      },
                    },
                    // The enqueue has failed
                    failed: {
                      on: {
                        'enqueue.start_over': {
                          target: 'idle',
                        },
                      },
                    },
                    // The user's account is suspended
                    suspended: {
                      on: {
                        'session.reload': {
                          actions: 'reload',
                        },
                      },
                    },
                    // Waiting for the enqueue request
                    idle: {
                      entry: 'clearEnqueueRequest',
                      on: {
                        'enqueue.enqueue': {
                          target: 'enqueing',
                          actions: 'setEnqueueRequest',
                        },
                      },
                    },
                  },
                },
              },
              // We didn't have a session, but now we do.
              on: {
                'session.updateSession': {
                  target: '#active',
                  guard: 'isSessionActive',
                  actions: 'newSession',
                },
              },
            },
            // Some session was found
            some: {
              id: 'some',
              initial: 'active',
              states: {
                // Our session has the unavailable status flag
                unavailable: {
                  id: 'unavailable',
                  on: {
                    'session.reload': {
                      actions: 'reload',
                    },
                  },
                },
                // An SSO user logged in on a different device
                oidcResumeAttempted: {
                  id: 'oidcResumeAttempted',
                  on: {
                    'session.reload': {
                      actions: 'reload',
                    },
                  },
                },
                // We have an active session (determined by the session status flag)
                active: {
                  id: 'active',
                  description:
                    'An active session.  Could be connected or matching (transferring, requeueing, waiting).',
                  // We must figure out where we should be in the session.
                  initial: 'decide_initial',
                  // At any point in time, while active ...
                  on: {
                    'session.updateSession': [
                      {
                        target: '#unknown.different',
                        guard: 'isSessionDifferent',
                      },
                      {
                        target: '#finished',
                        guard: 'isSessionFinished',
                        actions: 'updateSession',
                      },
                      {
                        target: '#finished',
                        guard: 'isSessionCanceled',
                        actions: 'updateSession',
                      },
                      {
                        target: '#unavailable',
                        guard: 'isSessionUnavailable',
                        actions: 'updateSession',
                      },
                      {
                        guard: 'isSessionActive',
                        actions: 'updateSession',
                      },
                    ],
                    'session.updateLegs': {
                      actions: 'updateSessionLegs',
                    },
                    'session.notFound': {
                      target: 'unknown.expired',
                    },
                    'session.readyToConnect': {
                      actions: 'sessionIsReadyToConnect',
                    },
                    'session.sessionLegDropped': {
                      actions: 'dropSessionLeg',
                      target: 'finished',
                    },
                    // Note that this could be for any QUNAVAILABLE
                    // request.  Even one not for this session, which
                    // is unlikely due to mulitiple device constraints.
                    //
                    // No one from the backend team can explain why
                    // this is necessary, but iOS and AI Web 1.0 do it:
                    'session.qUnavailable': {
                      target: '#unavailable',
                      guard: 'isNonAddPartyQunavailable',
                    },
                  },
                  states: {
                    // The user has not yet accepted a found session.
                    // Likely due to a refresh, where the xhr request told us we have an oustanding session.
                    detached: {
                      initial: 'idle',
                      states: {
                        idle: {
                          on: {
                            // The user wants to join the session
                            'session.attach': {
                              target: 'accepting',
                            },
                            // The user wants to end the session
                            'session.reject': {
                              target: 'rejecting',
                            },
                          },
                        },
                        // Ending the session
                        rejecting: {
                          invoke: {
                            id: 'rejectSession',
                            src: fromPromise(() => backend.endSession()),
                            onDone: {
                              // Here, we are waiting for a socket update
                              // to transition us to the right place.
                            },
                            onError: [
                              // 404 means, we are no longer in session.
                              {
                                target: '#unknown.expired',
                                guard: ({ event }) => {
                                  if (
                                    event.type ===
                                    'error.platform.rejectSession'
                                  ) {
                                    const error = event.data;
                                    return hasHTTPStatus(error, 404);
                                  }

                                  return false;
                                },
                              },
                              // Show a toast message and let them try again.
                              {
                                target: 'idle',
                                actions: 'handleRejectSessionFailure',
                              },
                            ],
                          },
                        },
                        // Accepting the session
                        accepting: {
                          invoke: {
                            id: 'acceptSession',
                            src: fromPromise(() =>
                              // Notify the backend that we are attempting, which
                              // also happens to check if we are still in session
                              backend.notifyResumeAttempted(contextId)
                            ),
                            onDone: {
                              target: '#attached',
                              actions: ['onAttach', 'setSessionResumed'],
                            },
                            onError: [
                              // 400 means, we are no longer in session.
                              {
                                target: '#unknown.expired',
                                guard: ({ event }) => {
                                  if (
                                    event.type ===
                                    'error.platform.acceptSession'
                                  ) {
                                    const error = event.data;

                                    return hasHTTPStatus(error, 400);
                                  }
                                  return false;
                                },
                              },
                              // Show a toast message and let them try again.
                              {
                                target: 'idle',
                                actions: 'handleAcceptSessionFailure',
                              },
                            ],
                          },
                        },
                      },
                    },
                    // The user has decided to be in session.
                    // Either by accepting a detached session or explicitly
                    // through an enqueue.
                    attached: {
                      id: 'attached',
                      // We need to figure out where we are in the session.
                      initial: 'deciding',
                      entry: [
                        // start the presence pings to keep us alive
                        'startPresencePings',
                        // start the cancel machine to handle the user leaving
                        'startCancelMachine',
                        // fetch any missed chat message
                        'startChatMachine',
                      ],
                      exit: [
                        'stopPresencePings',
                        'stopCancelMachine',
                        'stopChatMachine',
                      ],
                      on: {
                        // Update our session when active
                        'session.updateSession': [
                          {
                            guard: 'isSessionActive',
                            actions: 'updateSession',
                            target: '.deciding',
                          },
                        ],
                        // Update our chat message
                        'session.updateChat': {
                          actions: 'updateChat',
                        },
                        // Handle an interpreter connection loss
                        'session.connectionLoss': {
                          actions: 'handleConnectionLoss',
                        },
                        // Handle the oidc resume attempted message
                        'session.oidcResumeAttempted': {
                          target: '#oidcResumeAttempted',
                          actions: 'setSessionOidcResumeAttempted',
                          guard: 'isOidcForDifferentDevice',
                        },
                        // Clear the chat
                        'session.clearChat': {
                          actions: 'clearChat',
                        },
                        // The socket came back online, so we need to ...
                        'socket.online': {
                          actions: [
                            // runSyncSession appears higher up when
                            // `syncSession.search` is handled at the
                            // root.  We must explicitly run it here,
                            // since these actions takes override.
                            // Determine if we still have a session
                            'runSyncSession',
                            // Restart the chat machine to check for missed
                            // chats
                            'stopChatMachine',
                            'startChatMachine',
                          ],
                        },
                      },
                      states: {
                        // figure out where we should be
                        deciding: {
                          always: [
                            {
                              // We should be connected to someone
                              target: 'party',
                              guard: 'isContextSessionParty',
                            },
                            {
                              // We should be playing the IVVR
                              target: 'ivvr',
                              guard: 'isContextSessionIVVR',
                            },
                            {
                              // We are being requeued and shouldn't be connected
                              target: 'requeueing',
                              guard: 'isContextSessionRequeueing',
                            },
                            {
                              // We are being transferred and shouldn't be connected
                              target: 'transferring',
                              guard: 'isContextSessionTransferring',
                            },
                          ],
                        },
                        // The session has the transfer status flag
                        transferring: {
                          on: {
                            'session.leaveSession': {
                              actions: 'cancelSession',
                            },
                          },
                        },
                        // The session has the requeue status flag
                        requeueing: {
                          on: {
                            'session.leaveSession': {
                              actions: 'cancelSession',
                            },
                          },
                        },
                        // The user is ready to be connected with someone
                        party: {
                          on: {
                            'session.leaveSession': {
                              actions: 'endSession',
                            },
                          },
                        },
                        // The session is waiting on the interpreter to answer
                        // and should be playing the ivvr.
                        ivvr: {
                          on: {
                            'session.leaveSession': {
                              actions: 'cancelSession',
                            },
                          },
                        },
                      },
                    },
                    // Decide whether or active session should be attached or detached
                    decide_initial: {
                      always: [
                        {
                          target: 'attached',
                          guard: 'isWithinEnqueuedTimestamp',
                        },
                        {
                          target: 'detached',
                        },
                      ],
                    },
                  },
                },
                // The session is in the finished status
                finished: {
                  id: 'finished',
                  on: {
                    'session.reload': {
                      actions: 'reload',
                    },
                  },
                },
                // We have an unexpected session
                unknown: {
                  id: 'unknown',
                  on: {
                    'session.reload': {
                      actions: 'reload',
                    },
                  },
                  initial: 'expired',
                  states: {
                    // Expired
                    expired: {},
                    // Somehow got two different sessions
                    different: {},
                  },
                },
              },
            },
          },
        },
      },
      types: {
        context: {} as CallSessionContext,
        events: {} as CallStateEvents,
      },
      context: ({ spawn }) => ({
        session: undefined,
        cancelMachineRef: undefined,
        chatMachineRef: undefined,
        enqueueRequest: undefined,
        enqueueError: undefined,
        enqueueSuspension: undefined,
        presenceRef: undefined,
        socketMessageRef: spawn(socketMessageMachine, {
          id: 'socketMessage',
          input: {},
        }),
        socketStatusRef: spawn(socketStatusMachine, {
          id: 'socketObservableStatus',
        }),
        checkForSessionRef: spawn(checkForSessionMachine, {
          id: 'checkForSessionStatus',
        }),
      }),
    },
    {
      actions: {
        runSyncSession: sendTo(({ context }) => context.checkForSessionRef, {
          type: 'syncSession.search',
        }),
        stopSyncSession: sendTo(({ context }) => context.checkForSessionRef, {
          type: 'syncSession.stop',
        }),
        cancelSession: pure(({ context }) => {
          if (context.cancelMachineRef) {
            return sendTo(context.cancelMachineRef, {
              type: 'endSession',
              reason: 'cancel',
            });
          }
          return [];
        }),
        endSession: pure(({ context }) => {
          if (context.cancelMachineRef) {
            return sendTo(context.cancelMachineRef, {
              type: 'endSession',
              reason: 'end',
            });
          }
          return [];
        }),
        newSession: assign(({ event, context }) => {
          if (event.type === 'session.updateSession') {
            return {
              session: {
                sessionDetails: event.session,
                legDetails: event.legs,
                readyToConnect: event.source === 'xhr',
                chat: null,
                isResumed: false,
                oidcResumeAttempted: null,
              },
            };
          }

          return context;
        }),
        updateSession: assign(({ event, context }) => {
          if (event.type === 'session.updateSession') {
            const session = context.session;
            const newSession = event.session;

            if (!session || session.sessionDetails.id !== newSession.id)
              return context;

            return {
              session: {
                ...session,
                // we may have missed the ready to connect message (SESSIONLEGJOINED / CONFERENCELEGJOINED)
                readyToConnect:
                  !session.readyToConnect || event.source === 'xhr',
                sessionDetails: newSession,
                // if we get the legs here, it's coming from all the detached legs
                legDetails: event.legs || session.legDetails,
              },
            };
          }

          return context;
        }),
        // the user mamnually resumed
        setSessionResumed: assign(({ context }) => {
          const session = context.session;
          if (!session) {
            return context;
          }

          return {
            session: {
              ...session,
              isResumed: true,
            },
          };
        }),
        setSessionOidcResumeAttempted: assign(({ event, context }) => {
          const session = context.session;

          if (!session || event.type !== 'session.oidcResumeAttempted') {
            return context;
          }

          return {
            session: {
              ...session,
              oidcResumeAttempted: event.message,
            },
          };
        }),
        updateSessionLegs: assign(({ event, context }) => {
          if (event.type === 'session.updateLegs') {
            const session = context.session;

            if (session && session.sessionDetails.id === event.session_id) {
              const legs = mergeLegs(event.legs, context.session?.legDetails);

              return {
                session: { ...session, legDetails: legs },
              };
            }
          }

          return context;
        }),
        dropSessionLeg: assign(({ event, context }) => {
          if (event.type === 'session.sessionLegDropped') {
            const session = context.session;

            if (session && session.sessionDetails.id === event.session_id) {
              return {
                session: {
                  ...session,
                  legDetails: updateLeg(event.leg, session.legDetails),
                },
              };
            }
          }

          return context;
        }),
        sessionIsReadyToConnect: assign(({ event, context }) => {
          if (event.type === 'session.readyToConnect') {
            const session = context.session;

            if (session && session.sessionDetails.id === event.session_id) {
              return {
                session: { ...session, readyToConnect: true },
              };
            }
          }

          return context;
        }),
        updateChat: assign(({ event, context }) => {
          if (event.type === 'session.updateChat' && context.session) {
            if (event.sessionId !== context.session.sessionDetails.id) {
              return context;
            }

            return {
              session: {
                ...context.session,
                chat: {
                  message: event.message,
                  timestamp: event.timestamp,
                },
              },
            };
          }

          return context;
        }),
        clearChat: assign(({ event, context }) => {
          if (event.type === 'session.clearChat' && context.session) {
            return {
              session: {
                ...context.session,
                chat: null,
              },
            };
          }

          return context;
        }),
        reload: () => window.location.reload(),
        setEnqueueRequest: assign(({ event }) => {
          if (event.type === 'enqueue.enqueue') {
            return {
              enqueueRequest: event.request,
            };
          }

          return { enqueueRequest: undefined };
        }),
        clearEnqueueRequest: assign(() => ({
          enqueueRequest: undefined,
          enqueueError: undefined,
        })),
        setEnqueueError: assign(({ event, context }) => {
          if (event.type === 'error.platform.enqueueSession') {
            return { enqueueError: svenErrorMessage(event.data) };
          }

          return context;
        }),
        setEnqueueSuspension: assign(({ event, context }) => {
          if (event.type === 'error.platform.enqueueSession') {
            if (event.data instanceof ParsedError) {
              return {
                enqueueSuspension: event.data.json as EnqueueSuspension,
              };
            }
          }

          return context;
        }),
        startPresencePings: assign(({ spawn }) => ({
          presenceRef: spawn(pingMachine),
        })),
        stopPresencePings: stop(({ context }) => {
          return context.presenceRef!;
        }),
        startCancelMachine: assign(({ spawn }) => {
          return { cancelMachineRef: spawn('cancelSessionMachine') };
        }),
        stopCancelMachine: stop(({ context }) => {
          return context.cancelMachineRef!;
        }),
        startChatMachine: assign(({ spawn, context }) => {
          const sessionId = context.session?.sessionDetails.id;

          if (sessionId === undefined) {
            return { chatMachineRef: undefined };
          }

          return {
            chatMachineRef: spawn('chatSessionMachine', {
              input: { sessionId: sessionId },
            }),
          };
        }),
        stopChatMachine: stop(({ context }) => {
          return context.chatMachineRef!;
        }),
      },
      guards: {
        isEnqueueSuspended: ({ event }) => {
          if (event.type === 'error.platform.enqueueSession') {
            if (event.data instanceof HTTPError) {
              return event.data.response.status === 402;
            }
          }

          return false;
        },
        isSessionActive: ({ event }) => {
          if (event.type === 'session.updateSession') {
            const activeFlags: StatusFlagEnum[] = [
              StatusFlag.WAITING,
              StatusFlag.WAITING_IVVR_ANSWERED,
              StatusFlag.CONNECTED_PENDING_IVVR,
              StatusFlag.CONNECTED,
              StatusFlag.REQUEUED,
              StatusFlag.TRANSFERRING,
              StatusFlag.CONNECTED_PENDING_INTERPRETER_ANSWERS,
              //StatusFlag.TO_OPI, // not used, so don't route to active
              //StatusFlag.TO_CONCIERGE, // not used, so don't route to active
            ];
            return activeFlags.includes(event.session.status_flag);
          }

          return false;
        },
        isSessionCanceled: ({ event }) => {
          if (event.type === 'session.updateSession') {
            const activeFlags: StatusFlagEnum[] = [StatusFlag.CANCELED];
            return activeFlags.includes(event.session.status_flag);
          }

          return false;
        },
        isSessionFinished: ({ event }) => {
          if (event.type === 'session.updateSession') {
            const activeFlags: StatusFlagEnum[] = [
              StatusFlag.FINALIZED,
              StatusFlag.DISCONNECTED,
            ];
            return activeFlags.includes(event.session.status_flag);
          }

          return false;
        },
        isSessionUnavailable: ({ event }) => {
          if (event.type === 'session.updateSession') {
            const activeFlags: StatusFlagEnum[] = [StatusFlag.UNAVAILABLE];
            return activeFlags.includes(event.session.status_flag);
          }

          return false;
        },
        isSessionDifferent: ({ event, context }) => {
          if (event.type === 'session.updateSession') {
            return event.session.id !== context.session?.sessionDetails.id;
          }

          return false;
        },
        isNonAddPartyQunavailable: ({ event }) => {
          if (event.type === 'session.qUnavailable') {
            return (
              event.message.queue_request.request_type !==
              QueueRequestType.AddParty
            );
          }

          return false;
        },
        isContextSessionParty: ({ context }) => {
          const session = context.session;

          if (!session) return false;

          const activeFlags: StatusFlagEnum[] = [
            StatusFlag.WAITING_IVVR_ANSWERED,
            StatusFlag.CONNECTED_PENDING_INTERPRETER_ANSWERS,
            StatusFlag.CONNECTED,
          ];

          return activeFlags.includes(session.sessionDetails.status_flag);
        },
        isContextSessionIVVR: ({ context }) => {
          const session = context.session;

          if (!session) return false;

          const activeFlags: StatusFlagEnum[] = [
            StatusFlag.WAITING,
            StatusFlag.CONNECTED_PENDING_IVVR,
          ];

          return activeFlags.includes(session.sessionDetails.status_flag);
        },
        isContextSessionRequeueing: ({ context }) => {
          const session = context.session;

          if (!session) return false;

          const activeFlags: StatusFlagEnum[] = [StatusFlag.REQUEUED];

          return activeFlags.includes(session.sessionDetails.status_flag);
        },
        isContextSessionTransferring: ({ context }) => {
          const session = context.session;

          if (!session) return false;

          const activeFlags: StatusFlagEnum[] = [StatusFlag.TRANSFERRING];

          return activeFlags.includes(session.sessionDetails.status_flag);
        },
        isWithinEnqueuedTimestamp: ({ context }) => {
          const now = new Date();

          if (!context.enqueueRequest?.requestedTimestamp) {
            return false;
          }

          return (
            differenceInSeconds(
              now,
              context.enqueueRequest.requestedTimestamp
            ) < 120
          );
        },
        isOidcForDifferentDevice: ({ event }) => {
          // Does the JTI (or web_resume_uuid) that was sent to
          // /api/me/session/current/resume/attempts differ from what
          // this browser session would have sent (via contextId)
          if (event.type === 'session.oidcResumeAttempted') {
            return event.message.jti !== contextId;
          }

          return false;
        },
      },
    }
  );
};

type EnqueueRequest =
  | {
      type: 'video';
      buttonId: number;
      preferredVideoResolution?: number;
      requestedTimestamp: Date;
    }
  | { type: 'audio'; requestedTimestamp: Date }
  | { type: 'support'; requestedTimestamp: Date };

type EnqueueSuspension = {
  message: string | null;
  ivvr: IVVR | null;
};

export type CallSessionContext = {
  // the session
  session: Session | undefined;
  // the request to enqueue the session
  enqueueRequest: EnqueueRequest | undefined;
  // enqueue error
  enqueueError: string | undefined;
  // presence pinger actor ref
  enqueueSuspension: EnqueueSuspension | undefined;
  // presence pinger actor ref
  presenceRef: ActorRefFrom<typeof pingMachine> | undefined;
  // check for session actor ref
  checkForSessionRef: ActorRefFrom<typeof checkForSessionMachine>;
  // listen for socket messages
  socketMessageRef: ActorRefFrom<typeof socketMessageMachine>;
  // listen for socket online/offfline events
  socketStatusRef: ActorRefFrom<typeof socketStatusMachine>;
  // cancel session pinger actor ref
  cancelMachineRef: ActorRefFrom<typeof cancelSessionMachine> | undefined;
  // session chat actor ref
  chatMachineRef: ActorRefFrom<typeof chatSessionMachine> | undefined;
};
