/**
 * Copyright © 2021, AMN Healthcare, Inc. All rights reserved.
 */

import React from 'react';
import { CallStateContext } from '../ProtectedPage';
import { SessionRequestButtons } from './SessionRequestButtons';
import { SessionUnknown } from './SessionUnknown';
import { SessionFinished } from './SessionFinished';
import { SessionUnavailable } from './SessionUnavailable';
import { SessionOidcResumeAttempted } from './SessionOidcResumeAttempted';
import { SessionInitializing } from './SessionInitializing';
import { SessionEnqueued } from './SessionEnqueued';
import { SessionEnqueueFailed } from './SessionEnqueueFailed';
import { SessionEnqueueSuspended } from './SessionEnqueueSuspended';
import { SessionDetached } from './SessionDetached';
import { SessionAttachedRequeueing } from './SessionAttachedRequeueing';
import { SessionAttachedTransferring } from './SessionAttachedTransferring';
import { SessionAttachedParty } from './SessionAttachedParty';
import { SessionAttachedIVVR } from './SessionAttachedIVVR';
import { SessionProvider, useSession } from './SessionContext';
import { type CallState } from '../ProtectedPage';
import { AlertMessage } from '@/ui/alert-message';
import { SessionLayout } from './SessionLayout';
import { Button } from '@/ui/button';
import { getPlatformType } from '@/protected/Interop/getPlatformType';
import { InteropPlatformProvider } from '@/protected/Interop/InteropPlatformProvider';
import { SessionErrorDialogProvider } from './SessionErrorDialogContext';
import { AriaAssertiveProvider } from '@/protected/shared/AriaAssertiveContext';
import { SessionErrorDialog } from './SessionErrorDialog';
import { SessionVolumeProvider } from '@/protected/Session/SessionVolumeContext';
import { ErrorBoundary } from 'react-error-boundary';
import { logBoundaryError } from '@/utils/logBoundaryError';
import { useInteropPlatform } from '@/protected/Interop/InteropPlatformProvider';
import { MediaPreferenceProvider } from '@/protected/Session/MediaPreferenceContext';
import { SessionType } from '@/protected/apis/protectedApiSchemas';
import { toast } from 'sonner';
import { debugLog } from '@/utils/debugLog';

export class SessionRouterMatchError extends Error {
  stateValue: string;

  constructor(message: string, stateValue: string) {
    super(message);
    this.name = 'SessionRouterMatchError';
    this.stateValue = stateValue;
  }
}

type StateRouterProps = {
  state: CallState;
};

const MemoedSessionAttachedParty = React.memo(SessionAttachedParty);
const MemoedSessionAttachedIVVR = React.memo(SessionAttachedIVVR);
const MemoedSessionAttachedRequeueing = React.memo(SessionAttachedRequeueing);
const MemoedSessionAttachedTransferring = React.memo(
  SessionAttachedTransferring
);

export function SessionAttachedRouter({ state }: StateRouterProps) {
  // // FIXME veryify when xstate gets typegen
  if (state.matches({ Session: { some: { active: { attached: 'party' } } } })) {
    return <MemoedSessionAttachedParty />;
  }

  if (state.matches({ Session: { some: { active: { attached: 'ivvr' } } } })) {
    // FIXME when better xstate typescript support
    const session = state.context.session;
    if (!session) throw new Error('Should have had session here');

    return <MemoedSessionAttachedIVVR session={session} />;
  }

  if (
    state.matches({ Session: { some: { active: { attached: 'requeueing' } } } })
  ) {
    return <MemoedSessionAttachedRequeueing />;
  }

  if (
    state.matches({
      Session: { some: { active: { attached: 'transferring' } } },
    })
  ) {
    return <MemoedSessionAttachedTransferring />;
  }

  throw new SessionRouterMatchError(
    'Failed to match SessionAttached',
    JSON.stringify(state.value)
  );
}

function LoadingInterop() {
  return <div className="loading-ellipsis">Loading provider components.</div>;
}

function WrapInterops({ children }: React.PropsWithChildren) {
  const { components: Interop } = useInteropPlatform();

  return (
    <Interop.Provider>
      <Interop.ConnectionManager />

      {children}
    </Interop.Provider>
  );
}

function RolloverNotifier() {
  const session = useSession();

  const isRollover =
    (session.sessionDetails.session_type === SessionType.CSR ||
      session.sessionDetails.session_type === SessionType.AUDIOOUT) &&
    session.sessionDetails.initial_session_type === SessionType.INTERPRETING;

  const showWarning = !session.isResumed && isRollover;

  React.useEffect(() => {
    if (showWarning) {
      toast('Rollover Session', {
        description:
          'No video interpreters are currently available for this language.  We are redirecting your call to an audio-only interpreter.',
      });
    }
  }, [showWarning]);

  return null;
}

function WrappedSessionAttachedRouter({ state }: StateRouterProps) {
  const session = useSession();
  const platform = getPlatformType(session.sessionDetails.session_type);

  return (
    <SessionErrorDialogProvider>
      <SessionVolumeProvider initialSessionVolume={100}>
        <InteropPlatformProvider
          platform={platform}
          fallback={<LoadingInterop />}
        >
          <MediaPreferenceProvider>
            <WrapInterops>
              <SessionAttachedRouter state={state} />
              <RolloverNotifier />
            </WrapInterops>
          </MediaPreferenceProvider>
        </InteropPlatformProvider>
      </SessionVolumeProvider>
      <SessionErrorDialog />
    </SessionErrorDialogProvider>
  );
}

export function SessionEnqueueRouter({ state }: StateRouterProps) {
  if (state.matches({ Session: { none: { Enqueue: 'idle' } } })) {
    return <SessionRequestButtons />;
  }

  if (
    state.matches({ Session: { none: { Enqueue: { enqueing: 'invoking' } } } })
  ) {
    return <SessionRequestButtons disabled={true} />;
  }

  if (state.matches({ Session: { none: { Enqueue: 'enqueing' } } })) {
    return <SessionEnqueued />;
  }

  if (state.matches({ Session: { none: { Enqueue: 'suspended' } } })) {
    return <SessionEnqueueSuspended />;
  }

  if (state.matches({ Session: { none: { Enqueue: 'failed' } } })) {
    return <SessionEnqueueFailed />;
  }

  throw new SessionRouterMatchError(
    'Failed to match SessionEnqueue',
    JSON.stringify(state.value)
  );
}

export function SessionNoneRouter({ state }: StateRouterProps) {
  if (state.matches({ Session: { none: 'Enqueue' } })) {
    return <SessionEnqueueRouter state={state} />;
  }

  throw new SessionRouterMatchError(
    'Failed to match SessionNone',
    JSON.stringify(state.value)
  );
}

export function SessionSomeRouter({ state }: StateRouterProps) {
  if (state.matches({ Session: { some: { active: 'attached' } } })) {
    return <WrappedSessionAttachedRouter state={state} />;
  }

  if (state.matches({ Session: { some: { active: 'detached' } } })) {
    return <SessionDetached />;
  }

  if (state.matches({ Session: { some: 'unknown' } })) {
    return <SessionUnknown />;
  }

  if (state.matches({ Session: { some: 'finished' } })) {
    return <SessionFinished />;
  }

  if (state.matches({ Session: { some: 'unavailable' } })) {
    return <SessionUnavailable />;
  }

  if (state.matches({ Session: { some: 'oidcResumeAttempted' } })) {
    return <SessionOidcResumeAttempted />;
  }

  throw new SessionRouterMatchError(
    'Failed to match SessionSomeRouter',
    JSON.stringify(state.value)
  );
}

function SessionRouterImpl() {
  return CallStateContext.useSelector((state) => {
    debugLog('SessionRouter state.value', state.value);
    debugLog('SessionRouter state.context', state.context);

    if (state.matches({ Session: 'some' })) {
      if (state.context.session === undefined) {
        // Let error boundaries save us
        throw new Error('Expected a session in SessionRouter.');
      }

      return (
        <SessionProvider session={state.context.session}>
          <SessionSomeRouter state={state} />
        </SessionProvider>
      );
    }

    if (state.matches({ Session: 'none' })) {
      return <SessionNoneRouter state={state} />;
    }

    if (state.matches({ Session: 'checking' })) {
      return <SessionInitializing />;
    }

    throw new SessionRouterMatchError(
      'Failed to match SessionRouter',
      JSON.stringify(state.value)
    );
  });
}

function SessionRouterFallbackError() {
  const reload = () => window.location.reload();

  return (
    <SessionLayout>
      <div className="space-y-4">
        <AlertMessage title="Unexpected error">
          An unexpected error has occurred.
        </AlertMessage>

        <Button onPress={reload} type="button">
          Reload the app
        </Button>
      </div>
    </SessionLayout>
  );
}

export function SessionRouter() {
  return (
    <ErrorBoundary
      FallbackComponent={SessionRouterFallbackError}
      onError={logBoundaryError}
    >
      <AriaAssertiveProvider>
        <SessionRouterImpl />
      </AriaAssertiveProvider>
    </ErrorBoundary>
  );
}
