/**
 * Copyright © 2021, AMN Healthcare, Inc. All rights reserved.
 */

// Taken from https://stackoverflow.com/a/75594044
//
// We are only formatting US phone numbers for AI Web; therefore,
// we can use something simple.
//
// Alternatively, we'd use something like libphonenumber-js
export function simpleFormatPhoneNumber(phone: string) {
  return phone
    .replace(/\D/g, '')
    .replace(
      /(\d*)(\d{3})(\d{3})(\d{4})$/,
      (_, a, b, c, d) => `+${a} (${b}) ${c}-${d}`
    )
    .replace(/\+(1\b|\s)\s*/, '');
}
