/**
 * Copyright © 2021, AMN Healthcare, Inc. All rights reserved.
 */

import React from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useSubscription } from 'observable-hooks';
import { sessionWebsocketConnection$ } from '@/protected/apis/protectedApiWebsockets';
import { useLegs, useSession } from './SessionContext';
import { type SessionWebsocketMessages } from '@/protected/apis/protectedApiWebsockets';
import { toast } from 'sonner';

type SessionVolumeState = {
  volume: number;
};

type SessionVolumeAction =
  | { type: 'up' }
  | { type: 'down' }
  | { type: 'mute' }
  | { type: 'unmute' }
  | { type: 'toggle' }
  | { type: 'set'; volume: number };

const nearest10 = (x: number) => Math.round(x / 10) * 10;

type SessionVolumeContextValue = [
  SessionVolumeState,
  React.Dispatch<SessionVolumeAction>,
];

let SessionVolumeContext = React.createContext<
  SessionVolumeContextValue | undefined
>(undefined);

// Helper to figure out what platform is in use
function useSessionVolume() {
  const context = React.useContext(SessionVolumeContext);
  if (context === undefined) {
    throw new Error(
      'useSessionVolume must be used within a SessionVolumeProvider'
    );
  }
  return context;
}

type SessionVolumeProviderProps = {
  children: React.ReactNode;
  initialSessionVolume: number;
};

function volumeReducer(
  state: SessionVolumeState,
  action: SessionVolumeAction
): SessionVolumeState {
  switch (action.type) {
    case 'up':
      return {
        ...state,
        volume: Math.min(nearest10(state.volume + 10), 100),
      };
    case 'down':
      return {
        ...state,
        volume: Math.max(nearest10(state.volume - 10), 0),
      };
    case 'mute':
      return {
        ...state,
        volume: 0,
      };
    case 'unmute':
      return {
        ...state,
        // we could change behavior and put the reducer in the render
        //volume: Math.max(initialSessionVolume, 25),
        volume: 100,
      };
    case 'toggle':
      return state.volume === 0
        ? volumeReducer(state, { type: 'unmute' })
        : volumeReducer(state, { type: 'mute' });
    case 'set':
      return {
        ...state,
        volume: action.volume,
      };
  }
}

function SessionVolumeProvider({
  children,
  initialSessionVolume,
}: SessionVolumeProviderProps) {
  const session = useSession();
  const legs = useLegs();

  const me = legs.me;
  const sessionId = session.sessionDetails.id;

  const [state, dispatch] = React.useReducer(volumeReducer, {
    volume: initialSessionVolume,
  });

  const volumeToggle = React.useCallback(
    (e: KeyboardEvent) => {
      if (e.repeat) return;
      dispatch({ type: 'toggle' });
    },
    [dispatch]
  );

  const volumeUp = React.useCallback(
    (e: KeyboardEvent) => {
      if (e.repeat) return;
      dispatch({ type: 'up' });
    },
    [dispatch]
  );

  const volumeDown = React.useCallback(
    (e: KeyboardEvent) => {
      if (e.repeat) return;
      dispatch({ type: 'down' });
    },
    [dispatch]
  );

  const notify = React.useCallback((reason: string) => {
    toast('Volume remotely adjusted', {
      description: `Volume changed: ${reason}`,
      duration: 1_000,
    });
  }, []);

  // NOTE: we popup a toast whenever the volume changes.
  // In the original AI Web, it was a video overlay message.

  // Respond to remote volume adjustments from interpreter
  // (over the websocket).
  const adjustVolume = React.useCallback(
    (e: SessionWebsocketMessages) => {
      if (e.action_type === 'SESSIONLEGVOLUMEADJUSTMENT') {
        if (sessionId !== e.data.session_id && me !== e.data.session_leg_id) {
          return;
        }

        switch (e.data.adjustment) {
          case 'DOWN':
            dispatch({ type: 'down' });
            notify('Down');
            break;
          case 'UP':
            dispatch({ type: 'up' });
            notify('Up');
            break;
          case 'MAX':
            dispatch({ type: 'unmute' });
            notify('Max');
            break;
          case 'MIN':
            dispatch({ type: 'mute' });
            notify('Min');
            break;
          case 'DEFAULT':
            dispatch({ type: 'unmute' });
            notify('Default');
            break;
        }
      }
    },
    [me, sessionId, notify]
  );

  useSubscription(sessionWebsocketConnection$, adjustVolume);

  useHotkeys('alt+/', volumeUp, [volumeUp]);
  useHotkeys('alt+.', volumeDown, [volumeDown]);
  useHotkeys('alt+q', volumeToggle, [volumeToggle]);

  const value = React.useMemo((): SessionVolumeContextValue => {
    return [state, dispatch];
  }, [state, dispatch]);

  return (
    <SessionVolumeContext.Provider value={value}>
      {children}
    </SessionVolumeContext.Provider>
  );
}

export { useSessionVolume, SessionVolumeProvider };
export type { SessionVolumeProviderProps };
