/**
 * Copyright © 2021, AMN Healthcare, Inc. All rights reserved.
 */

import {
  Tab as AriaTab,
  TabList as AriaTabList,
  TabListProps as AriaTabListProps,
  TabPanel as AriaTabPanel,
  TabPanelProps as AriaTabPanelProps,
  TabProps as AriaTabProps,
  TabsProps as AriaTabsProps,
  Tabs as AriaTabsRoot,
} from 'react-aria-components';

import { cn } from '@/utils/cn';

interface TabsProps extends Omit<AriaTabsProps, 'className' | 'orientation'> {
  className?: string;
}

const TabsRoot = ({ children, className, ...props }: TabsProps) => (
  <AriaTabsRoot
    {...props}
    orientation="vertical"
    className={cn('flex w-full flex-row space-x-2', className)}
  >
    {children}
  </AriaTabsRoot>
);

TabsRoot.displayName = TabsRoot;

interface TabListProps<T> extends Omit<AriaTabListProps<T>, 'className'> {
  className?: string;
}

const TabList = <T extends object>({
  children,
  className,
  ...props
}: TabListProps<T>) => (
  <AriaTabList
    {...props}
    className={cn('relative w-48 flex-none space-y-1 p-1 text-sm', className)}
  >
    {children}
  </AriaTabList>
);

TabList.displayName = TabList;

interface TabProps extends Omit<AriaTabProps, 'className'> {
  className?: string;
}

const Tab = ({ children, className, ...props }: TabProps) => (
  <AriaTab
    {...props}
    className={cn(
      'ring-focus relative cursor-pointer rounded-md bg-gray-50 p-2 outline-none ring-offset-2 ring-offset-white aria-selected:cursor-default aria-selected:bg-teal-600 aria-selected:text-white data-[hovered]:bg-teal-600/30 data-[focus-visible]:ring-2 aria-selected:data-[hovered]:bg-teal-600',
      className
    )}
  >
    {children}
  </AriaTab>
);

Tab.displayName = Tab;

interface TabPanelProps extends Omit<AriaTabPanelProps, 'className'> {
  className?: string;
}

const TabPanel = ({ children, className, ...props }: TabPanelProps) => (
  <AriaTabPanel
    {...props}
    className={cn(
      'ring-focus flex-auto p-1 outline-none data-[focus-visible]:ring-2',
      className
    )}
  >
    {children}
  </AriaTabPanel>
);

TabPanel.displayName = TabPanel;

export { Tab, TabList, TabPanel, TabsRoot };
