/**
 * Copyright © 2021, AMN Healthcare, Inc. All rights reserved.
 */

import { CallStateContext } from '../ProtectedPage';
import { Button } from '@/ui/button';
import { useSession } from '@/protected/Session/SessionContext';
import { SessionSidebarLayout } from './SessionSidebarLayout';

export function SessionOidcResumeAttempted() {
  const { send } = CallStateContext.useActorRef();

  const session = useSession();
  const message = session.oidcResumeAttempted?.message;

  return (
    <SessionSidebarLayout
      title={message || 'Switched to another device'}
      sidebar={
        <Button onPress={() => send({ type: 'session.reload' })} type="button">
          Continue to languages
        </Button>
      }
    >
      <p>
        You've been switched to another device. Please resume the call on that
        device.
      </p>
    </SessionSidebarLayout>
  );
}
