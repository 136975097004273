/**
 * Copyright © 2021, AMN Healthcare, Inc. All rights reserved.
 */

import React from 'react';
import { useAuthProfile } from '../AuthProfileContext';
import { BrandedImage } from '@/shared/BrandedImage';
import amnLanguageServices from '@/assets/amn-language-services.png';
import { cn } from '@/utils/cn';
import { useNavigate } from 'react-router-dom';

const imageClass = cn('block max-h-full max-w-full');

function MaybeSwitchAccountButton({ children }: React.PropsWithChildren) {
  const profile = useAuthProfile();
  const navigate = useNavigate();

  if (profile.login_enterprise?.must_choose_subaccount) {
    return (
      <button
        className="cursor-pointer border border-transparent p-1 hover:rounded-md hover:border-gray-600 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-1"
        onClick={() => navigate('/accounts/select')}
      >
        {children}
      </button>
    );
  }

  return children;
}

type ProtectedFooterProps = {
  supportSwitchAccount?: boolean;
};

export function ProtectedFooter({
  supportSwitchAccount = false,
}: ProtectedFooterProps) {
  const profile = useAuthProfile();

  const Wrapper = supportSwitchAccount
    ? MaybeSwitchAccountButton
    : React.Fragment;

  return (
    <footer className="relative isolate h-[80px] flex-none overflow-hidden border-t border-black/[0.05] bg-white">
      <div className="space-between container relative mx-auto flex h-full w-full items-center justify-between gap-2 text-sm">
        <div className="flex h-full w-full items-center">
          <span className="break-anywhere">
            {profile.user.first_name} {profile.user.last_name}
          </span>
        </div>
        <div className="flex h-full w-full items-center">
          <BrandedImage
            className={imageClass}
            src={amnLanguageServices}
            itemName="footer_image_left"
            itemSize="medium"
            alt="Left branding"
          />
        </div>
        <div className="flex h-full w-full items-center justify-center">
          <Wrapper>
            <BrandedImage
              className={imageClass}
              itemName="footer_image_center"
              itemSize="medium"
              alt="Center branding"
            >
              {profile.enterprise.name}
            </BrandedImage>
          </Wrapper>
        </div>
        <div className="hidden h-full w-full items-center justify-end lg:flex">
          {profile.enterprise.profile_picture ? (
            <BrandedImage
              className={imageClass}
              src={profile.enterprise.profile_picture}
              itemName="footer_image_right"
              itemSize="medium"
              alt="Right branding"
            />
          ) : (
            <BrandedImage
              className="block h-auto max-w-full"
              itemName="footer_image_right"
              itemSize="medium"
              alt="Right branding"
            >
              <React.Fragment />
            </BrandedImage>
          )}
        </div>
      </div>
    </footer>
  );
}
