/**
 * Copyright © 2021, AMN Healthcare, Inc. All rights reserved.
 */

import { BrandedAMNImage } from '@/shared/BrandedAMNImage';

export type CardProps = { header: React.ReactNode; children: React.ReactNode };
export function AuthCardLayout({ header, children }: CardProps) {
  return (
    <div className="h-full w-full bg-gray-100 pt-9">
      <div className="align-center mx-auto w-3/4 max-w-[600px] space-y-2 rounded-sm border border-gray-200 bg-white px-6 py-8 text-gray-900 shadow">
        <BrandedAMNImage />
        <h1 className="text-2xl font-medium">{header}</h1>
        {children}
      </div>
    </div>
  );
}
