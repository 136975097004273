/**
 * Copyright © 2021, AMN Healthcare, Inc. All rights reserved.
 */

// Adapted from https://webaudio.github.io/web-audio-api/#vu-meter-mode

export default class VUMeterNode extends AudioWorkletNode {
  constructor(context, updateIntervalInMS) {
    super(context, 'vumeter', {
      numberOfInputs: 1,
      numberOfOutputs: 1,
      channelCount: 1,
      processorOptions: {
        updateIntervalInMS: updateIntervalInMS || 16.67,
      },
    });
    // States in AudioWorkletNode
    this._updateIntervalInMS = updateIntervalInMS;
    this._volume = 0;
    this._peak = 0;

    // Handles updated values from AudioWorkletProcessor
    this.port.onmessage = event => {
      if (event.data && event.data.type === 'volume-update') {
        this._volume = event.data.volume;
        this._peak = event.data.peak;
      }
    };

    this.port.start();
  }

  get updateInterval() {
    return this._updateIntervalInMS;
  }

  get volume() {
    return this._volume;
  }

  get peak() {
    return this._peak;
  }

  set updateInterval(updateIntervalInMS) {
    this._updateIntervalInMS = updateIntervalInMS;
    this.port.postMessage({ updateIntervalInMS: updateIntervalInMS });
  }
}
