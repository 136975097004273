/**
 * Copyright © 2021, AMN Healthcare, Inc. All rights reserved.
 */

import React from 'react';
import VUMeterNode from '@/utils/audio/vumeter-node';
import VUMeterProcess from '@/utils/audio/vumeter-processor';
import colors from 'tailwindcss/colors';

// Hack to get the process worklet into a file that the
// can be passed to addModule.
//
// This could also be hosted in static/public, but it's
// harder to test with this setup.
function workletBlob(worklet: any) {
  let code = worklet.toString();
  code = code.substring(code.indexOf('{') + 1, code.lastIndexOf('}'));

  const blob = new Blob([code], { type: 'application/javascript' });
  return URL.createObjectURL(blob);
}

// Dimensions of Canvas
const WIDTH = 200;
const HEIGHT = 10;

type VolumeMeterProps = { stream: MediaStream; label?: string };
export default function VolumeMeter({
  stream,
  label = 'Volume meter',
}: VolumeMeterProps) {
  const [isReady, setIsReady] = React.useState(false);
  const canvasRef = React.useRef<HTMLCanvasElement>(null);
  const blob = workletBlob(VUMeterProcess);
  const id = React.useId();

  const updateMeter = React.useCallback((node?: VUMeterNode) => {
    if (node === undefined || canvasRef.current == null) {
      return;
    }

    var canvasContext = canvasRef.current.getContext('2d');
    if (canvasContext == null) {
      return;
    }

    const volume = node.volume;
    const peak = node.peak;

    var width = WIDTH;
    var height = HEIGHT;

    canvasContext.fillStyle = colors.gray[400];
    canvasContext.fillRect(0, 0, width, height);

    canvasContext.fillStyle = colors.green[400];
    canvasContext.fillRect(0, 0, peak * width, height);

    canvasContext.fillStyle = colors.green[300];
    canvasContext.fillRect(0, 0, volume * width, height);

    const whole = Math.round(peak * 100);

    canvasRef.current.setAttribute('aria-valuenow', '' + whole);
    canvasRef.current.setAttribute('aria-valuetext', whole + '%');

    var spaces = width / 10;
    var offset = 1;
    canvasContext.fillStyle = 'white';
    for (var i = 0; i < 10; i++) {
      canvasContext.fillRect(i * spaces, 0, offset, height);
    }
  }, []);

  React.useEffect(() => {
    let active = true;

    const context = new AudioContext();
    let vuMeterNode: VUMeterNode | undefined;
    let microphone: MediaStreamAudioSourceNode | undefined;

    context.audioWorklet
      .addModule(blob)
      .then(() => {
        if (active) {
          vuMeterNode = new VUMeterNode(context, 50);

          microphone = new MediaStreamAudioSourceNode(context, {
            mediaStream: stream,
          });

          microphone.connect(vuMeterNode);
          vuMeterNode.connect(context.destination);

          setIsReady(true);

          function drawMeter() {
            if (vuMeterNode) {
              updateMeter(vuMeterNode);
              requestAnimationFrame(drawMeter);
            }
          }
          drawMeter();
        }
      })
      .catch((err) => {
        console.error('Error setting up volume meter', err);
      });

    return () => {
      active = false;

      if (vuMeterNode) {
        vuMeterNode.disconnect();
        vuMeterNode = undefined;
      }

      if (microphone) {
        microphone.disconnect();
        microphone = undefined;
      }

      context.close();
    };
  }, [blob, stream, updateMeter]);

  return (
    <div className="space-y-2">
      {isReady ? (
        <p className="text-sm">
          Start talking to view your microphone input levels.
        </p>
      ) : (
        <p className="text-sm">Setting up meter ...</p>
      )}

      <label id={id} className="block font-medium">
        {label}
      </label>

      {/* don't know why it's not recognizing meter */}
      {/* eslint-disable jsx-a11y/aria-role */}
      <canvas
        role="meter progressbar"
        aria-busy={!isReady}
        aria-valuetext="0%"
        aria-valuenow={0}
        aria-valuemin={0}
        aria-valuemax={100}
        aria-labelledby={id}
        tabIndex={0}
        ref={canvasRef}
        width={WIDTH}
        height={HEIGHT}
      />
    </div>
  );
}
