/**
 * Copyright © 2021, AMN Healthcare, Inc. All rights reserved.
 */

import { CallStateContext } from '../ProtectedPage';
import { SessionLayout } from './SessionLayout';
import { Button } from '@/ui/button';
import { Spinner } from '@/ui/spinner';
import { useEnqueuedLanguage } from './useEnqueuedLanguage';

export function SessionEnqueued() {
  const { send } = CallStateContext.useActorRef();

  const canCancel = CallStateContext.useSelector((s) => {
    return s.can({ type: 'session.cancelSession' });
  });

  const cancel = () => send({ type: 'session.cancelSession' });

  const language = useEnqueuedLanguage();

  return (
    <SessionLayout>
      <div className="flex h-full w-full justify-between space-x-4">
        <div className="flex w-full justify-between">
          <div className="flex flex-auto flex-col items-center gap-2">
            <h3 className="text-lg font-extrabold">
              Your call has been queued and is pending a match.
            </h3>
            {language && <div>{language}</div>}
            <Spinner size={32} />
          </div>
          <div className="flex flex-none flex-col items-center gap-2">
            <Button isDisabled={!canCancel} onPress={cancel} type="button">
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </SessionLayout>
  );
}
