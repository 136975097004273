/**
 * Copyright © 2021, AMN Healthcare, Inc. All rights reserved.
 */

import { z } from 'zod';

// -------------------
// Languages
// -------------------

export const languageSchema = z.object({
  pk: z.number(),
  name: z.string(),
  native_name: z.string(),
  language_type: z.number(),
});

export const languagePairSchema = z.object({
  pk: z.number(),
  from_language: languageSchema,
  to_language: languageSchema,
});

export const scheduleSchema = z.object({
  start_date: z.number(),
  start_time: z.string(),
  end_date: z.number(),
  end_time: z.string(),
  is_24_hours: z.boolean(),
});

export type Schedule = z.infer<typeof scheduleSchema>;

export const holidayScheduleSchema = z.object({
  holiday_name: z.string(),
  window_start: z.string(),
  window_end: z.string(),
  availability_start: z.string().nullable(),
  availability_end: z.string().nullable(),
});

export type HolidaySchedule = z.infer<typeof holidayScheduleSchema>;

export const scheduleSetSchema = z.object({
  holiday_schedule_set: z
    .object({
      windows: z.array(holidayScheduleSchema),
    })
    .nullable(),
  id: z.number(),
  schedule_name: z.string(),
  schedules: z.array(scheduleSchema),
  time_zone: z.string(),
});

export const enterpriseLanguageSchema = z.object({
  pk: z.number(),
  language_pair: languagePairSchema,
  display_text: z.string(),
  display_subtext: z.string().nullable(),
  order: z.number().nullable(),
  schedule_set: scheduleSetSchema,
});

export const enterpriseLanguagesSchema = z.array(enterpriseLanguageSchema);

export const errorMessageSchema = z.object({
  message: z.string(),
});

export type EnterpriseLanguage = z.infer<typeof enterpriseLanguageSchema>;

// --------------------
// Enterprise
// --------------------

export const enterpriseSchema = z.object({
  name: z.string(),
  id: z.number(),
  enterprise_type: z.number(),
  can_call_international: z.boolean(),
  public_client_id: z.string().nullable(),
  city: z.string().nullable(),
  state: z.string().nullable(),
  question_prompt: z.number(),
  mentoring_participant: z.boolean(),
});

export type Enterprise = z.infer<typeof enterpriseSchema>;

// --------------------
// Session
// --------------------

export const StatusFlag = {
  WAITING: 0,
  WAITING_IVVR_ANSWERED: 1,
  CONNECTED_PENDING_IVVR: 2,
  CONNECTED: 3,
  DISCONNECTED: 4,
  FINALIZED: 5,
  UNAVAILABLE: 6,
  TO_OPI: 7,
  TO_CONCIERGE: 8,
  REQUEUED: 9,
  TRANSFERRING: 10,
  CANCELED: 12,
  CONNECTED_PENDING_INTERPRETER_ANSWERS: 13,
} as const;

export const SessionType = {
  INTERPRETING: 0,
  PTOPCALL: 1,
  TELEHEALTH: 2, // Retired
  SUPPORT: 3, // Support Audio
  LEGACY: 4, // SIP Video Interop
  AUDIOONLY: 5, // inbound PSTN
  AUDIOOUT: 6, // outbound
  CSR: 7, // outbound to CSR
  // RELAY: someone calls a phone number that they think belongs to a hospital
  // we put our interpreters in front to handle and redirect the call within the
  // hospital system
  RELAY: 8, // outbound to RELAY (patient line)
  SUPPORT_VIDEO: 9, // future video support
  MS_TEAMS: 10, // Microsoft Teams Interop
  TWILIO: 11, // Twilio Interop
  BLUESTREAM: 12, // Bluestream Interop
  VITALCHAT: 13, // VitalChat Interop
  EPIC: 14, // Epic Interop
  ZOOM: 15, // Zoom Interop
} as const;

const sessionTypeEnum = z.nativeEnum(SessionType);
export type SessionTypeEnum = z.infer<typeof sessionTypeEnum>;

const statusFlagEnum = z.nativeEnum(StatusFlag);
export type StatusFlagEnum = z.infer<typeof statusFlagEnum>;

export const currentIvvrSchema = z.object({
  pk: z.number(),
  name: z.string(),
  h264_file: z.string(),
  webm_file: z.string(),
  message: z.string().nullable(),
});

export type IVVR = z.infer<typeof currentIvvrSchema>;

export const sessionDetailsSchema = z.object({
  client: z.object({
    date_joined: z.string(),
    email: z.string(),
    first_name: z.string(),
    last_name: z.string(),
    username: z.string(),
  }),
  current_ivvr: currentIvvrSchema.nullable(),
  current_ivvr_watched: z.boolean(),
  enterprise: enterpriseSchema,
  from_language: languageSchema.nullable(),
  id: z.number(),
  provider: enterpriseSchema.nullable(),
  sip_domain: z.string(),
  status_flag: statusFlagEnum,
  session_type: sessionTypeEnum,
  initial_session_type: sessionTypeEnum,
  to_language: languageSchema.nullable(),
  updated_timestamp: z.string(),
  created_timestamp: z.string(),
  cancelled_timestamp: z.string().nullable(),
  suspended: z.boolean(),
  can_change_enterprise: z.boolean(),
  can_interpreter_change_enterprise: z.boolean(),
  has_enterprise_changed: z.boolean(),
  ivvr_form: z.object({
    has_questions: z.boolean(),
    answers_received: z.boolean(),
  }),
  requested_resolution: z
    .object({
      width: z.number(),
      height: z.number(),
    })
    .nullable(),
  show_session_code_prompt: z.boolean(),
});

export type SessionDetails = z.infer<typeof sessionDetailsSchema>;

// --------------------
// Queue Request
// --------------------

export enum QueueRequestStatus {
  Canceled = 0,
  Waiting = 1,
  Matched = 2,
  Fulfilled = 3,
  Final = 4,
}

export enum QueueRequestType {
  Initial = 0,
  AddParty = 1,
  QueueRequest = 2,
}

export const queueRequestDetailsSchema = z.object({
  fulfilled_leg: z.number().nullable(),
  id: z.number(),
  language_pair: z.number().nullable(),
  priority_flag: z.number(),
  queueable_type: z.number(),
  request_type: z.number(),
  requeued_status_flag: z.number().nullable(),
  // Note always verify the shape of the skills when used, as
  // this could different depending on the the API it's used in.
  // It's commented out here since it's not needed.
  // skills: z.array(z.number()),
  status: z.number(),
});

// --------------------
// Leg
// --------------------

export const legDetailSchema = z.object({
  id: z.number(),
  status_flag: statusFlagEnum,
  type: z.string(),
  user: z
    .object({
      username: z.string().nullable(),
      groups: z.array(
        z.object({
          name: z.string(),
        })
      ),
    })
    .nullable(),
  display_text: z.string().nullable(),
  display_subtext: z.string().nullable(),
  on_hold: z.boolean(),
  is_audio_muted: z.boolean(),
  is_video_muted: z.boolean(),
  end_timestamp: z.string().nullable(),
  start_timestamp: z.string(),
  call_address: z.string(),
  has_adjustable_volume: z.boolean().nullable(),
});

export const legDetailsSchema = z.array(legDetailSchema);

export type LegDetail = z.infer<typeof legDetailSchema>;
export type LegDetails = LegDetail[];

// --------------------
// Socket Messages
// --------------------

export const sessionUpdateMessageSchema = z.object({
  action_type: z.literal('SESSIONUPDATE'),
  timestamp: z.string(),
  session: sessionDetailsSchema,
});

export const qRequestCanceledMessageSchema = z.object({
  action_type: z.literal('QREQUESTCANCELLED'),
  request_id: z.number(),
  cancelled_by: z.string(),
  location: z.string(),
  timestamp: z.string(),
  environment: z.string(),
  receiving_user_id: z.number(),
});

export const sessionLegJoinedMessageSchema = z.object({
  action_type: z.literal('SESSIONLEGJOINED'),
  timestamp: z.string(),
  session_id: z.number(),
});

export const conferenceLegJoinedMessageSchema = z.object({
  action_type: z.literal('CONFERENCELEGJOINED'),
  timestamp: z.string(),
  session_id: z.number(),
});

export const transferFailMessageSchema = z.object({
  action_type: z.literal('TRANSFERFAIL'),
  timestamp: z.string(),
  user: z.string(),
  pk: z.number(),
});

export const directAddRequestSchema = z.object({
  id: z.number(),
  created_timestamp: z.string(),
  requested_by_display_text: z.string().nullable(),
  requested_by_display_subtext: z.string().nullable(),
  notes: z.string().nullable(),
  accepted: z.boolean().nullable(),
  session: sessionDetailsSchema,
});

export const directOfferMessageSchema = z.object({
  action_type: z.literal('DIRECTOFFER'),
  direct_add_request: directAddRequestSchema,
});

export const directOfferAcceptedMessageSchema = z.object({
  action_type: z.literal('DIRECTOFFERACCEPTED'),
  direct_add_request: directAddRequestSchema,
});

export const directOfferRejectedMessageSchema = z.object({
  action_type: z.literal('DIRECTOFFERREJECTED'),
  direct_add_request: directAddRequestSchema,
});

export const directOfferCancelledMessageSchema = z.object({
  action_type: z.literal('DIRECTOFFERCANCELLED'),
  direct_add_request: directAddRequestSchema,
});

export const chatDetailsSchema = z.object({
  display_in_video: z.boolean(),
  message: z.string(),
  sender: z.object({
    username: z.string(),
  }),
  sent_timestamp: z.string(),
});

export const fetchSessionChatSchema = z
  .object({
    session_id: z.number(),
  })
  .and(chatDetailsSchema);

export const sessionChatMessageSchema = z.object({
  action_type: z.literal('SESSIONCHAT'),
  chat: chatDetailsSchema,
  timestamp: z.string(),
  session_id: z.number(),
});

export const clearChatMessageSchema = z.object({
  action_type: z.literal('CLEARCHATMESSAGE'),
  timestamp: z.string(),
  session_id: z.number(),
});

export const qUnavailableMessageSchema = z.object({
  action_type: z.literal('QUNAVAILABLE'),
  timestamp: z.string(),
  request_id: z.number(),
  ivvr: z.any(),
  queue_request: queueRequestDetailsSchema,
});

export const captureSelfViewMessageSchema = z.object({
  action_type: z.literal('CAPTURESELFVIEW'),
  session_id: z.number(),
  upload_url: z.string(),
});

export const attemptingResumeMessageSchema = z.object({
  action_type: z.literal('ATTEMPTINGRESUME'),
  timestamp: z.string(),
});

export const authTokenInvalidMessageSchema = z.object({
  action_type: z.literal('AUTHTOKENINVALID'),
  reason: z.number(),
  token: z.string().nullable(),
  timestamp: z.string(),
});

export const skillSchema = z.object({
  pk: z.number(),
  name: z.string(),
});

export const skillCategorySchema = z.object({
  pk: z.number(),
  name: z.string(),
  skills: z.array(skillSchema),
  is_multi_select: z.boolean(),
  hidden: z.boolean(),
});

export const transferrableSchema = z.object({
  id: z.number(),
  display_text: z.string(),
  display_subtext: z.string().nullable(),
  user: z.object({
    username: z.string(),
    first_name: z.string().nullable(),
    last_name: z.string().nullable(),
  }),
});

export const answerChoiceSchema = z.object({
  pk: z.number(),
  order: z.number(),
  text: z.string(),
});

export const billingQuestionSchema = z.object({
  pk: z.number(),
  order: z.number(),
  question: z.string(),
  answer_type: z.number(),
  mandatory: z.boolean(),
  available_choices: z.array(answerChoiceSchema),
  answer_slug: z.string(),
  help_text: z.string().nullable(),
  question_answer_max_len: z.number().nullable(),
  question_answer_min_len: z.number().nullable(),
  validation_failure_prompt: z.string().nullable(),
});

export const sessionLegUpdateMessageSchema = z.object({
  action_type: z.literal('SESSIONLEGUPDATE'),
  timestamp: z.string(),
  legs: z.array(legDetailSchema),
  inactive_legs: z.array(legDetailSchema),
  session_id: z.number(),
});

export const sessionLegDroppedMessageSchema = z.object({
  action_type: z.literal('SESSIONLEGDROPPED'),
  timestamp: z.string(),
  session_leg: legDetailSchema,
  session_id: z.number(),
});

export const transferAvailableMessageSchema = z.object({
  action_type: z.literal('TRANSFERAVAILABLE'),
  timestamp: z.string(),
  transfer: z.object({
    pk: z.number(),
    reason: z.string().optional().nullable(),
    provider: z.string(),
    enterprise: z.string(),
    client_name: z.string(),
    city: z.string(),
    state: z.string(),
    notes: z.string(),
    from_user: transferrableSchema,
  }),
  session: sessionDetailsSchema,
});

export const warmTransferAvailableMessageSchema = z.object({
  action_type: z.literal('WARMTRANSFERAVAILABLE'),
  timestamp: z.string(),
  transfer: z.object({
    pk: z.number(),
    provider: z.string(),
    reason: z.string().optional().nullable(),
    enterprise: z.string(),
    client_name: z.string(),
    city: z.string(),
    state: z.string(),
    notes: z.string(),
    from_user: transferrableSchema,
  }),
  session: sessionDetailsSchema,
});

export const warmTransferFailMessageSchema = z.object({
  action_type: z.literal('WARMTRANSFERFAIL'),
  timestamp: z.string(),
  transfer_id: z.number(),
  user: transferrableSchema,
});

export const warmTransferCancelledMessageSchema = z.object({
  action_type: z.literal('WARMTRANSFERCANCELLED'),
  timestamp: z.string(),
  transfer_id: z.number(),
  user: transferrableSchema,
});

export const chatLookupSchema = z.object({
  session_id: z.number(),
  message: z.string(),
});

// -------------------
// Post Session Feedback
// -------------------

export const choiceSchema = z.object({
  pk: z.number(),
  order: z.number(),
  text: z.string(),
});

export const AnswerType = {
  Text: 1,
  Choice: 2,
  Rating: 3,
  Boolean: 4,
  Date: 5,
} as const;

export const questionSchema = z.object({
  pk: z.number(),
  answer_slug: z.string(),
  session_leg_id: z.number().optional(),
  question: z.string(),
  order: z.number(),
  answer_type: z.number(),
  mandatory: z.boolean(),
  available_choices: z.array(choiceSchema),
  help_text: z.string().nullable(),
  question_answer_max_len: z.number().nullable(),
  question_answer_min_len: z.number().nullable(),
  validation_failure_prompt: z.string().nullable(),
});

export type Question = z.infer<typeof questionSchema>;

export const postSessionFeedbackSchema = z.object({
  session_id: z.number(),
  questions: z.array(questionSchema),
  created_timestamp: z.string(),
  message: z.string().optional(),
  queueable: z
    .object({
      id: z.number(),
      user: z.object({
        username: z.string(),
        first_name: z.string().nullable(),
        last_name: z.string().nullable(),
      }),
      auto_accept: z.boolean(),
      queueable_type: z.number(),
      groups: z.array(z.object({ name: z.string() })),
      display_text: z.string().nullable(),
      display_subtext: z.string().nullable(),
      widescreen_enabled: z.boolean(),
      is_mentor: z.boolean(),
    })
    .optional(),
});

export const recentSessionSchema = z.object({
  is_video: z.boolean(),
  id: z.number(),
  start_timestamp: z.string(),
  end_timestamp: z.string().nullable(),
  duration: z.string(),
  language: z.string(),
  agent: z.string(),
});

export type RecentSession = z.infer<typeof recentSessionSchema>;

export const recentSessionsSchema = z.object({
  count: z.number(),
  next: z.string().nullable(),
  previous: z.string().nullable(),
  results: z.array(recentSessionSchema),
});

export const notificationSchema = z.object({
  id: z.number(),
  template_title: z.string(),
  sent_content: z.string(),
  sent_title: z.string(),
  notification_code: z.string(),
  created_date: z.string(),
  scheduled_send_date: z.string(),
  expires_date: z.string(),
  color_index: z.number(),
  status: z.number(),
});

export type Notification = z.infer<typeof notificationSchema>;

export const notificationsSchema = z.object({
  notifications: z.array(notificationSchema),
});

export const cafeXTokenSchema = z.object({
  session_id: z.string(),
});

const volumeAdjustmentSchema = z.union([
  z.literal('MIN'),
  z.literal('MAX'),
  z.literal('UP'),
  z.literal('DOWN'),
  z.literal('DEFAULT'),
]);

export type VolumeAdjustment = z.infer<typeof volumeAdjustmentSchema>;

export const sessionVolumeAdjustmentSchema = z.object({
  action_type: z.literal('SESSIONLEGVOLUMEADJUSTMENT'),
  data: z.object({
    session_id: z.number(),
    session_leg_id: z.number(),
    adjustment: volumeAdjustmentSchema,
  }),
  timestamp: z.string(),
});

export type SessionVolumeAdjustment = z.infer<
  typeof sessionVolumeAdjustmentSchema
>;

export const connectionLossMessageSchema = z.object({
  action_type: z.literal('CONNECTION_LOSS'),
  timestamp: z.string(),
  session_id: z.number(),
  message: z.string(),
});

export const oidcResumeAttemptedMessageSchema = z.object({
  action_type: z.literal('OIDCRESUMEATTEMPTED'),
  nonce: z.string().nullable(),
  jti: z.string().nullable(),
  message: z.string(),
});

export type OidcResumeAttempted = z.infer<
  typeof oidcResumeAttemptedMessageSchema
>;
