/**
 * Copyright © 2021, AMN Healthcare, Inc. All rights reserved.
 */

import { ErrorBoundary } from 'react-error-boundary';
import { logBoundaryError } from '@/utils/logBoundaryError';
import { AlertMessage } from '@/ui/alert-message';

export type ContainerLayoutProps = {
  header?: React.ReactNode;
  children: React.ReactNode;
  'aria-label': string;
};

function ContainerLayoutHeader({ children }: React.PropsWithChildren) {
  if (typeof children === 'string') {
    return <h2 className="text-lg font-medium">{children}</h2>;
  }

  return children;
}

function ContainerFallback() {
  return (
    <AlertMessage title="An unexpected error has ocurred">
      <div>
        The following page could not be rendered due to an unexpected error.
      </div>
    </AlertMessage>
  );
}

function ContainerLayout({ header, children, ...props }: ContainerLayoutProps) {
  return (
    <main aria-label={props['aria-label']} className="h-full">
      <div className="container my-6 space-y-4 border bg-white p-6">
        <div className="space-y-4">
          <ContainerLayoutHeader>{header}</ContainerLayoutHeader>
          <ErrorBoundary
            FallbackComponent={ContainerFallback}
            onError={logBoundaryError}
          >
            {children}
          </ErrorBoundary>
        </div>
      </div>
    </main>
  );
}

const Root = ContainerLayout;
const Header = ContainerLayoutHeader;

export { ContainerLayout, ContainerLayoutHeader, Root, Header };
