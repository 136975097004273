/**
 * Copyright © 2021, AMN Healthcare, Inc. All rights reserved.
 */

import { CallStateContext } from '../ProtectedPage';
import { Button } from '@/ui/button';
import { IVVRVideo } from './ui/IVVRVideo';
import { useSession } from '@/protected/Session/SessionContext';
import { SessionSidebarLayout } from './SessionSidebarLayout';

export function SessionUnavailable() {
  const { send } = CallStateContext.useActorRef();

  const session = useSession();
  const ivvr = session.sessionDetails.current_ivvr;

  return (
    <SessionSidebarLayout
      title="Sorry, no matches were found"
      sidebar={
        <Button onPress={() => send({ type: 'session.reload' })} type="button">
          Continue to languages
        </Button>
      }
    >
      {ivvr && <IVVRVideo ivvr={ivvr} />}
    </SessionSidebarLayout>
  );
}
