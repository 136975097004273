/**
 * Copyright © 2021, AMN Healthcare, Inc. All rights reserved.
 */

import React from 'react';
import { useLocation } from 'react-router-dom';
import { BrandedAMNImage } from '@/shared/BrandedAMNImage';
import { TextField } from '@/ui/fields/text-field';
import { Button } from '@/ui/button';
import { AlertMessage } from '@/ui/alert-message';
import { InfoMessage } from '@/ui/info-message';
import { useForm, Controller, type ErrorOption } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useNavigate, Link } from 'react-router-dom';
import api from '@/api/api';
import { ParsedError } from '@/api/api';
import { svenErrorMessage } from '@/api/getError';
import { z } from 'zod';
import { getLicense, setLicense } from './license';
import { getLogoutMessage } from './getLogoutMessage';
import { INTERNAL_APP_NAME } from '@/constants';
import { authProfileUserSchema } from './authApiSchemas';

const unauthorizedErrorSchema = z.object({
  reset_url: z.string().optional(),
});

const forbiddenErrorSchema = z.object({
  api_url: z.string().optional(),
  app_url: z.string().optional(),
});

const loginSchema = z.object({
  username: z.string().min(1, { message: 'Required' }),
  password: z.string().min(1, { message: 'Required' }),
});

type FormInput = z.input<typeof loginSchema>;
type FormOutput = z.output<typeof loginSchema>;

export function Signin() {
  const navigate = useNavigate();
  const location = useLocation();

  const stateUsername = location.state?.username as string | undefined;
  const reason = location.state?.reason;
  const logoutReason = getLogoutMessage(reason);

  const [licenseResult] = React.useState(() => getLicense());

  const defaultUsername =
    (licenseResult.type === 'found' ? licenseResult.license.username : '') ||
    stateUsername ||
    '';
  const defaultPassword =
    licenseResult.type === 'found' ? licenseResult.license.password : '';

  const { setError, formState, handleSubmit, control, resetField } = useForm<
    FormInput,
    any,
    FormOutput
  >({
    shouldFocusError: true,
    resolver: zodResolver(loginSchema),
    defaultValues: {
      username: defaultUsername,
      password: defaultPassword,
    },
  });

  const [loginSuccess, setLoginSuccess] = React.useState(false);

  React.useLayoutEffect(() => {
    // Tracks whether or not we've seen this page, allowing
    // the user to get back to the login screen to re-enter their
    // username.  This is specifically for auto-filling the
    // username + password license fields.
    window.hasSeenSignIn = true;
  }, []);

  React.useEffect(() => {
    if (loginSuccess) {
      navigate('/consumer');
    }
  }, [navigate, loginSuccess]);

  const onSubmit = async (data: FormOutput) => {
    try {
      const result = await api
        .post('/api/login', {
          json: {
            username: data.username,
            password: data.password,
            source: 'web',
            login_origin: window.location.origin,
            build_version: process.env.REACT_APP_BUILD_VERSION || null,
            version: process.env.REACT_APP_BUILD_VERSION || null,
            app: INTERNAL_APP_NAME,
            // Timezone needed to format agent's work schedule, if
            // they use ai web to login instead of iws/cws/cca.
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          },
          validationSchema: authProfileUserSchema,
        })
        .json<z.infer<typeof authProfileUserSchema>>();

      if (result.type === 'client') {
        setLicense(data);
      }

      setLoginSuccess(true);
    } catch (error) {
      const getError = (): ErrorOption => {
        if (error && error instanceof ParsedError) {
          switch (error.response.status) {
            case 401: {
              const parsed = unauthorizedErrorSchema.safeParse(error.json);

              if (parsed.success && parsed.data.reset_url) {
                window.location.assign(parsed.data.reset_url);

                return {
                  type: 'server',
                  message: 'Password reset needed.  Redirecting ...',
                };
              }

              return {
                type: 'server',
                message: 'Username or password is incorrect.  Try again.',
              };
            }
            case 403: {
              const parsed = forbiddenErrorSchema.safeParse(error.json);

              if (parsed.success && parsed.data.app_url) {
                return {
                  type: 'app_url',
                  message: parsed.data.app_url,
                };
              } else if (parsed.success && parsed.data.api_url) {
                return {
                  type: 'api_url',
                  message: parsed.data.api_url,
                };
              }
            }
          }
        }

        return { type: 'server', message: svenErrorMessage(error) };
      };

      setError('root', getError());

      if (licenseResult.type !== 'found') {
        // Only reset the password when not auto-filled by the license
        resetField('password', { defaultValue: '' });
      }
    }
  };

  const errorRoot = formState.errors.root;

  return (
    <div className="space-y-2">
      <BrandedAMNImage className="mx-auto" />

      <form
        className="mx-auto max-w-sm space-y-2"
        onSubmit={handleSubmit(onSubmit)}
      >
        {logoutReason && <InfoMessage>{logoutReason}</InfoMessage>}

        {errorRoot?.type === 'api_url' && errorRoot?.message && (
          <AlertMessage title="Failed to sign in">
            <p>
              Please login with the URL associated with your enterprise.{' '}
              <a href={errorRoot?.message} className="underline">
                Go to Your Enterprise.
              </a>
            </p>
          </AlertMessage>
        )}

        {errorRoot?.type === 'app_url' && errorRoot?.message && (
          <AlertMessage title="Failed to sign in">
            <p>
              Please sign in with the URL associated with your enteprise.{' '}
              <a href={errorRoot?.message} className="underline">
                Go to Your Enterprise.
              </a>
            </p>
          </AlertMessage>
        )}

        {errorRoot?.type === 'server' && errorRoot?.message && (
          <AlertMessage title="Failed to sign in">
            <p>{errorRoot?.message}</p>
          </AlertMessage>
        )}

        <div>
          <Controller
            render={({ field: { ...field }, fieldState: { error } }) => (
              <TextField
                type="text"
                isRequired
                label="Username"
                placeholder="Enter your username"
                errorMessage={error?.message}
                maxLength={512}
                autoFocus={defaultUsername.length === 0}
                autoComplete="username"
                {...field}
              />
            )}
            control={control}
            name="username"
          />

          <Controller
            render={({ field: { ...field }, fieldState: { error } }) => (
              <TextField
                type="password"
                isRequired
                label="Password"
                placeholder="Enter your password"
                errorMessage={error?.message}
                maxLength={512}
                autoFocus={defaultUsername.length > 0}
                autoComplete="current-password"
                {...field}
              />
            )}
            control={control}
            name="password"
          />
        </div>
        <div className="space-y-2 text-right">
          <div>
            <Button type="submit" variant="secondary">
              Sign in
            </Button>
          </div>
          <div className="text-sm text-muted-foreground">
            <Link to="/public/login" className="hover:underline">
              Switch to Email
            </Link>
          </div>
        </div>
      </form>
    </div>
  );
}
