/**
 * Copyright © 2021, AMN Healthcare, Inc. All rights reserved.
 */

import React from 'react';
import { Button } from '@/ui/button';
import { svenErrorMessage } from '@/api/getError';
import { useMyEnterpriseLanguages } from '@/protected/apis/protectedApiQueries';
import { usePreferredVideoResolution } from '@/protected/shared/usePreferredVideoResolution';
import { useAuthProfile } from '@/protected/AuthProfileContext';
import { MdSearch } from 'react-icons/md';
import { matchSorter } from 'match-sorter';
import { CallStateContext } from '../ProtectedPage';
import { timer } from 'rxjs';
import { SessionLayout } from './SessionLayout';
import { AlertMessage } from '@/ui/alert-message';
import * as R from 'remeda';

type RequestButtonsProps = { disabled?: boolean };

/**
 * Displays a list of language buttons to enqueue a video interpreting session.
 * Displays an audio button to enqueue an audio-only interpreting session.
 */
export function SessionRequestButtons({ disabled }: RequestButtonsProps) {
  const profile = useAuthProfile();
  const [preferredVideoResolution] = usePreferredVideoResolution();
  const [search, setSearch] = React.useState('');
  const actorRef = CallStateContext.useActorRef();

  React.useEffect(() => {
    // Per requirements, reset the search after 5 minutes.
    const subscription = timer(30_000).subscribe(() => setSearch(''));
    return () => subscription.unsubscribe();
  }, [search, setSearch]);

  const query = useMyEnterpriseLanguages();

  const filteredList = React.useMemo(() => {
    // Apply a startsWith filter on the languages
    const list = matchSorter(query.data || [], search, {
      keys: ['display_text'],
      threshold: matchSorter.rankings.WORD_STARTS_WITH,
    });

    // Return the original ordering
    return R.pipe(
      list,
      R.sortBy(
        (x) => x.order ?? Number.MAX_SAFE_INTEGER,
        (x) => x.display_text
      )
    );
  }, [query.data, search]);

  const enqueueVideo = (buttonId: number) => {
    actorRef.send({
      type: 'enqueue.enqueue',
      request: {
        type: 'video',
        buttonId: buttonId,
        requestedTimestamp: new Date(),
        preferredVideoResolution: preferredVideoResolution ?? undefined,
      },
    });
  };

  const enqueueAudio = () => {
    actorRef.send({
      type: 'enqueue.enqueue',
      request: {
        type: 'audio',
        requestedTimestamp: new Date(),
      },
    });
  };

  return (
    <SessionLayout greenBackground scrollableContent="hidden">
      <div className="flex h-full flex-col gap-2 overflow-hidden">
        <div className="flex-auto overflow-hidden">
          {query.status === 'success' && (
            <div className="flex h-full w-full flex-col space-y-2 overflow-hidden">
              <div className="flex-none rounded bg-black/[.1] p-2">
                <form className="relative">
                  <label
                    className="absolute left-[0.75rem] flex h-full items-center text-gray-700"
                    id="filter-languages"
                  >
                    <MdSearch aria-hidden />
                    <span className="sr-only">Filter languages</span>
                  </label>
                  <input
                    autoFocus
                    id="filter-languages-input"
                    type="text"
                    aria-labelledby="filter-languages"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    autoComplete="off"
                    autoCorrect="off"
                    autoCapitalize="off"
                    spellCheck="false"
                    placeholder="Filter languages"
                    maxLength={512}
                    className="h-10 w-full rounded border-2 border-branded2 bg-gray-50 pl-8 pr-4 placeholder-gray-500 outline-none ring-white ring-offset-1 ring-offset-branded2 focus:ring-2"
                  />
                </form>
              </div>
              <div className="flex-auto overflow-hidden">
                {filteredList.length === 0 && (
                  <div className="h-fit w-full text-lg text-white">
                    {search.length > 0 ? (
                      <span>No languages matched.</span>
                    ) : (
                      <span>No languages found.</span>
                    )}
                  </div>
                )}
                <ul className="always-on-scrollbar inline-flex max-h-full w-full list-none flex-wrap gap-2 overflow-x-hidden overflow-y-scroll pr-2">
                  {filteredList.map((language) => (
                    <li
                      className="w-full p-1 sm:w-[calc((100%/2)-(theme('gap.2')*0.5))] md:w-[calc((100%/3)-(theme('gap.2')*1))]"
                      key={language.pk}
                    >
                      <button
                        type="button"
                        className="flex h-full w-full flex-col items-center truncate rounded-md border-4 border-branded2 bg-white px-3.5 py-3 font-semibold text-branded2 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-offset-1 disabled:cursor-not-allowed disabled:opacity-70"
                        disabled={disabled}
                        onClick={() => {
                          enqueueVideo(language.pk);
                        }}
                      >
                        <div className="max-w-full truncate text-lg">
                          {language.display_text}
                        </div>
                        <div className="max-w-full truncate text-sm">
                          {language.display_subtext}
                        </div>
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          )}
          {query.status === 'error' && (
            <div className="h-full flex-none">
              <div className="flex flex-col gap-2">
                <AlertMessage title="Failed to load languages">
                  <p>{svenErrorMessage(query.error)}</p>
                </AlertMessage>
              </div>
            </div>
          )}
          {query.status === 'pending' && (
            <div className="h-full w-full lg:overflow-visible" aria-busy>
              <h3 className="loading-ellipsis text-xl font-medium text-white">
                Loading languages
              </h3>
            </div>
          )}
        </div>
        {profile.opi_config.enabled && (
          <div className="flex h-10 w-full flex-none basis-12 items-center justify-center py-2">
            <Button
              size="lg"
              type="button"
              onPress={() => {
                enqueueAudio();
              }}
            >
              {profile.opi_config.label}
            </Button>
          </div>
        )}
      </div>
    </SessionLayout>
  );
}
