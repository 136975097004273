/**
 * Copyright © 2021, AMN Healthcare, Inc. All rights reserved.
 */

import { useQuery } from '@tanstack/react-query';
import { fetchTierFavorites } from './accountFetchers';

export const accountKey = ['tier_favorites'];

export function useTierFavorites() {
  return useQuery({
    queryKey: accountKey,
    queryFn: fetchTierFavorites,
    retry: 0,
    staleTime: Infinity,
  });
}
