/**
 * Copyright © 2021, AMN Healthcare, Inc. All rights reserved.
 */

import React from 'react';
import { SessionLayout } from './SessionLayout';
import { Button } from '@/ui/button';
import { sessionFinishedMachine } from '@/machines/sessionFinishedMachine';
import { createActorContext } from '@xstate/react';
import { Spinner } from '@/ui/spinner';
import { formatLongFriendly } from '@/utils/date-formatter';
import { Questions } from './ui/Questions';
import { CountdownSeconds } from '@/protected/shared/CountdownSeconds';
import { joinName } from '@/utils/joinName';
import { useForm, FormProvider, type SubmitHandler } from 'react-hook-form';
import { AnswerType } from '@/protected/apis/protectedApiSchemas';
import { AlertMessage } from '@/ui/alert-message';
import { useAuthProfile } from '@/protected/AuthProfileContext';
import { debugLog } from '@/utils/debugLog';

const FinishedStateContext = createActorContext(sessionFinishedMachine);

type LabeledValueProps = {
  label: string;
  value: React.ReactNode;
};
function LabeledValue({ label, value }: LabeledValueProps) {
  return (
    <div className="space-x-2">
      <span className="font-semibold">{label}</span>
      <span>{value}</span>
    </div>
  );
}

function SessionSummary() {
  const feedback = FinishedStateContext.useSelector((state) => {
    return state.context.feedback;
  });

  if (!feedback) return null;

  const lastAgent = joinName(
    feedback.queueable?.display_text,
    feedback.queueable?.display_subtext
  );

  return (
    <div>
      <LabeledValue label="Session ID:" value={feedback.session_id} />
      <LabeledValue
        label="Start time:"
        value={formatLongFriendly(new Date(feedback.created_timestamp))}
      />
      {lastAgent && <LabeledValue label="Last agent:" value={lastAgent} />}
    </div>
  );
}

export function ToLanguagesButton() {
  const { send } = FinishedStateContext.useActorRef();

  return (
    <Button
      variant="secondary"
      onPress={() => send({ type: 'finish' })}
      type="button"
    >
      Go to languages
    </Button>
  );
}
type FeedbackLayoutProps = {
  main: React.ReactNode;
  supplementalButton?: React.ReactNode;
};

function FeedbackLayout({ main, supplementalButton }: FeedbackLayoutProps) {
  const expiresAt = FinishedStateContext.useSelector(
    (state) => state.context.expireTime
  );

  return (
    <div className="-mx-2 flex w-full flex-auto flex-col space-y-4 px-2">
      <h3 className="text-lg font-extrabold">Session complete</h3>
      <div className="space-y-4">
        <SessionSummary />
        {main}
      </div>
      <div className="space-y-2">
        <div className="space-x-2 text-right">
          <ToLanguagesButton />
          {supplementalButton}
        </div>
        {expiresAt && (
          <p className="text-right">
            Automatically going to Languages in{' '}
            <span className="font-medium">
              <CountdownSeconds expiresAt={expiresAt}>
                {(seconds) => (
                  <>
                    <span className="tabular-nums">{seconds}</span> second(s)
                    ...
                  </>
                )}
              </CountdownSeconds>
            </span>
          </p>
        )}
      </div>
    </div>
  );
}

function Loading() {
  return (
    <FeedbackLayout
      main={
        <div className="flex w-full flex-col items-center gap-2">
          <div>Checking for feedback questions</div>
          <Spinner size={32} />
        </div>
      }
    />
  );
}

function LoadingFailed() {
  return (
    <FeedbackLayout
      main={<AlertMessage>Failed to load feedback questions.</AlertMessage>}
    />
  );
}

export type AnswerData =
  | { type: typeof AnswerType.Text; value: string }
  | { type: typeof AnswerType.Choice; value: string }
  | { type: typeof AnswerType.Date; value: string }
  | { type: typeof AnswerType.Boolean; value: string }
  | { type: typeof AnswerType.Rating; value: number };

export type FormInput = Record<string, AnswerData>;

function FeedbackNeeded() {
  const { send } = FinishedStateContext.useActorRef();

  // Is our state machine answering the questions against the backend
  const isAnswering = FinishedStateContext.useSelector((state) =>
    state.matches({ feedback_needed: 'answering' })
  );

  const methods = useForm<FormInput>({ shouldFocusError: true });

  const { feedback, answerFailure } = FinishedStateContext.useSelector(
    (state) => {
      return {
        feedback: state.context.feedback,
        answerFailure: state.context.answerFailure,
      };
    }
  );

  // On submit handler that sends the answers to the state machine
  const onSubmit: SubmitHandler<FormInput> = (data) => {
    const answers: Record<string, string | number> = {};

    feedback?.questions.forEach((question) => {
      const answer = data[question.answer_slug];
      if (answer) {
        answers[question.answer_slug] = answer.value;
      }
    });

    send({ type: 'answer', answers: answers });
  };

  return (
    <FeedbackLayout
      main={
        feedback?.questions ? (
          <FormProvider {...methods}>
            <form
              id="post-answers-form"
              onSubmit={methods.handleSubmit(onSubmit)}
              className="space-y-2"
            >
              <h4 className="font-medium">
                Help us improve. Rate your experience!
              </h4>

              {answerFailure && <AlertMessage>{answerFailure}</AlertMessage>}

              <Questions questions={feedback.questions} />
            </form>
          </FormProvider>
        ) : (
          <div>No feedback needed.</div>
        )
      }
      supplementalButton={
        <Button
          variant="default"
          form="post-answers-form"
          type="submit"
          isDisabled={isAnswering}
        >
          Submit feedback
        </Button>
      }
    />
  );
}

function FeedbackNotNeeded() {
  // Current AI shows no message here.
  return <FeedbackLayout main={<React.Fragment />} />;
}

function Finished() {
  return <FeedbackLayout main={<div>Going to languages ...</div>} />;
}

function SessionFinishedImpl() {
  const component = FinishedStateContext.useSelector((state) => {
    debugLog('SessionFinished state.value', state.value);
    debugLog('SessionFinished state.context', state.context);

    if (state.matches('loading')) return <Loading />;
    if (state.matches('loading_failed')) return <LoadingFailed />;
    if (state.matches('feedback_needed')) return <FeedbackNeeded />;
    if (state.matches('feedback_not_needed')) return <FeedbackNotNeeded />;
    if (state.matches('finished')) return <Finished />;

    console.error('SessionFinished bad match', JSON.stringify(state.value));

    throw new Error('bad match');
  });

  return (
    <SessionLayout>
      <div className="flex h-full w-full justify-between space-x-4">
        {component}
      </div>
    </SessionLayout>
  );
}

const MemoedSessionFinishedImpl = React.memo(SessionFinishedImpl);

export function SessionFinished() {
  const profile = useAuthProfile();
  const softTimeout = (profile.enterprise.feedback_soft_timeout ?? 600) * 1_000;

  return (
    <FinishedStateContext.Provider
      logic={sessionFinishedMachine.provide({})}
      options={{
        input: {
          withoutQuestionsTimeout: 60_000,
          withQuestionsTimeout: softTimeout,
        },
      }}
    >
      <MemoedSessionFinishedImpl />
    </FinishedStateContext.Provider>
  );
}
