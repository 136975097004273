/**
 * Copyright © 2021, AMN Healthcare, Inc. All rights reserved.
 */

import React from 'react';
import { Tab, TabList, TabPanel, TabsRoot } from '@/ui/side-tabs';
import { useAuthCheck } from '@/auth/AuthCheckContext';
import { simpleFormatPhoneNumber } from '@/utils/simpleFormatPhoneNumber';
import { MediaDiagnostics } from '@/shared/MediaDiagnostics';

const Version = process.env.REACT_APP_BUILD_VERSION;

function Contact() {
  const profileResult = useAuthCheck();
  const profile =
    profileResult.profile.type === 'authorized'
      ? profileResult.profile.profile
      : undefined;

  const supportMessage = profile?.enterprise.support_details;
  const supportNumber = profile?.enterprise.support_number || '+18556631231';

  // The support number is going to be US only for now, so we can use a
  // simple formatter
  const phone = React.useMemo(
    () => simpleFormatPhoneNumber(supportNumber),
    [supportNumber]
  );

  return (
    <div className="space-y-4">
      <p>
        Currently having an issue? Have a suggestion about what we can do
        better? Contact our <em>Customer Success Team</em>.
      </p>

      {supportMessage && (
        <div className="hyphens-auto whitespace-pre-line text-sm break-anywhere">
          {supportMessage}
        </div>
      )}
      <div>
        <span className="font-medium">Phone:</span> {phone}
      </div>

      <div className="text-sm">Version: {Version || 'not available'}</div>
    </div>
  );
}

export function PublicSupport() {
  return (
    <div className="container mx-auto space-y-4 border bg-white p-6">
      <h1 className="text-xl font-medium">AMN Interpreting Customer Support</h1>
      <div className="w-full">
        <TabsRoot>
          <TabList aria-label="Support Panels">
            <Tab id="contact">Contact</Tab>
            <Tab id="diagnostics">Media Diagnostics</Tab>
          </TabList>
          <TabPanel id="contact">
            <Contact />
          </TabPanel>
          <TabPanel id="diagnostics">
            <MediaDiagnostics />
          </TabPanel>
        </TabsRoot>
      </div>
    </div>
  );
}
