/**
 * Copyright © 2021, AMN Healthcare, Inc. All rights reserved.
 */

import React from 'react';
import { useNavigate } from 'react-router-dom';
import { TextField } from '@/ui/fields/text-field';
import { Button } from '@/ui/button';
import { AlertMessage, SuccessMessage } from '@/ui/messages';
import { useForm, Controller } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import api from '@/api/api';
import { svenErrorMessage } from '@/api/getError';
import { z } from 'zod';

const loginSchema = z.object({
  username: z.string().min(1, { message: 'Required' }),
});

type FormInput = z.input<typeof loginSchema>;
type FormOutput = z.output<typeof loginSchema>;

export function ForgotPassword() {
  const navigate = useNavigate();

  const [success, setSuccess] = React.useState(false);

  const { setError, handleSubmit, control, formState } = useForm<
    FormInput,
    any,
    FormOutput
  >({
    shouldFocusError: true,
    resolver: zodResolver(loginSchema),
    defaultValues: {
      username: '',
    },
  });

  const onSubmit = async (data: FormOutput) => {
    try {
      await api.post('/api/forgot_password', {
        json: {
          username: data.username,
        },
      });
      setSuccess(true);
    } catch (error) {
      const message = svenErrorMessage(error);
      setError('root', { type: 'server', message });
    }
  };

  React.useEffect(() => {
    // This may not be the best behavior, but it's how it was implemented in webclient.
    if (success) {
      const timeout = setTimeout(() => navigate('/public/login'), 4_000);

      return () => clearTimeout(timeout);
    }
  }, [success, navigate]);

  if (success) {
    return (
      <div className="mx-auto max-w-sm space-y-2">
        <h2 className="text-lg font-medium text-slate-700">Forgot password?</h2>
        <div className="space-y-2">
          <SuccessMessage>
            <p>
              Password reset successfully. We'll email you a reset password
              link.
            </p>
            <p>Redirecting to login ...</p>
          </SuccessMessage>
        </div>
      </div>
    );
  }

  return (
    <div className="mx-auto max-w-sm space-y-2">
      <h2 className="text-lg font-medium text-slate-700">Forgot password?</h2>

      <form className="space-y-2" onSubmit={handleSubmit(onSubmit)}>
        {formState.errors.root?.message && (
          <AlertMessage title="Failed to request password">
            <p>{formState.errors.root?.message}</p>
          </AlertMessage>
        )}

        <div className="space-y-2">
          <Controller
            render={({ field: { ...field }, fieldState: { error } }) => (
              <TextField
                type="text"
                isRequired
                label="Username"
                errorMessage={error?.message}
                autoComplete="off"
                {...field}
              />
            )}
            control={control}
            name="username"
          />
        </div>
        <div className="text-right">
          <Button
            variant="secondary"
            isDisabled={formState.isSubmitting}
            type="submit"
          >
            Reset password
          </Button>
        </div>
      </form>
    </div>
  );
}
