/**
 * Copyright © 2021, AMN Healthcare, Inc. All rights reserved.
 */

import React from 'react';
import { z } from 'zod';
import * as R from 'remeda';

function parse<T>(item: string, schema: z.Schema<T>): T {
  const result = JSON.parse(item);
  return schema.parse(result);
}

// what happens when we have {a: number, b: number}, but our new schema is { a: number, b: number, c: number }.
// it'd fail since c is missing.  we could make c optional.  we could create new key and transform or discard the old.

export function useLocalStorage<T>(
  key: string,
  initialValue: T,
  schema: z.Schema<T>
) {
  const readValue = React.useCallback(() => {
    try {
      const item = window.localStorage.getItem(key);
      return item ? parse<T>(item, schema) : initialValue;
    } catch (error) {
      console.warn('Invalid read from useLocalStorage storage', error);
      return initialValue;
    }
  }, [key, initialValue, schema]);

  const [localState, setLocalState] = React.useState(readValue);

  const handleSetState = React.useCallback(
    (value: T | ((prevState: T) => T)) => {
      try {
        const nextState = R.isFunction(value) ? value(localState) : value;
        setLocalState(nextState);

        // it's ok if the write fails, which likely won't
        window.localStorage.setItem(key, JSON.stringify(nextState));
      } catch (error) {
        console.warn('Invalid write to useLocalStorag', error);
      }
    },
    [key, localState]
  );

  return [localState, handleSetState] as const;
}
