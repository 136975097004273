/**
 * Copyright © 2021, AMN Healthcare, Inc. All rights reserved.
 */

import { CallStateContext } from '../ProtectedPage';
import { SessionLayout } from './SessionLayout';
import { formatAllFriendly } from '@/utils/date-formatter';

export function SessionDetached() {
  const session = CallStateContext.useSelector((s) => {
    return s.context.session;
  });

  return (
    <SessionLayout>
      <div className="flex h-full w-full justify-between space-x-2">
        <div className="items-left flex h-full flex-auto flex-col space-y-3">
          <h3 className="flex-none text-xl font-medium">Session Found</h3>
          <div className="h-full min-h-[200px] w-full flex-auto space-y-3">
            <p>
              An active session has been found. Use the{' '}
              <span className="font-medium">banner above</span> to continue or
              end the session.
            </p>
            {session && (
              <div>
                <p>
                  <strong>Session ID:</strong> {session.sessionDetails.id}
                </p>
                <p>
                  <strong>Start Time:</strong>{' '}
                  {formatAllFriendly(
                    new Date(session.sessionDetails.created_timestamp)
                  )}
                </p>
                {session.sessionDetails.to_language && (
                  <p>
                    <strong>Language:</strong>{' '}
                    {session.sessionDetails.to_language.name}
                  </p>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </SessionLayout>
  );
}
