/**
 * Copyright © 2021, AMN Healthcare, Inc. All rights reserved.
 */

import { z } from 'zod';
import api from '@/api/api';
import { hrefBase } from '@/constants';

const enterpriseSchema = z.object({
  id: z.number(),
  parent_enterprise: z.number().nullable(),
  is_selectable: z.boolean(),
  name: z.string(),
});

const tierSchema = z.object({
  name: z.string(),
  enterprises: z.array(enterpriseSchema),
});

export const tiersSchema = z.object({
  enterprise_tiers: z.object({
    tier_1: tierSchema,
    tier_2: tierSchema,
    tier_3: tierSchema,
  }),
  root_enterprise: z.object({
    id: z.number(),
    parent_enterprise: z.number().nullable(),
    is_selectable: z.boolean(),
    name: z.string(),
  }),
});

const childAccountsSchema = z.object({
  id: z.number(),
  name: z.string(),
  parents: z.array(z.string()),
});

export const tierFavoritesSchema = z.object({
  child_accounts: z.array(childAccountsSchema),
  root_enterprise: z.object({
    id: z.number(),
    name: z.string(),
  }),
});

export type Tiers = z.infer<typeof tiersSchema>;

// All 403s will cause a redirect to the following URL.
// Override by setting autoLogoutUrl to undefined
// in any of the apis below.
const autoLogoutApi = api.extend({
  autoLogoutUrl: `${hrefBase}/logout?reason=timeout`,
});

export async function fetchTiers() {
  return await autoLogoutApi
    .get('/child_accounts/accounts', {
      validationSchema: tiersSchema,
    })
    .json<z.infer<typeof tiersSchema>>();
}

export async function fetchTierFavorites() {
  return await autoLogoutApi
    .get('/child_accounts/saved', {
      validationSchema: tierFavoritesSchema,
    })
    .json<z.infer<typeof tierFavoritesSchema>>();
}

export async function removeFavorite(id: number) {
  return await autoLogoutApi.delete(`/child_accounts/remove_selection/${id}`);
}

export async function addFavorite(id: number) {
  return await autoLogoutApi.post('/child_accounts/saved', {
    json: {
      enterprise: id,
    },
  });
}

export async function setFavorite(id: number) {
  return await autoLogoutApi.put('/child_accounts/primary_enterprise', {
    json: {
      enterprise: id,
    },
  });
}
