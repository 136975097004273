/**
 * Copyright © 2021, AMN Healthcare, Inc. All rights reserved.
 */

import type { APIError } from '@/api/api';
import type { EnterpriseLanguage } from './protectedApiSchemas';
import { useAuthProfile } from '@/protected/AuthProfileContext';
import { useQuery, queryOptions } from '@tanstack/react-query';
import * as backend from './protectedApiFetchers';

// -------------------
// Enterprise
// -------------------

export const enterpriseKeys = {
  all: ['enterprise'],
  byEnterprise: (enterpriseId: number) =>
    [...enterpriseKeys.all, enterpriseId] as const,
};

// -------------------
// Languages
// -------------------

export const languageKeys = {
  byEnterprise: (enterpriseId: number) =>
    [...enterpriseKeys.byEnterprise(enterpriseId), 'languages'] as const,
};

export function createLanguageOptions(id: number) {
  return queryOptions<EnterpriseLanguage[], APIError>({
    queryKey: languageKeys.byEnterprise(id),
    queryFn: backend.fetchLanguages,
  });
}

export function useEnterpriseLanguages(enterpriseId: number) {
  return useQuery({
    ...createLanguageOptions(enterpriseId),
    retry: 2,
  });
}

export function useMyEnterpriseLanguages() {
  const profile = useAuthProfile();

  return useQuery({
    ...createLanguageOptions(profile.enterprise.id),
    retry: 2,
  });
}

// -------------------
// Session
// -------------------

export const sessionKeys = {
  byEnterprise: (enterpriseId: number) =>
    [...enterpriseKeys.byEnterprise(enterpriseId), 'session'] as const,
  bySession: (enterpriseId: number, sessionId: number) =>
    [...sessionKeys.byEnterprise(enterpriseId), sessionId] as const,
};

// -------------------
// Notifications
// -------------------

export function useNotifications() {
  const localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  return useQuery({
    queryKey: ['notifications', localTimezone],
    queryFn: () => backend.fetchNotifications(localTimezone),
    retry: 1,
    staleTime: Infinity,
  });
}
