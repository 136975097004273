/**
 * Copyright © 2021, AMN Healthcare, Inc. All rights reserved.
 */

type InfoMessageProps = { title?: string; children: React.ReactNode };

export function InfoMessage({ children, title }: InfoMessageProps) {
  return (
    <div
      className="border border-l-4 border-blue-400 bg-blue-100 p-2 text-blue-900"
      role="alert"
    >
      {title && <p className="font-bold">{title}</p>}
      {children}
    </div>
  );
}
