/**
 * Copyright © 2021, AMN Healthcare, Inc. All rights reserved.
 */

import React from 'react';

import { Dialog } from 'react-aria-components';
import { Button } from '@/ui/button';
import * as Modal from '@/ui/modal';

// A controlled Confirmation Dialog.
//
// Cancel will become auto-focused if it exists; otherwise,
// the confirm action will gain focus

type ConfirmDialogProps = {
  title?: string;
  content: React.ReactNode;
  isOpen: boolean;
  onConfirm: () => void;
  onCancel?: () => void;
  confirmName?: string;
  cancelName?: string;
};

export function ConfirmDialog({
  title = 'Confirm?',
  content,
  isOpen,
  onConfirm,
  onCancel,
  confirmName = 'Confirm',
  cancelName = 'Cancel',
}: ConfirmDialogProps) {
  const hasCancel = onCancel != null;

  return (
    <Modal.Root
      className="max-w-2xl"
      isDismissable={hasCancel}
      isOpen={isOpen}
      onOpenChange={(isOpen) => {
        // TODO verify
        if (!isOpen && hasCancel) {
          onCancel();
        }
      }}
    >
      <Dialog className="w-full overflow-hidden">
        <div className="">
          <Modal.Heading className="rounded-t-md bg-branded2 px-4 py-2 text-lg text-white">
            {title}
          </Modal.Heading>
          <div className="px-4 py-4">{content}</div>
          <div className="h-[1px] border-t-0 bg-gray-100 px-[1px] opacity-100" />
          <div className="space-x-2 px-4 py-2 text-right">
            {onCancel && (
              <Button
                type="button"
                variant="ghost"
                onPress={() => onCancel()}
                autoFocus
                data-e2e="confirm-dialog-cancel"
              >
                {cancelName}
              </Button>
            )}
            <Button
              type="button"
              onPress={() => onConfirm()}
              autoFocus={!hasCancel}
              data-e2e="confirm-dialog-accept"
            >
              {confirmName}
            </Button>
          </div>
        </div>
      </Dialog>
    </Modal.Root>
  );
}

export default ConfirmDialog;
