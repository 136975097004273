/**
 * Copyright © 2021, AMN Healthcare, Inc. All rights reserved.
 */

import { DEFAULT_FRAMERATE } from '@/constants';
import React from 'react';
import { DeviceAlert } from '@/shared/DeviceAlert';
import { useCamera } from '@/shared/useCamera';

const WideScreen = { width: 1280, height: 640 };
const StandardScreen = { width: 720, height: 480 };

export type MediaDiagnosticsCameraProps = {
  supportsWidescreen: boolean;
};
export const MediaDiagnosticsCamera = ({
  supportsWidescreen,
}: MediaDiagnosticsCameraProps) => {
  const { width, height } = supportsWidescreen ? WideScreen : StandardScreen;

  const cameraConstraints = React.useMemo(() => {
    let constraints: MediaStreamConstraints = {
      video: {
        width: { exact: width },
        height: { exact: height },
        frameRate: { ideal: DEFAULT_FRAMERATE },
      },
    };

    return constraints;
  }, [width, height]);

  const stream = useCamera({ on: true, constraints: cameraConstraints });
  const videoRef = React.useRef<HTMLVideoElement>(null);

  React.useEffect(() => {
    let node = videoRef.current;

    if (node && stream.type === 'on') {
      node.srcObject = stream.stream;
    } else if (node) {
      node.srcObject = null;
    }

    return () => {
      if (node) {
        node.srcObject = null;
      }
    };
  }, [stream]);

  const handleCanPlay = () => {
    if (videoRef.current) {
      videoRef.current.play();
    }
  };

  if (stream.type === 'failure') {
    return (
      <DeviceAlert
        title="Unable to access camera"
        errorName={stream.errorName}
        deviceName="camera"
      />
    );
  }

  if (stream.type === 'pending') {
    return <p className="loading-ellipsis">Accessing camera</p>;
  }

  return (
    <div className="select-none border border-black bg-gray-800">
      <video
        className="pointer-events-none h-full w-full"
        aria-label="Self-view video"
        controls={false}
        ref={videoRef}
        onCanPlay={handleCanPlay}
        autoPlay
        playsInline
        muted
      />
    </div>
  );
};
