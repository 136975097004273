/**
 * Copyright © 2021, AMN Healthcare, Inc. All rights reserved.
 */

type JoinArgs = (string | null | undefined)[];
export function joinName(...args: JoinArgs): string | null {
  let result: string[] = [];

  args.forEach((arg) => {
    if (arg == null) {
      return;
    }

    result.push(arg);
  });

  return result.length > 0 ? result.join(' ') : null;
}

export function joinNameWithDefault(defaultName: string) {
  return (args: JoinArgs) => {
    const result = joinName(...args);
    return result || defaultName;
  };
}
