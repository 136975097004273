/**
 * Copyright © 2021, AMN Healthcare, Inc. All rights reserved.
 */

import { AuthCardLayout } from '@/auth/AuthCardLayout';

export function TabLocked() {
  return (
    <AuthCardLayout header="Multiple tabs detected">
      <p className="text-muted-foreground">
        It looks like you have this app open in another tab.
      </p>
      <p className="text-muted-foreground">
        Please close this tab and continue usage in the original tab.
      </p>
    </AuthCardLayout>
  );
}
