/**
 * Copyright © 2021, AMN Healthcare, Inc. All rights reserved.
 */

import React from 'react';
import { cn } from '@/utils/cn';

type SessionLayoutProps = {
  children: React.ReactNode;
  greenBackground?: boolean;
  scrollableContent?: React.CSSProperties['overflowY'];
  fullScreen?: boolean;
};
export function SessionLayout(props: SessionLayoutProps) {
  const { children, greenBackground, scrollableContent = 'visible' } = props;

  return (
    <main
      className={cn('h-full', greenBackground && 'bg-branded1')}
      style={{ overflowY: scrollableContent }}
    >
      <div
        className={cn(
          'h-full overflow-x-hidden py-6',
          props.fullScreen ? 'w-full px-4 py-0' : 'container'
        )}
      >
        {children}
      </div>
    </main>
  );
}
