/**
 * Copyright © 2021, AMN Healthcare, Inc. All rights reserved.
 */

import { SessionLayout } from './SessionLayout';

export function SessionInitializing() {
  return (
    <SessionLayout greenBackground>
      <div className="h-full w-full lg:overflow-visible" aria-busy>
        <h3 className="loading-ellipsis text-xl font-medium text-white">
          Checking for an existing session
        </h3>
      </div>
    </SessionLayout>
  );
}
