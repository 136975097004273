/**
 * Copyright © 2021, AMN Healthcare, Inc. All rights reserved.
 */

import React from 'react';
import * as backend from '@/auth/authApiFetchers';
import {
  authProfileSchema,
  genericAuthProfileSchema,
} from '@/auth/authApiSchemas';
import type { AuthProfile, GenericAuthProfile } from '@/auth/authApiSchemas';
import { svenErrorMessage } from '@/api/getError';
import { HTTPError } from '@/api/api';
import { messages } from '@/constants';
import { noticeError } from '@/utils/noticeError';

function getRedirectUrl(user: GenericAuthProfile['user']) {
  switch (user.type) {
    case 'sven_admin':
      return '/control';
    case 'reporter':
      return '/report';
    case 'administrator':
      return '/sven_admin';
    case 'enterprise_admin':
      return '/qadmin';
    case 'susi':
      return '/susi';
    case 'audio_interpreter':
      return '/audio';
    case 'quality_manager':
      return '/qmclient';
    case 'loapp_admin':
      return '/languageops';
    case 'csr':
      return '/cws';
    case 'alsa':
      return '/alsa';
    case 'client':
      return null;
    default:
      // Logic copied from webclient:
      // I have no idea why we look at groups instead of type here,
      // but I'm sticking with it for now.
      if (user.groups.some((x) => x.name === 'interpreter')) {
        return '/iws';
      }

      return null;
  }
}

export type AuthCheckResult =
  | { type: 'initial' }
  | { type: 'fetching' }
  | { type: 'profile_not_found' }
  | { type: 'authorized'; profile: AuthProfile }
  | { type: 'forbidden'; redirectUrl: string | null }
  | { type: 'failed'; message: string };

type AuthCheckContextType = {
  profile: AuthCheckResult;
  fetchProfile: () => void;
};

const AuthCheckContext = React.createContext<AuthCheckContextType | undefined>(
  undefined
);

export function AuthCheckProvider({ children }: { children: React.ReactNode }) {
  let [profile, setProfile] = React.useState<AuthCheckResult>({
    type: 'initial',
  });

  React.useEffect(() => {
    async function run(authContext: AuthCheckResult) {
      // This overly complicated.  The problem is that a lot of the profile
      // fields only exist for the client user type.  We could make them
      // optional, but then we'd have to do a bunch of testing when we
      // want to use those fields.  Instead, we try to parse as the full
      // schema.  If that fails, then we try to parse only a partial user
      // schema, so we know the type and groups.
      if (authContext.type === 'fetching') {
        try {
          const profileJson = await backend.fetchAuthProfile();
          const authProfile = authProfileSchema.safeParse(profileJson);

          if (authProfile.success) {
            const profile = authProfile.data;

            if (profile.user.type !== 'client') {
              const redirectUrl = getRedirectUrl(profile.user);
              setProfile({ type: 'forbidden', redirectUrl });
              return;
            }

            setProfile({ type: 'authorized', profile: authProfile.data });
          } else {
            const profile = genericAuthProfileSchema.parse(profileJson);
            const redirectUrl = getRedirectUrl(profile.user);

            if (profile.user.type !== 'client') {
              setProfile({ type: 'forbidden', redirectUrl });
              return;
            }

            noticeError(authProfile.error, { url: '/api/me/view' });

            setProfile({
              type: 'failed',
              message: messages.BACKEND_MISMATCH,
            });
          }
        } catch (err) {
          if (err instanceof HTTPError && err.response.status === 403) {
            setProfile({ type: 'profile_not_found' });
            return;
          }
          const message = svenErrorMessage(err);
          setProfile({ type: 'failed', message });
        }
      }
    }

    run(profile);
  }, [profile]);

  const fetchProfile = React.useCallback(() => {
    setProfile({ type: 'fetching' });
  }, []);

  return (
    <AuthCheckContext.Provider value={{ profile, fetchProfile }}>
      {children}
    </AuthCheckContext.Provider>
  );
}

export function useAuthCheck() {
  const auth = React.useContext(AuthCheckContext);

  if (auth === undefined) {
    throw new Error('useAuth must be used in the context of an AuthProvider');
  }

  return auth;
}
