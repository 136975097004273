/**
 * Copyright © 2021, AMN Healthcare, Inc. All rights reserved.
 */

import { DateTime } from 'luxon';

export function formatDayFriendly(date: Date) {
  return DateTime.fromJSDate(date).toFormat('ccc LLL d');
}

export function formatLongNoMinutes(date: Date) {
  return DateTime.fromJSDate(date).toFormat('LLL d, h a');
}

export function formatLongFriendly(date: Date) {
  return DateTime.fromJSDate(date).toFormat('LLL d, h:mm a');
}

export function formatAllFriendly(date: Date) {
  return DateTime.fromJSDate(date).toFormat('cccc, MMM d, h:mm a');
}
