/**
 * Copyright © 2021, AMN Healthcare, Inc. All rights reserved.
 */

type AlertMessageProps = { title?: string; children: React.ReactNode };

export function AlertMessage({ children, title }: AlertMessageProps) {
  return (
    <div
      className="border border-l-4 border-orange-200 border-l-orange-500 bg-orange-100 p-4 text-orange-700"
      role="alert"
    >
      {title && <p className="font-bold">{title}</p>}
      {children}
    </div>
  );
}
