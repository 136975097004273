/**
 * Copyright © 2021, AMN Healthcare, Inc. All rights reserved.
 */

import { AlertMessage } from '@/ui/alert-message';
import { type LooseAutocomplete } from '@/types/helpers';

type ErrorName = LooseAutocomplete<
  | 'NotAllowedError'
  | 'NotFoundError'
  | 'DevicesNotFoundError'
  | 'OverconstrainedError'
  | 'ConstraintNotSatisfiedError'
>;

type DeviceAlertProps = {
  errorName: ErrorName;
  deviceName: string;
  title: string;
};

export function DeviceAlert({
  errorName,
  deviceName,
  title,
}: DeviceAlertProps) {
  let cameraErrorMessage: React.ReactNode | undefined = undefined;
  switch (errorName) {
    case 'NotAllowedError':
      cameraErrorMessage = (
        <span>
          Please <span className="font-medium">allow</span> your browser access
          to your {deviceName} and then hit refresh.
        </span>
      );
      break;
    case 'NotFoundError':
    case 'DevicesNotFoundError':
      cameraErrorMessage = (
        <span>
          A {deviceName} could not be detected. Please try a new camera and hit
          refresh.
        </span>
      );
      break;
    case 'OverconstrainedError':
    case 'ConstraintNotSatisfiedError':
      cameraErrorMessage = (
        <span>
          A {deviceName} is either already in use or does not match the required
          constraints. Please refresh and try again.
        </span>
      );
      break;
    default:
      console.error('No Device Access', errorName);
      cameraErrorMessage = <span>Please refresh and try again.</span>;
      break;
  }

  if (!cameraErrorMessage) {
    return null;
  }

  return (
    <AlertMessage title={title}>
      <p>{cameraErrorMessage}</p>
    </AlertMessage>
  );
}
