/**
 * Copyright © 2021, AMN Healthcare, Inc. All rights reserved.
 */

import React from 'react';
import { CallStateContext } from '../../ProtectedPage';
import { Button, type ButtonProps } from '@/ui/button';
import { useSelector } from '@xstate/react';
import { CallSessionContext } from '../../../machines/callStateMachine';

type LeaveSessionButtonProps = ButtonProps & {};

type EnhancedButtonProps = LeaveSessionButtonProps & {
  machineRef: NonNullable<CallSessionContext['cancelMachineRef']>;
};
function EnhancedButton({ machineRef, ...props }: EnhancedButtonProps) {
  const disabled = useSelector(machineRef, (state) => !state.matches('idle'));

  const leaveSession = useLeaveSession();

  return (
    <Button
      {...props}
      isDisabled={disabled}
      onPress={leaveSession}
      type="button"
    />
  );
}

// if confirm text is presence, the dialog is open, confirm text
// gets removed, then the dialog closes w/o anything being clicked
export function LeaveSessionButton(
  props: Omit<LeaveSessionButtonProps, 'onClick' | 'onPress'>
) {
  const cancelMachineRef = CallStateContext.useSelector((state) => {
    if (!state.can({ type: 'session.leaveSession' })) {
      return undefined;
    }

    return state.context.cancelMachineRef;
  });

  if (cancelMachineRef === undefined) {
    return <Button {...props} isDisabled={true} type="button" />;
  }

  return <EnhancedButton {...props} machineRef={cancelMachineRef} />;
}

export function useLeaveSession() {
  const { send } = CallStateContext.useActorRef();

  const leaveSession = React.useCallback(() => {
    send({ type: 'session.leaveSession' });
  }, [send]);

  return leaveSession;
}
