/**
 * Copyright © 2021, AMN Healthcare, Inc. All rights reserved.
 */

import React from 'react';
import { nanoid } from 'nanoid';
import { useEvent } from 'react-use-event-hook';

const PING = 'ping';
const PONG = 'pong';

type TablockPayload = { id: string };
type TabLockMessage = { type: string; payload: TablockPayload };

function ping(data: TablockPayload) {
  return { type: PING, payload: data };
}

function pong(data: TablockPayload) {
  return { type: PONG, payload: data };
}

export type TabLockResult = boolean;

let tabId = nanoid();
let channelName = 'ai:tablock';

// Note this hook is optimistic and will return not blocked until
// we receive a message from another tab confirming we are blocked.
export function useTabLock(): TabLockResult {
  const [isBlocked, setIsBlocked] = React.useState<TabLockResult>(false);

  const handleMessage = useEvent(
    (channel: BroadcastChannel, event: MessageEvent<any>) => {
      const data = event.data;

      if (data && data.type && data.payload && data.payload.id) {
        const { type, payload } = data as TabLockMessage;
        const { id } = payload;

        if (type === PING && !isBlocked) {
          // if we receive a pong in response to our request then
          // there is an active tab and block us
          channel.postMessage(pong(payload));
        } else if (
          // if we receive a pong in response to our request then
          // there is an active tab and so block us
          type === PONG &&
          !isBlocked &&
          id === tabId
        ) {
          setIsBlocked(true);
        }
      }
    }
  );

  React.useEffect(() => {
    const controller = new AbortController();

    const channel = new BroadcastChannel(channelName);

    channel.addEventListener('message', (e) => handleMessage(channel, e), {
      signal: controller.signal,
    });

    channel.postMessage(ping({ id: tabId }));

    return () => {
      controller.abort();
      channel.close();
    };
  }, [handleMessage]);

  return isBlocked;
}
