/**
 * Copyright © 2021, AMN Healthcare, Inc. All rights reserved.
 */

import { SessionLayout } from './SessionLayout';
import { ErrorBoundary } from 'react-error-boundary';
import { logBoundaryError } from '@/utils/logBoundaryError';
import { useInteropPlatform } from '@/protected/Interop/InteropPlatformProvider';
import { LeaveSessionButton } from './ui/LeaveSessionButton';
import { SessionSidebarLayout } from './SessionSidebarLayout';
import { AlertMessage } from '@/ui/alert-message';

function VideoContainerErrorFallback() {
  return (
    <SessionSidebarLayout
      title="Video platform error"
      sidebar={<LeaveSessionButton>Leave session</LeaveSessionButton>}
    >
      <AlertMessage title="Unexpected error">
        A video platform error has ocurred. Refresh to try again or leave the
        session.
      </AlertMessage>
    </SessionSidebarLayout>
  );
}

function VideoContainer() {
  const { components: Interop } = useInteropPlatform();

  return (
    <ErrorBoundary
      FallbackComponent={VideoContainerErrorFallback}
      onError={logBoundaryError}
    >
      <Interop.VideoContainer />
    </ErrorBoundary>
  );
}

export function SessionAttachedParty() {
  return (
    <SessionLayout fullScreen>
      <div className="grid h-full w-full auto-cols-[1fr] grid-rows-[1fr_42px] gap-1 pt-1">
        <VideoContainer />
      </div>
    </SessionLayout>
  );
}
