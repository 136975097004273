/**
 * Copyright © 2021, AMN Healthcare, Inc. All rights reserved.
 */

import React from 'react';

export type AudioStreamResult =
  | { type: 'pending' }
  | { type: 'success'; stream: MediaStream }
  | { type: 'failure'; error: Error };

export const useAudioStream: (
  onError?: (error: Error) => void
) => AudioStreamResult = (onError) => {
  const [stream, setStream] = React.useState<AudioStreamResult>({
    type: 'pending',
  });
  const errorCallback = React.useRef<(error: Error) => void>();

  React.useEffect(() => {
    errorCallback.current = onError;
  }, [onError]);

  React.useEffect(() => {
    let active = true;
    let localStream: MediaStream | null;

    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((mediaStream) => {
        localStream = mediaStream;

        if (active) {
          setStream({ type: 'success', stream: localStream });
        } else {
          localStream.getAudioTracks().forEach((track) => track.stop());
        }
      })
      .catch((error) => {
        if (errorCallback.current) {
          errorCallback.current(error);
        }

        if (active) {
          setStream({ type: 'failure', error: error });
        }
      });

    return () => {
      active = false;

      if (localStream) {
        localStream.getAudioTracks().forEach((track) => track.stop());
      }
    };
  }, []);

  return stream;
};
