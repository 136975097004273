/**
 * Copyright © 2021, AMN Healthcare, Inc. All rights reserved.
 */

import { z } from 'zod';

export const authProfileUserSchema = z.object({
  groups: z.array(z.object({ name: z.string() })),
  username: z.string(),
  type: z.string(),
  first_name: z.string().nullable(),
  last_name: z.string().nullable(),
  can_initiate_pstn: z.boolean().optional(),
  api_url: z.string(),
  app_version: z.number().optional(),
  stun_servers: z.array(z.string()),
  media_channels: z.object({
    default: z.string(),
    CAFEX: z.object({
      gateway: z.string(),
      port: z.number(),
      is_secure: z.boolean(),
    }),
  }),
  queueable: z
    .object({
      display_text: z.string().nullable(),
      display_subtext: z.string().nullable(),
      widescreen_enabled: z.boolean(),
      is_mentor: z.boolean(),
      is_audio_only: z.boolean().optional(),
    })
    .optional(),
});

export const authProfileEnterpriseSchema = z.object({
  id: z.number(),
  parent_enterprise: z.number().nullable(),
  enterprise_type: z.number(),
  name: z.string(),
  address_line_1: z.string().nullable(),
  address_line_2: z.string().nullable(),
  city: z.string().nullable(),
  state: z.string().nullable(),
  zip_code: z.string().nullable(),
  country: z.number().nullable(),
  time_zone: z.string().nullable(),
  website: z.string().nullable(),
  profile_picture: z.string().nullable(),
  fav_icon: z.string().nullable(),
  active: z.boolean(),
  opi_number: z.string().nullable(),
  assigned_opi_number: z.string().nullable(),
  provider_station_url: z.string().nullable(),
  question_prompt: z.number(),
  feedback_soft_timeout: z.number().nullable(),
  support_number: z.string().nullable(),
  support_details: z.string().nullable(),
  training_video_url: z.string().nullable(),
  preferred_video_resolution: z
    .object({
      id: z.number(),
      name: z.string(),
      order: z.number(),
      width: z.number(),
      height: z.number(),
      alternatives: z.array(
        z.object({
          id: z.number(),
          name: z.string(),
          order: z.number(),
          width: z.number(),
          height: z.number(),
        })
      ),
    })
    .nullable(),
  must_choose_subaccount: z.boolean(),
});

export const linkSchema = z.object({
  url: z.string(),
  name: z.string(),
});

export const linkSetSchema = z.object({
  links: z.array(linkSchema),
});

export const authProfileSchema = z.object({
  enterprise: authProfileEnterpriseSchema,
  user: authProfileUserSchema,
  vic_link_set: linkSetSchema.optional(),
  opi_config: z.object({ enabled: z.boolean(), label: z.string() }),
  speedtest_url: z.string().nullable(),
  login_enterprise: z
    .object({
      id: z.number(),
      parent_enterprise: z.number().nullable(),
      name: z.string(),
      active: z.boolean(),
      must_choose_subaccount: z.boolean(),
    })
    .nullable(),
});

export const partialAuthProfileUserSchema = z.object({
  groups: z.array(z.object({ name: z.string() })),
  username: z.string(),
  type: z.string(),
  first_name: z.string().nullable(),
  last_name: z.string().nullable(),
});

export const genericAuthProfileSchema = z.object({
  user: partialAuthProfileUserSchema,
});

export type AuthProfile = z.infer<typeof authProfileSchema>;
export type GenericAuthProfile = z.infer<typeof genericAuthProfileSchema>;
