/**
 * Copyright © 2021, AMN Healthcare, Inc. All rights reserved.
 */

let cached: boolean | undefined = undefined;

function isEnabled() {
  if (cached === undefined) {
    return (cached = window.localStorage.getItem('debugSven') === 'on');
  }

  return cached;
}

type LogArgs = Parameters<typeof console.log>;
export function debugLog(...args: LogArgs) {
  if (isEnabled()) {
    console.log('[AI Web Debug]', ...args);
  }
}
