/**
 * Copyright © 2021, AMN Healthcare, Inc. All rights reserved.
 */

import React from 'react';

export type AssertiveState = {
  message: string;
};

type AriaAssertiveState = {
  contents: AssertiveState | null;
};

type AriaAssertiveAction =
  | { type: 'assert'; contents: AssertiveState }
  | { type: 'clear' };

function assertiveReducer(
  state: AriaAssertiveState,
  action: AriaAssertiveAction
): AriaAssertiveState {
  switch (action.type) {
    case 'assert':
      return {
        ...state,
        contents: action.contents,
      };
    case 'clear':
      return {
        ...state,
        contents: null,
      };
    default:
      return state;
  }
}

type AriaAssertiveContextValue = [
  AriaAssertiveState,
  React.Dispatch<AriaAssertiveAction>,
];

let AriaAssertiveContext = React.createContext<
  AriaAssertiveContextValue | undefined
>(undefined);

// Helper to figure out what platform is in use
function useNotifyAriaAssertive() {
  const context = React.useContext(AriaAssertiveContext);

  if (context === undefined) {
    throw new Error(
      'useAriaAssertive must be used within a SessionDialogProvider'
    );
  }

  return context[1];
}

type AriaAssertiveProviderProps = {
  children: React.ReactNode;
};

function AriaAssertiveProvider({ children }: AriaAssertiveProviderProps) {
  const [state, dispatch] = React.useReducer(assertiveReducer, {
    contents: null,
  });

  const value = React.useMemo((): AriaAssertiveContextValue => {
    return [state, dispatch];
  }, [state, dispatch]);

  React.useEffect(() => {
    let active = true;

    const timeout = setTimeout(() => {
      if (active) {
        dispatch({ type: 'clear' });
      }
    }, 2_500);

    return () => {
      active = false;

      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, []);

  const message = state.contents ? state.contents.message : null;

  return (
    <AriaAssertiveContext.Provider value={value}>
      {children}

      <span aria-live="assertive" aria-atomic="true" className="sr-only">
        {message}
      </span>
    </AriaAssertiveContext.Provider>
  );
}

export { useNotifyAriaAssertive, AriaAssertiveProvider };
export type { AriaAssertiveProviderProps };
