/**
 * Copyright © 2021, AMN Healthcare, Inc. All rights reserved.
 */

import { z } from 'zod';

export const svenBrandingSchema = z.discriminatedUnion('type', [
  z.object({
    type: z.literal('Label'),
    value: z.object({
      content: z.string(),
    }),
  }),
  z.object({
    type: z.literal('Image'),
    value: z.object({
      small: z.string(),
      medium: z.string(),
      large: z.string(),
    }),
  }),
]);

export const svenBrandingRecordSchema = z.record(svenBrandingSchema);

export type SvenBranding = z.infer<typeof svenBrandingSchema>;
export type SvenBrandingRecord = z.infer<typeof svenBrandingRecordSchema>;
