/**
 * Copyright © 2021, AMN Healthcare, Inc. All rights reserved.
 */

import { nanoid } from 'nanoid';

export const messages = {
  INVALID_LOGIN: 'Invalid username or password.',
  UNEXPECTED_ERROR: 'Unable to process your request.',
  AUTHENTICATION_ERROR: 'An error occurred while trying to authenticate.',
  BACKEND_ERROR: 'A platform server had an error with your request.',
  ERRORS_HEADER: 'The following errors occurred',
  UNAUTHENTICATED: 'You are no longer authenticated',
  UNREACHABLE:
    'Unable to reach the server.  Please check your network connection.',
  TIMEOUT: 'Your request has timed out.',
  BACKEND_MISMATCH:
    'Unable to process your request from the server.  Please contact support.',
  FORBIDDEN:
    'Unable to process your request.  Your user session may have expired or you do not have access to make this request.',
  NOT_AUTHORIZED: 'Not Authorized',
};

export const DEFAULT_FRAMERATE = 30;
export const INTERNAL_APP_NAME = 'webclient';

export const SupportPhoneNumber = '+18556631231';

export const StorageKeys = {
  // From AI Web 1.0
  preferredVideoResolution: 'settings.preferredVideoResolution',
};

export const contextId = nanoid();

export const hrefBase = '/client';
