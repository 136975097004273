/**
 * Copyright © 2021, AMN Healthcare, Inc. All rights reserved.
 */

import React from 'react';
import { CallStateContext } from '../ProtectedPage';
import { useMyEnterpriseLanguages } from '@/protected/apis/protectedApiQueries';

export function useEnqueuedLanguage() {
  const { data: languages } = useMyEnterpriseLanguages();

  const request = CallStateContext.useSelector((s) => {
    return s.context.enqueueRequest;
  });

  const language = React.useMemo(() => {
    if (!request) return null;

    if (request.type === 'audio') {
      return 'Audio';
    }

    if (request.type === 'video') {
      const match = languages?.find(
        (language) => language.pk === request.buttonId
      );
      if (match) {
        return match.display_text;
      }
    }

    return null;
  }, [languages, request]);

  return language;
}
