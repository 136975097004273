/**
 * Copyright © 2021, AMN Healthcare, Inc. All rights reserved.
 */

import React from 'react';
import type { AuthProfile } from '@/auth/authApiSchemas';
import { useNavigate } from 'react-router-dom';
import { useAuthProfile } from './AuthProfileContext';
import { toast } from 'sonner';

type AccountSelection =
  | { type: 'idle' }
  | { type: 'user-wants-change' }
  | { type: 'system-wants-change' };

type State = AccountSelection;
type Action = { type: 'user-wants-change' } | { type: 'system-wants-change' };

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'system-wants-change':
      return { type: 'system-wants-change' };
    case 'user-wants-change':
      if (state.type === 'system-wants-change') {
        return { type: 'system-wants-change' };
      }

      return { type: 'user-wants-change' };
    default:
      throw new Error('account selection action not suported');
  }
}

const AccountSelectionContext = React.createContext<
  AccountSelection | undefined
>(undefined);

function isSelectionRequired(profile: AuthProfile) {
  return (
    profile.login_enterprise &&
    profile.login_enterprise.must_choose_subaccount &&
    profile.enterprise.id === profile.login_enterprise.id
  );
}

function initializer(profile: AuthProfile): State {
  if (isSelectionRequired(profile)) {
    return { type: 'system-wants-change' };
  }

  return { type: 'idle' };
}

export function AccountSelectionProvider({
  children,
}: React.PropsWithChildren) {
  const profile = useAuthProfile();
  const [state, dispatch] = React.useReducer(reducer, profile, initializer);

  if (isSelectionRequired(profile) && state.type !== 'system-wants-change') {
    dispatch({ type: 'system-wants-change' });
  }

  const navigate = useNavigate();

  const requiresRedirect =
    state.type === 'user-wants-change' || state.type === 'system-wants-change';

  React.useEffect(() => {
    if (requiresRedirect) {
      navigate('/accounts/select');

      toast.error('Account selection required', {
        description: 'Please select an account',
      });
    }
  }, [navigate, requiresRedirect]);

  return (
    <>
      <AccountSelectionContext.Provider value={state}>
        {children}
      </AccountSelectionContext.Provider>
    </>
  );
}

export function useAccountSelection() {
  const auth = React.useContext(AccountSelectionContext);

  if (auth === undefined) {
    throw new Error(
      'useAccountSelection must be used in the context of an AccountSelectionProvider'
    );
  }

  return auth;
}
