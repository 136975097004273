/**
 * Copyright © 2021, AMN Healthcare, Inc. All rights reserved.
 */

import React from 'react';
import * as ContainerLayout from '@/shared/ContainerLayout';
import { useQuery } from '@tanstack/react-query';
import { fetchRecentSessions } from './apis/protectedApiFetchers';
import { DateTime } from 'luxon';
import {
  Row,
  Column,
  Cell,
  Table,
  TableBody,
  TableHeader,
} from '@/ui/simple-table';
import { SelectField, SelectFieldItem } from '@/ui/fields/select-field';
import { type RecentSession } from './apis/protectedApiSchemas';
import { AlertMessage } from '@/ui/alert-message';
import { svenErrorMessage } from '@/api/getError';
import * as R from 'remeda';
import { formatDayFriendly, formatLongFriendly } from '@/utils/date-formatter';
import { Key } from 'react-aria';

type SessionRow = RecentSession & { start: string; end: string; day: string };
type Option = { id: Key; name: string };

const All: Option = { id: '', name: 'All' };

export function History() {
  const result = useQuery({
    queryKey: ['tiers'],
    queryFn: fetchRecentSessions,
    retry: 1,
    staleTime: Infinity,
  });

  const [selectedDay, setSelectedDay] = React.useState<Key>('');
  const [selectedLanguage, setSelectedLanguage] = React.useState<Key>('');

  // Returns an array of
  // 1) enhanced session rows, with start and end date formatted, and a
  // day added (for filtering)
  // 2) day filtering options for filtering (uniq set of all days)
  // 3) language filtering options for filtering (uniq set of all languages)
  const [rows, days, languages] = React.useMemo((): [
    SessionRow[],
    Option[],
    Option[],
  ] => {
    if (result.status !== 'success') {
      return [[], [All], [All]];
    }

    let rows: SessionRow[] = [];
    let seenLanguages = new Set<string>();
    let seenDays = new Set<number>();

    result.data.results.forEach((row) => {
      const start = new Date(row.start_timestamp);
      const end = row.end_timestamp ? new Date(row.end_timestamp) : null;

      seenLanguages.add(row.language);

      const startOfDay = DateTime.fromJSDate(start).startOf('day').toJSDate();
      seenDays.add(startOfDay.getTime());

      rows.push({
        ...row,
        day: formatDayFriendly(start),
        start: formatLongFriendly(start),
        end: end === null ? '' : formatLongFriendly(end),
      });
    });

    const languages = R.pipe(
      [...seenLanguages],
      R.sort((a, b) => a.localeCompare(b)),
      R.map((l) => ({ id: l, name: l }) as Option)
    );

    const days = R.pipe(
      [...seenDays],
      R.sort((a, b) => a - b),
      R.map((seenDay) => {
        const day = formatDayFriendly(new Date(seenDay));
        return { id: day, name: day } as Option;
      })
    );

    return [rows, [All, ...days], [All, ...languages]];
  }, [result]);

  // Filters the returned session rows based on the applied language and day selections
  const filteredRows = React.useMemo(() => {
    return rows.filter((row) => {
      return (
        (selectedLanguage !== '' ? selectedLanguage === row.language : true) &&
        (selectedDay !== '' ? selectedDay === row.day : true)
      );
    });
  }, [rows, selectedDay, selectedLanguage]);

  return (
    <ContainerLayout.Root aria-label="Session history">
      <ContainerLayout.Header>Session history</ContainerLayout.Header>
      {result.status === 'error' && (
        <AlertMessage title="Unable to fetch session history">
          {svenErrorMessage(result.error)}
        </AlertMessage>
      )}
      <div className="flex space-x-6 rounded-md border border-gray-200 bg-gray-100 p-2">
        <SelectField
          inline
          className="w-48"
          name="day"
          label="Day:"
          isRequired={false}
          placeholder="Select a day"
          errorMessage={undefined}
          items={days}
          onSelectionChange={(val) => {
            setSelectedDay(val);
          }}
          selectedKey={selectedDay}
        >
          {(item) => <SelectFieldItem>{item.name}</SelectFieldItem>}
        </SelectField>

        <SelectField
          inline
          className="w-72"
          name="language"
          label="Language:"
          isRequired={false}
          placeholder="Select a language"
          errorMessage={undefined}
          items={languages}
          onSelectionChange={(val) => {
            setSelectedLanguage(val);
          }}
          selectedKey={selectedLanguage}
        >
          {(item) => <SelectFieldItem>{item.name}</SelectFieldItem>}
        </SelectField>
      </div>

      <Table aria-label="Session history" className="w-full shadow">
        <TableHeader>
          <Column id="symbol">Type</Column>
          <Column id="session" isRowHeader>
            Session
          </Column>
          <Column id="start">Start</Column>
          <Column id="end">End</Column>
          <Column id="language">Language</Column>
          <Column id="agent">Agent</Column>
          <Column id="duration">Duration</Column>
        </TableHeader>
        <TableBody
          items={filteredRows}
          renderEmptyState={() =>
            result.status === 'pending' ? 'Loading' : 'No results found.'
          }
          className={(f) =>
            f.isEmpty ? '[&>tr>td]:px-4 [&>tr>td]:py-2 [&>tr>td]:italic' : ''
          }
        >
          {(item) => (
            <Row>
              <Cell>{item.is_video ? 'Video' : 'Audio'}</Cell>
              <Cell className="font-semibold">{item.id}</Cell>
              <Cell>{item.start}</Cell>
              <Cell>{item.end}</Cell>
              <Cell>{item.language}</Cell>
              <Cell>{item.agent}</Cell>
              <Cell>{item.duration}</Cell>
            </Row>
          )}
        </TableBody>
      </Table>
    </ContainerLayout.Root>
  );
}
