/**
 * Copyright © 2021, AMN Healthcare, Inc. All rights reserved.
 */

import React from 'react';
import {
  useNavigate,
  Navigate,
  Outlet,
  useLocation,
  useHref,
} from 'react-router-dom';
import { useAuthCheck } from '@/auth/AuthCheckContext';
import { AuthProfileProvider } from '@/protected/AuthProfileContext';
import { useEvent } from 'react-use-event-hook';
import { AuthCardLayout } from '@/auth/AuthCardLayout';
import { Button } from '@/ui/button';

function Forbidden() {
  const navigate = useNavigate();

  const logout = () => {
    navigate('/logout');
  };

  return (
    <AuthCardLayout header="Access Forbidden">
      <p className="text-muted-foreground">
        Your user does not have access to this application.
      </p>
      <p className="text-muted-foreground">
        If you feel you've received this message in error then please contact
        customer support.
      </p>
      <div>
        <Button onPress={logout} type="button">
          Logout
        </Button>
      </div>
    </AuthCardLayout>
  );
}

type RedirectProps = { to: string };
function RedirectWithMessage({ to }: RedirectProps) {
  React.useEffect(() => {
    window.location.assign(to);
  }, [to]);

  return (
    <AuthCardLayout
      header={<span className="loading-ellipsis">Redirecting</span>}
    >
      <p className="text-muted-foreground">
        Please wait while we redirect you to the right place.
      </p>
    </AuthCardLayout>
  );
}

// Requires the user be authenticated to proceed.
// Serves the Outlet upon success, wrapped in an profile provider.
export function RequireAuth() {
  let { profile, fetchProfile } = useAuthCheck();

  let location = useLocation();
  const toLogin = useHref('/public/login');
  const toLogout = useHref('/logout');

  const login = () => {
    window.location.assign(toLogin);
  };

  const logout = () => {
    window.location.assign(toLogout);
  };

  const checkAuth = useEvent(() => {
    // Check to see if the user is authenticated on mount.
    //
    // Note:
    // If the user navigates to the login while having an active session,
    // then CheckAuth will make an API request to check the profile.
    // it will then redirect here to make another api request.  We
    // avoid the double check with the following bail condition.

    if (
      (profile.type === 'authorized' || profile.type === 'forbidden') &&
      location.state?.skipCheck === true
    ) {
      return;
    }

    fetchProfile();
  });

  React.useEffect(() => {
    checkAuth();
  }, [checkAuth]);

  if (profile.type === 'profile_not_found') {
    return (
      <Navigate
        to="/public/login"
        state={{ ...location.state, from: location }}
        replace
      />
    );
  }

  if (profile.type === 'failed') {
    return (
      <AuthCardLayout header="Failed to load profile">
        <p className="text-muted-foreground">
          We were unable to load your user profile.
        </p>
        <p className="text-red-600">{profile.message}</p>
        <p className="space-x-2 pt-2">
          <Button onPress={login} type="button">
            Login
          </Button>
          <Button onPress={logout} type="button" variant="outline">
            Logout
          </Button>
        </p>
      </AuthCardLayout>
    );
  }

  // found the profile, so render the outlet (or requested page)
  if (profile.type === 'authorized') {
    return (
      <AuthProfileProvider profile={profile.profile}>
        <Outlet />
      </AuthProfileProvider>
    );
  }

  // found a profile, but the user doesn't have access to ai web
  if (profile.type === 'forbidden') {
    return profile.redirectUrl ? (
      <RedirectWithMessage to={profile.redirectUrl} />
    ) : (
      <Forbidden />
    );
  }

  return (
    <AuthCardLayout
      header={<span className="loading-ellipsis">Loading profile</span>}
    >
      <p>Please wait while we try to load your user profile.</p>
    </AuthCardLayout>
  );
}

// Checks that a user is authenticated.
// If successful, then redirect to the protected page root.
// If not found, then serve the Outlet (which is typically the Login page)
export function CheckAuth() {
  let { profile, fetchProfile } = useAuthCheck();

  let location = useLocation();

  const toLogin = useHref('/public/login');
  const toLogout = useHref('/logout');

  const login = () => {
    window.location.assign(toLogin);
  };

  const logout = () => {
    window.location.assign(toLogout);
  };

  React.useEffect(() => {
    // Always check the user's current auth state when they navigate
    // to the login screen.
    fetchProfile();
  }, [fetchProfile]);

  if (profile.type === 'profile_not_found') {
    return <Outlet />;
  }

  if (profile.type === 'failed') {
    return (
      <AuthCardLayout header="Failed to load profile">
        <p className="text-muted-foreground">
          We were unable to load your user profile.
        </p>
        <p className="text-red-600">{profile.message}</p>
        <p className="space-x-2 pt-2">
          <Button onPress={login} type="button">
            Login
          </Button>
          <Button onPress={logout} type="button" variant="outline">
            Logout
          </Button>
        </p>
      </AuthCardLayout>
    );
  }

  // found the profile, so redirect to the dashboard (or requested page)
  if (profile.type === 'authorized') {
    return (
      <Navigate
        to="/consumer"
        state={{ from: location, skipCheck: true }}
        replace
      />
    );
  }

  // found a profile, but the user doesn't have access to ai web
  if (profile.type === 'forbidden') {
    return profile.redirectUrl ? (
      <RedirectWithMessage to={profile.redirectUrl} />
    ) : (
      <Forbidden />
    );
  }

  return (
    <AuthCardLayout
      header={<span className="loading-ellipsis">Loading profile</span>}
    >
      <p>Please wait while we try to load your user profile.</p>
    </AuthCardLayout>
  );
}
