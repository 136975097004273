/**
 * Copyright © 2021, AMN Healthcare, Inc. All rights reserved.
 */

import React from 'react';
import { Outlet, useMatches } from 'react-router-dom';
import type { RouteHandle } from './index';
import { Toaster } from 'sonner';
import { ErrorBoundary } from 'react-error-boundary';
import { logBoundaryError } from '@/utils/logBoundaryError';
import { AlertMessage } from '@/ui/alert-message';
import amnLanguageServices from '@/assets/amn-language-services.png';

function AppFallback() {
  return (
    <div className="h-full overflow-auto bg-gray-200 p-4">
      <div className="container mx-auto space-y-3 border bg-white p-6">
        <h1 className="text-xl font-medium">
          <img
            src={amnLanguageServices}
            alt="AMN Interpreting"
            className="w-[210px]"
          />
        </h1>
        <h2 className="text-2xl font-medium">Unexpected error</h2>
        <AlertMessage title="An unexpected error has ocurred">
          <div>Please reload the app to continue.</div>
        </AlertMessage>
      </div>
    </div>
  );
}

export default function App() {
  const matches = useMatches();

  const titleSegments = matches.reduce((titles, match) => {
    const title = (match.handle as RouteHandle)?.title;
    if (title) {
      return [...titles, title];
    }

    return titles;
  }, [] as string[]);

  const title = titleSegments.join(' - ');

  React.useLayoutEffect(() => {
    document.title = title;
  }, [title]);

  return (
    <div className="absolute left-0 top-0 h-full w-full">
      <ErrorBoundary FallbackComponent={AppFallback} onError={logBoundaryError}>
        <Outlet />
        <Toaster
          duration={10_000}
          richColors
          closeButton
          position="bottom-right"
          theme="dark"
        />
      </ErrorBoundary>
    </div>
  );
}
