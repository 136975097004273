/**
 * Copyright © 2021, AMN Healthcare, Inc. All rights reserved.
 */

import amnLanguageServices from '@/assets/amn-language-services.png';
import { Button } from '@/ui/button';
import { useNavigate } from 'react-router-dom';

// We could make the button a link, but we don't have a component
// for that yet.  And since we don't allow for multiple tabs, we
// just made it a button.

export function PublicNotFound() {
  const navigate = useNavigate();

  const goHome = () => navigate('/');

  return (
    <div className="container mx-auto space-y-3 border bg-white p-6">
      <h1 className="text-xl font-medium">
        <img
          src={amnLanguageServices}
          alt="AMN Interpreting"
          className="w-[210px]"
        />
      </h1>
      <h2 className="text-2xl font-medium">Page not found</h2>
      <p className="text-muted-foreground">
        The page you are looking for could not be found.
      </p>
      <Button onPress={goHome} type="button">
        Go home
      </Button>
    </div>
  );
}
