/**
 * Copyright © 2021, AMN Healthcare, Inc. All rights reserved.
 */

import React from 'react';
import type { TextFieldProps } from 'react-aria-components';
import { TextField as AriaTextField, Input } from 'react-aria-components';
import { FieldLabel, FieldErrorMessage, FieldDescription } from './field';

type LabelProps = { isRequired?: boolean };

interface MyTextFieldProps extends TextFieldProps {
  label: string | ((props: LabelProps) => React.ReactNode);
  description?: string | null;
  errorMessage?: string;
  placeholder?: string;
}

const TextField = React.forwardRef<HTMLInputElement, MyTextFieldProps>(
  (
    {
      label,
      description,
      errorMessage,
      placeholder,
      isRequired,
      isInvalid,
      ...props
    },
    ref
  ) => {
    const Label = label;

    const isFieldInvalid = Boolean(errorMessage) || isInvalid;

    return (
      <AriaTextField
        {...props}
        isInvalid={isFieldInvalid}
        className="flex flex-col space-y-1"
      >
        {label instanceof Function ? (
          <Label isRequired={isRequired} />
        ) : (
          <FieldLabel isRequired={isRequired} text={label} />
        )}
        <Input
          ref={ref}
          placeholder={placeholder}
          className="flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm outline-none file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground disabled:cursor-not-allowed disabled:opacity-50 aria-[invalid]:border-input-invalid rac-hover:border-input-hover rac-hover:aria-[invalid]:border-input-invalidHover rac-focus:!border-input-focus rac-focus:outline-none rac-focus-visible:ring-1 rac-focus-visible:ring-input-focus rac-focus-visible:ring-offset-0"
        />
        <div className="flex flex-col space-y-0.5">
          {description && <FieldDescription>{description}</FieldDescription>}
          {errorMessage && (
            <FieldErrorMessage>{errorMessage}</FieldErrorMessage>
          )}
        </div>
      </AriaTextField>
    );
  }
);

TextField.displayName = 'TextField';

export { TextField };
