/**
 * Copyright © 2021, AMN Healthcare, Inc. All rights reserved.
 */

import React from 'react';

import { interval, animationFrameScheduler } from 'rxjs';
import { map, distinctUntilChanged, endWith, takeWhile } from 'rxjs/operators';
import { Interval } from 'luxon';

function differenceInSeconds(lhs: Date, rhs: Date) {
  return ~~Interval.fromDateTimes(rhs, lhs).length('seconds');
}

// FUTURE: find some third party library for this.
// or look into having a global clock that other components can tap into.

// Returns the seconds until expiration.  Stops at 0.
export function useCountdownSeconds(expiresAt: Date) {
  const [left, setLeft] = React.useState(() =>
    Math.max(0, differenceInSeconds(expiresAt, new Date()))
  );

  React.useEffect(() => {
    // tick every second, using request animation frame
    const countdown$ = interval(0, animationFrameScheduler).pipe(
      map(() => differenceInSeconds(expiresAt, new Date())),
      distinctUntilChanged(),
      takeWhile((whatsLeft) => whatsLeft > 0),
      // Ensure we always end with 0.  Helps take care of the browser
      // tab being suspended and resumed after expiration.
      endWith(0)
    );

    const sub = countdown$.subscribe((whatsLeft) => {
      setLeft(whatsLeft);
    });

    return () => {
      sub.unsubscribe();
    };
  }, [expiresAt]);

  return left;
}

export type CountdownSecondsProps = {
  expiresAt: Date;
  children: (secondsLeft: number) => React.ReactNode;
};
export function CountdownSeconds({
  expiresAt,
  children,
}: CountdownSecondsProps) {
  const secondsLeft = useCountdownSeconds(expiresAt);

  return children(secondsLeft);
}
