/**
 * Copyright © 2021, AMN Healthcare, Inc. All rights reserved.
 */

import React from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { BrandedAMNImage } from '@/shared/BrandedAMNImage';
import { TextField } from '@/ui/fields/text-field';
import { Button } from '@/ui/button';
import { AlertMessage, InfoMessage, SuccessMessage } from '@/ui/messages';
import { useForm, Controller } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import api from '@/api/api';
import { svenErrorMessage } from '@/api/getError';
import { z } from 'zod';
import { MdOutlineCircle, MdCheckCircleOutline } from 'react-icons/md';

type PasswordRequirements = {
  length: boolean;
  uppercase: boolean;
  lowercase: boolean;
  number: boolean;
  symbol: boolean;
};

const validatePassword = (
  value: string | undefined
): PasswordRequirements | undefined => {
  const pw = value || '';

  const result: PasswordRequirements = {
    length: pw.length >= 8,
    uppercase: /[A-Z]/g.test(pw),
    lowercase: /[a-z]/g.test(pw),
    number: /[0-9]/g.test(pw),
    symbol: /[$#@!*.?]/g.test(pw),
  };

  var valid = (Object.keys(result) as Array<keyof PasswordRequirements>).every(
    (key) => {
      return result[key] === true;
    }
  );

  return valid ? undefined : result;
};

const loginSchema = z
  .object({
    password: z.string().min(1, { message: 'Required' }),
    confirmPassword: z.string().min(1, { message: 'Required' }),
  })
  .superRefine(({ confirmPassword, password }, ctx) => {
    if (validatePassword(password)) {
      ctx.addIssue({
        code: 'custom',
        message: 'Password does not meet requirements',
        path: ['password'],
      });

      return;
    }

    if (confirmPassword !== password) {
      ctx.addIssue({
        code: 'custom',
        message: 'Passwords do not match',
        path: ['confirmPassword'],
      });
    }
  });

type FormInput = z.input<typeof loginSchema>;
type FormOutput = z.output<typeof loginSchema>;

type PasswordItemProps = {
  isMet: boolean;
  children: React.ReactNode;
};
function PasswordItem({ isMet, children }: PasswordItemProps) {
  return (
    <li
      aria-hidden={isMet}
      className="m-0 flex items-start gap-1.5 p-0 py-1 text-slate-700 aria-hidden:text-slate-400"
    >
      <div className="flex h-4 items-center">
        {isMet ? (
          <MdCheckCircleOutline size="0.5rem" aria-hidden />
        ) : (
          <MdOutlineCircle size="0.5rem" aria-hidden />
        )}
      </div>
      <div className="leading-4">{children}</div>
    </li>
  );
}

type ResetPasswordFormProps = {
  id: string;
  token: string;
  expires: string;
  forExpired: boolean;
};
export function ResetPasswordForm({
  id,
  token,
  expires,
  forExpired,
}: ResetPasswordFormProps) {
  const navigate = useNavigate();

  const login = () => {
    navigate('/public/login');
  };

  const [success, setSuccess] = React.useState(false);

  const { setError, handleSubmit, control, formState, watch } = useForm<
    FormInput,
    any,
    FormOutput
  >({
    shouldFocusError: true,
    resolver: zodResolver(loginSchema),
    defaultValues: {
      password: '',
      confirmPassword: '',
    },
  });

  const password = watch('password');
  const meetsPassword = validatePassword(password);

  const onSubmit = async (data: FormOutput) => {
    try {
      await api.put('/api/change_password', {
        json: {
          id: id,
          expires: expires,
          token: token,
          new_password: data.password,
          confirm_password: data.password,
        },
      });
      setSuccess(true);
    } catch (error) {
      const message = svenErrorMessage(error);
      setError('root', { type: 'server', message });
    }
  };

  if (success) {
    return (
      <div className="space-y-2">
        <SuccessMessage>Your password has been reset.</SuccessMessage>

        <div className="text-right">
          <Button type="button" onPress={login}>
            Login
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div className="space-y-2">
      <form
        className="mx-auto max-w-sm space-y-2"
        onSubmit={handleSubmit(onSubmit)}
      >
        {forExpired && (
          <InfoMessage>
            Your password has expired. Please reset your password.
          </InfoMessage>
        )}

        {formState.errors.root?.message && (
          <AlertMessage title="Failed to reset password">
            <p>{formState.errors.root?.message}</p>
          </AlertMessage>
        )}

        <div className="space-y-2">
          <div>
            <Controller
              render={({ field: { ...field }, fieldState: { error } }) => (
                <TextField
                  type="password"
                  isRequired
                  label="New password"
                  aria-labelledby="reset-password--requirements"
                  errorMessage={error?.message}
                  autoFocus
                  autoComplete="off"
                  id="doo"
                  description={
                    'Must not be one of your last five passwords or username.'
                  }
                  {...field}
                />
              )}
              control={control}
              name="password"
            />

            {meetsPassword === undefined ? (
              <div className="text-sm text-green-700">
                <MdCheckCircleOutline
                  size="0.75rem"
                  className="inline-block"
                  aria-hidden
                />{' '}
                Your password is secured and ready to be reset.
              </div>
            ) : (
              <ul
                id="reset-password--requirements"
                className="m-0 list-none columns-2 p-0 text-sm"
              >
                <PasswordItem isMet={meetsPassword.length}>
                  8 characters minimum
                </PasswordItem>
                <PasswordItem isMet={meetsPassword.number}>
                  One number
                </PasswordItem>
                <PasswordItem isMet={meetsPassword.uppercase}>
                  One uppercase character
                </PasswordItem>
                <PasswordItem isMet={meetsPassword.lowercase}>
                  One lowercase character
                </PasswordItem>
                <PasswordItem isMet={meetsPassword.symbol}>
                  One special character ($#@!*.?)
                </PasswordItem>
              </ul>
            )}
          </div>

          <Controller
            render={({ field: { ...field }, fieldState: { error } }) => (
              <TextField
                type="password"
                isRequired
                label="Confirm password"
                errorMessage={error?.message}
                autoComplete="off"
                {...field}
              />
            )}
            control={control}
            name="confirmPassword"
          />
        </div>
        <div className="text-right">
          <Button
            variant="secondary"
            isDisabled={formState.isSubmitting}
            type="submit"
          >
            Reset password
          </Button>
        </div>
      </form>
    </div>
  );
}

export function ResetPassword() {
  const [searchParams] = useSearchParams();

  const id = searchParams.get('id');
  const token = searchParams.get('token');
  const expires = searchParams.get('expires');
  const forExpired = searchParams.get('expired') != null;

  const navigate = useNavigate();

  const goHome = () => {
    navigate('/');
  };

  let result =
    id && token && expires ? (
      <ResetPasswordForm
        id={id}
        token={token}
        expires={expires}
        forExpired={forExpired}
      />
    ) : (
      <div className="space-y-2">
        <AlertMessage>
          <p>
            Please use the reset password link in your inbox to complete the
            password reset.
          </p>
        </AlertMessage>
        <div className="text-right">
          <Button type="button" onPress={goHome}>
            Go Home
          </Button>
        </div>
      </div>
    );

  return (
    <div>
      <div className="mx-auto max-w-sm space-y-2">
        <BrandedAMNImage className="mx-auto" />

        <h2 className="mx-auto text-lg font-medium text-slate-700">
          Change password
        </h2>

        {result}
      </div>
    </div>
  );
}
