/**
 * Copyright © 2021, AMN Healthcare, Inc. All rights reserved.
 */

import amnLanguageServices from '@/assets/amn-language-services.png';
import { BrandedImage, type BrandedImageProps } from '@/shared/BrandedImage';
import { cn } from '@/utils/cn';

type BrandedAMNImageProps = Omit<
  BrandedImageProps,
  'children' | 'src' | 'itemName' | 'itemSize' | 'alt'
>;

export function BrandedAMNImage(props: BrandedAMNImageProps) {
  return (
    <BrandedImage
      {...props}
      className={cn('w-[210px]', props.className)}
      src={amnLanguageServices}
      itemName="login_logo_top"
      itemSize="medium"
      alt="Logo"
    />
  );
}
