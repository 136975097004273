/**
 * Copyright © 2021, AMN Healthcare, Inc. All rights reserved.
 */

import { IVVRVideo } from './ui/IVVRVideo';
import { LeaveSessionButton } from './ui/LeaveSessionButton';
import { type Session } from '@/machines/callStateMachine';
import { SessionType } from '@/protected/apis/protectedApiSchemas';
import { InfoMessage } from '@/ui/info-message';
import { SessionSidebarLayout } from './SessionSidebarLayout';

function isRolloverSession({ sessionDetails: session }: Session) {
  return (
    (session.session_type === SessionType.CSR ||
      session.session_type === SessionType.AUDIOOUT) &&
    session.initial_session_type === SessionType.INTERPRETING
  );
}

type SessionAttachedIVVRProps = {
  session: Session;
};
export function SessionAttachedIVVR({ session }: SessionAttachedIVVRProps) {
  const ivvr = session.sessionDetails.current_ivvr;
  const isRollover = isRolloverSession(session);

  return (
    <SessionSidebarLayout
      title="Finding a match..."
      sidebar={<LeaveSessionButton>Cancel request</LeaveSessionButton>}
    >
      {isRollover && (
        <InfoMessage title="Switching to audio-only">
          No video interpreters are currently available for this language. We
          are redirecting your call to an audio-only interpreter.
        </InfoMessage>
      )}
      {ivvr && <IVVRVideo ivvr={ivvr} />}
    </SessionSidebarLayout>
  );
}
