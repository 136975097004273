/**
 * Copyright © 2021, AMN Healthcare, Inc. All rights reserved.
 */

import { CallStateContext } from '../ProtectedPage';
import { SessionSidebarLayout } from './SessionSidebarLayout';
import { InfoMessage } from '@/ui/info-message';
import { Button } from '@/ui/button';

export function SessionUnknown() {
  const { send } = CallStateContext.useActorRef();

  const message = CallStateContext.useSelector((s) => {
    if (s.matches({ Session: { some: { unknown: 'expired' } } })) {
      return 'Your session has expired.';
    }

    if (s.matches({ Session: { some: { unknown: 'different' } } })) {
      return 'Another session has been found.';
    }

    return 'An unexpected session event occurred.';
  });

  return (
    <SessionSidebarLayout
      title="Session message"
      sidebar={
        <Button onPress={() => send({ type: 'session.reload' })} type="button">
          Continue
        </Button>
      }
    >
      <InfoMessage>{message}</InfoMessage>
    </SessionSidebarLayout>
  );
}
