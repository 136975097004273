/**
 * Copyright © 2021, AMN Healthcare, Inc. All rights reserved.
 */

import React from 'react';
import { useQueryClient, useMutation } from '@tanstack/react-query';
import { Button } from '@/ui/button';
import { AlertMessage } from '@/ui/alert-message';
import { svenErrorMessage } from '@/api/getError';
import { useHref } from 'react-router-dom';
import { Switch } from '@/ui/switch';
import { removeFavorite, setFavorite } from './accountFetchers';
import { accountKey, useTierFavorites } from './accountQueries';
import { MdDeleteForever } from 'react-icons/md';

type SubmitStatus =
  | { type: 'idle' }
  | { type: 'pending' }
  | { type: 'success' }
  | { type: 'failure'; message: string };

export function SelectAccounts() {
  const result = useTierFavorites();

  const queryClient = useQueryClient();

  const removeMutation = useMutation({
    mutationFn: (id: number) => {
      return removeFavorite(id);
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: accountKey });
    },
  });

  const redirectTo = useHref('/');

  const [status, setStatus] = React.useState<SubmitStatus>({ type: 'idle' });
  const [managing, setManaging] = React.useState(false);

  const childAccounts = React.useMemo(() => {
    if (!result.data) return [];
    return result.data.child_accounts
      .slice()
      .sort((a, b) => a.name.localeCompare(b.name));
  }, [result.data]);

  const handleSelection = async (enterpriseId: number) => {
    setStatus({ type: 'pending' });

    try {
      await setFavorite(enterpriseId);

      setStatus({ type: 'success' });

      return;
    } catch (err) {
      const message = svenErrorMessage(err);
      setStatus({ type: 'failure', message: message });
    }
  };

  const handleRemove = async (id: number) => {
    removeMutation.mutate(id);
  };

  React.useEffect(() => {
    if (status.type === 'success') {
      // NOTE: it's possible not to assign and reload,
      // but we will play it safe.  otherwise, we
      // need to ensure all of our cached apis are cached
      // by enterprise id.
      window.location.assign(redirectTo);
    }
  }, [status, redirectTo]);

  if (result.status === 'pending' || result.isFetching) {
    return <div className="loading-ellipsis">Loading shortcuts</div>;
  }

  if (result.status === 'error') {
    return (
      <AlertMessage title="Unable to fetch shortcuts">
        <p>{svenErrorMessage(result.error)}</p>
      </AlertMessage>
    );
  }

  if (childAccounts.length === 0) {
    return (
      <div className="space-y-2">
        <p>
          To view the available languages and connect to an interpreter, you'll
          need to select an account shortcut first. Please select the account
          for your session today from the options provided. Once you've chosen
          the account, add your shortcut, and you'll be able to view and choose
          from the available languages.
        </p>

        <p>
          Do you work within multiple accounts regularly? No problem! You can
          create shortcuts for each account by choosing from the options
          provided and adding another account shortcut.
        </p>

        <p>
          Want to add more shortcuts later? Simply go to 'Change Account' in the
          hamburger menu, or click the account name on the languages screen to
          create a new shortcut.
        </p>
      </div>
    );
  }

  return (
    <div className="space-y-4">
      {status.type === 'failure' && (
        <AlertMessage title="Unable to select shortcut">
          <p>{status.message}</p>
        </AlertMessage>
      )}
      <ul className="max-w-lg space-y-2">
        {childAccounts.map((account) => (
          <li key={account.id}>
            <div className="flex items-center space-x-2">
              {/* TODO: use Button */}
              <button
                className="w-full flex-auto rounded-md border-2 border-violet-500 bg-violet-200 p-2 text-left opacity-100 outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-1 hover:enabled:bg-violet-500 hover:enabled:text-white disabled:cursor-not-allowed disabled:opacity-60"
                onClick={() => handleSelection(account.id)}
                disabled={
                  status.type === 'pending' ||
                  status.type === 'success' ||
                  managing
                }
              >
                {account.parents.map((name, idx) => (
                  <div
                    key={idx}
                    className="text-sm text-black/70 break-anywhere"
                  >
                    {name}
                  </div>
                ))}
                <div className="font-semibold break-anywhere">
                  {account.name}
                </div>
              </button>
              {managing && (
                <Button
                  className="flex-none"
                  variant="destructive"
                  onPress={() => {
                    handleRemove(account.id);
                  }}
                  isDisabled={removeMutation.status === 'pending'}
                  size="sm"
                  type="button"
                >
                  <MdDeleteForever aria-hidden size="1.4rem" />
                  <span className="sr-only">Remove {account.name}</span>
                </Button>
              )}
            </div>
          </li>
        ))}
      </ul>
      <div>
        <Switch isSelected={managing} onChange={(val) => setManaging(val)}>
          Manage account shortcuts
        </Switch>
      </div>
    </div>
  );
}
