/**
 * Copyright © 2021, AMN Healthcare, Inc. All rights reserved.
 */

export const getLogoutMessage = (reason: string | undefined | null) => {
  switch (reason) {
    case 'expired':
      return "You've been logged into another device.";
    case 'forced':
      return "You've been logged out by a manager.";
    case 'timeout':
      return 'Your session has expired.';
    default:
      return null;
  }
};
