/**
 * Copyright © 2021, AMN Healthcare, Inc. All rights reserved.
 */

import { SessionLayout } from './SessionLayout';

type SessionSidebarLayoutProps = {
  title: string;
  children: React.ReactNode;
  sidebar?: React.ReactNode;
};

export function SessionSidebarLayout({
  title,
  sidebar,
  children,
}: SessionSidebarLayoutProps) {
  return (
    <SessionLayout>
      <div className="flex h-full w-full justify-between space-x-8">
        <div className="flex h-full min-w-0 flex-auto flex-col space-y-4 overflow-hidden">
          <h3 className="flex-none text-xl font-medium">{title}</h3>
          {children}
        </div>
        {sidebar && (
          <div className="flex min-w-[12rem] flex-initial flex-col items-end gap-2">
            {sidebar}
          </div>
        )}
      </div>
    </SessionLayout>
  );
}
