/**
 * Copyright © 2021, AMN Healthcare, Inc. All rights reserved.
 */

import { z } from 'zod';

// This manages the username/password in local storage to sync
// with electron.  This is always a bad idea, but we were ensured
// that the username and password are equivalent to license keys
// and the passwords aren't something the hospitals generate.
// See SV-2835 for more details.

type LicenseResult =
  | { type: 'initial' }
  | { type: 'found'; license: { username: string; password: string } }
  | { type: 'not-found' };

const licenseSchema = z.object({
  username: z.string(),
  password: z.string(),
});

const isElectron = navigator.userAgent.indexOf('Electron') >= 0;

export function getLicense(): LicenseResult {
  // Remember Me functionality for the Electron app
  if (!isElectron) return { type: 'not-found' };

  try {
    // backwards compatibility for electron app < 1.1.0
    // loadForSV2835 gets injected from electron app
    let fetchedLicense: unknown;

    if (window.loadForSV2835) {
      fetchedLicense = window.loadForSV2835();
    } else {
      // no longer injecting javascript, but sharing what's in
      // localStorage.
      // Note: `login` is also required for backwards compatibility
      const licenseData = window.localStorage.getItem('login');
      if (licenseData) {
        fetchedLicense = JSON.parse(licenseData);
      }
    }

    const license = licenseSchema.parse(fetchedLicense);

    if (license) {
      return { type: 'found', license: license };
    }
  } catch (_error) {
    // pass
  }

  return { type: 'not-found' };
}

export function setLicense({
  username,
  password,
}: {
  username: string;
  password: string;
}) {
  // Remember Me functionality for the Electron app
  if (!isElectron) return { type: 'not-found' };

  try {
    const license = {
      username: (username || '').substring(0, 256),
      password: (password || '').substring(0, 256),
    };

    // backwards compatibility for electron app < 1.1.0
    // storeForSV2835 gets injected from electron app
    if (window.storeForSV2835) {
      // this gets injected from the Electron app
      window.storeForSV2835(license);
    } else {
      // Note: `login` is also required for backwards compatibility
      var licenseData = JSON.stringify(license);
      window.localStorage.setItem('login', licenseData);
    }
  } catch (_error) {}
}
