/**
 * Copyright © 2021, AMN Healthcare, Inc. All rights reserved.
 */

import React from 'react';
import { Tab, TabList, TabPanel, TabsRoot } from '@/ui/side-tabs';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ZodError } from 'zod';
import { ManageAccounts } from './ManageAccounts';
import { SelectAccounts } from './SelectAccounts';
import { Key } from 'react-aria';

function SwitchAccountsImpl() {
  const [selection, setSelection] = React.useState<Key>('accounts');
  const handleShortcutAdded = React.useCallback(() => {
    setSelection('accounts');
  }, []);

  return (
    <div className="container mx-auto space-y-4 border bg-white p-6">
      <h2 className="text-xl font-medium">Account selection</h2>
      <TabsRoot
        selectedKey={selection}
        onSelectionChange={(value) => setSelection(value)}
      >
        <TabList aria-label="Account selection panels">
          <Tab id="accounts">
            <span>Accounts</span>
          </Tab>
          <Tab id="manage">
            <span>Create account shortcut</span>
          </Tab>
        </TabList>
        <TabPanel id="accounts">
          <SelectAccounts />
        </TabPanel>
        <TabPanel id="manage">
          <ManageAccounts onShortcutAdded={handleShortcutAdded} />
        </TabPanel>
      </TabsRoot>
    </div>
  );
}

const MemoedSwitchAccounts = React.memo(SwitchAccountsImpl);

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: (failureCount, error) => {
        if (error instanceof ZodError) {
          return false;
        }

        return failureCount < 3;
      },
    },
  },
});

export function SwitchAccounts() {
  return (
    <QueryClientProvider client={queryClient}>
      <MemoedSwitchAccounts />
    </QueryClientProvider>
  );
}
