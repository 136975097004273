/*
 * Copyright © 2021, AMN Healthcare, Inc. All rights reserved.
 */

import { Tab, TabList, TabPanel, TabsRoot } from '@/ui/side-tabs';
import { useAuthProfile } from '@/protected/AuthProfileContext';
import { InfoMessage } from '@/ui/info-message';
import * as ContainerLayout from '@/shared/ContainerLayout';
import { SelectField, StyledSelectFieldItem } from '@/ui/fields/select-field';
import { usePreferredVideoResolution } from '@/protected/shared/usePreferredVideoResolution';

function VideoSettings() {
  const [selectedKey, setSelection, options] = usePreferredVideoResolution();

  if (options.length === 0) {
    return (
      <InfoMessage>
        Please contact your account manager to set up your preferred video
        resolution.
      </InfoMessage>
    );
  }

  return (
    <div className="space-y-2">
      <p>These settings are persisted in your browser.</p>

      <SelectField
        name="videoPreference"
        label="Preferred video resolution"
        isRequired={false}
        errorMessage={undefined}
        placeholder="Select a resolution"
        description="Change the preferred video resolution for this device. Resolutions depend on network conditions and may not be guaranteed."
        items={options}
        onSelectionChange={(val) => {
          setSelection(Number(val));
        }}
        selectedKey={selectedKey || ''}
      >
        {(item) => (
          <StyledSelectFieldItem textValue={item.name}>
            <div className="flex items-center space-x-2">
              <span>{item.name}</span>
            </div>
          </StyledSelectFieldItem>
        )}
      </SelectField>
    </div>
  );
}

export function Settings() {
  const profile = useAuthProfile();

  // To be consistent with mobile, we must hide the Video section.
  // This means there are no settings to apply.

  if (profile.enterprise.preferred_video_resolution === null) {
    return (
      <ContainerLayout.Root aria-label="Settings">
        <ContainerLayout.Header>Settings</ContainerLayout.Header>
        <InfoMessage>
          Your enterprise has not been configured for any settings.
        </InfoMessage>
      </ContainerLayout.Root>
    );
  }

  return (
    <ContainerLayout.Root aria-label="Settings">
      <div className="w-full">
        <TabsRoot>
          <TabList aria-label="Support Panels">
            <Tab id="video">Video</Tab>
          </TabList>
          <TabPanel id="video">
            <div className="space-y-4">
              <h2 className="text-lg font-medium">Video Settings</h2>
              <VideoSettings />
            </div>
          </TabPanel>
        </TabsRoot>
      </div>
    </ContainerLayout.Root>
  );
}
