/**
 * Copyright © 2021, AMN Healthcare, Inc. All rights reserved.
 */

import React from 'react';
import { Tab, TabList, TabPanel, TabsRoot } from '@/ui/side-tabs';
import { useAuthProfile } from '@/protected/AuthProfileContext';
import { simpleFormatPhoneNumber } from '@/utils/simpleFormatPhoneNumber';
import { MediaDiagnostics } from '@/shared/MediaDiagnostics';
import { Button } from '@/ui/button';
import { CallStateContext } from '@/protected/ProtectedPage';
import { useNavigate } from 'react-router-dom';
import { SupportPhoneNumber } from '@/constants';
import { useMyEnterpriseLanguages } from '@/protected/apis/protectedApiQueries';
import { svenErrorMessage } from '@/api/getError';
import { AlertMessage } from '@/ui/alert-message';
import { InfoMessage } from '@/ui/info-message';
import { formatLongNoMinutes } from '@/utils/date-formatter';
import {
  type EnterpriseLanguage,
  type Schedule,
  type HolidaySchedule,
} from '@/protected/apis/protectedApiSchemas';
import { Spinner } from '@/ui/spinner';
import * as ContainerLayout from '@/shared/ContainerLayout';
import { DateTime } from 'luxon';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from '@/ui/accordion';

const Version = process.env.REACT_APP_BUILD_VERSION;

const localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

type EnterpriseHoursListProps = {
  languages: EnterpriseLanguage[];
};

type ScheduleProps = {
  languageTimeZone: string;
  schedule: Schedule;
  now: Date;
};

function dayLabel(day: number) {
  switch (day) {
    case 0:
      return 'Sun';
    case 1:
      return 'Mon';
    case 2:
      return 'Tue';
    case 3:
      return 'Wed';
    case 4:
      return 'Thu';
    case 5:
      return 'Fri';
    case 6:
      return 'Sat';
    default:
      return '';
  }
}

function timeParts(time: string) {
  const [h, m, s] = time.split(':').map((n) => Number(n));

  if (h != null && m != null && s != null) {
    return { hour: h, minute: m, second: s };
  }

  throw Error('bad time');
}

// Hopefully these conversions won't be necessary with
// https://stratus-video.atlassian.net/browse/SV-4502

function ScheduleDisplay({ languageTimeZone, now, schedule }: ScheduleProps) {
  // Get the current time in the language's time zone
  const scheduleNow = DateTime.fromJSDate(now).setZone(languageTimeZone);

  // Convert it
  const scheduleStartOfWeek = scheduleNow.startOf('week');

  // Add the start days to the language start of week,
  // set the start time, and switch back to local time
  const start = scheduleStartOfWeek
    .plus({ days: schedule.start_date })
    .set(timeParts(schedule.start_time))
    .setZone(localTimezone);

  // Add the end days to the language start of week,
  // set the end time, and switch back to local time
  const end = scheduleStartOfWeek
    .plus({ days: schedule.end_date })
    .set(timeParts(schedule.end_time))
    .setZone(localTimezone);

  if (
    schedule.is_24_hours ||
    (schedule.start_time === '00:00:00' && schedule.end_time === '23:59:59')
  ) {
    // 24 hours schedule
    if (schedule.start_date === schedule.end_date) {
      return <h4>{dayLabel(schedule.start_date)} 24 hours</h4>;
    } else {
      return (
        <h4>
          {dayLabel(schedule.start_date)} - {dayLabel(schedule.end_date)}, 24
          hours
        </h4>
      );
    }
  } else if (start.hasSame(end, 'day')) {
    // same day schedule
    return (
      <h4>
        {start.toFormat('ccc ha')} - {end.toFormat('h a')}
      </h4>
    );
  } else {
    // different day schedule
    return (
      <h4>
        {start.toFormat('ccc')} - {end.toFormat('ccc')}, {start.toFormat('h a')}{' '}
        - {end.toFormat('h a')}
      </h4>
    );
  }
}

type HolidayScheduleProps = {
  schedule: HolidaySchedule;
};

function holidayAvailability({
  availability_start,
  availability_end,
}: HolidaySchedule) {
  if (availability_start === null) {
    return 'Closed';
  }

  if (availability_start === null && availability_end === null) {
    return 'Closed';
  }

  const start = DateTime.fromISO(availability_start).toFormat('h a');

  if (availability_end === null) {
    return `Available: ${start} -`;
  }

  if (
    availability_start.indexOf('00:00:00') > 0 &&
    availability_end.indexOf('23:59:59') > 0
  ) {
    return 'Available: 24 Hours';
  }

  const end = DateTime.fromISO(availability_end).toFormat('h a');

  return `Available: ${start} - ${end}`;
}

function HolidayScheduleDisplay({ schedule }: HolidayScheduleProps) {
  const availability = holidayAvailability(schedule);

  const windowStart = new Date(schedule.window_start);
  const windowEnd = new Date(schedule.window_end);

  return (
    <div>
      <div className="font-medium">{schedule.holiday_name}</div>
      <div>
        Observed {formatLongNoMinutes(windowStart)} to{' '}
        {formatLongNoMinutes(windowEnd)}
      </div>
      <div>{availability}</div>
    </div>
  );
}

function EnterpriseHoursList({ languages }: EnterpriseHoursListProps) {
  const [now] = React.useState(() => new Date());

  const sortedLanguages = React.useMemo(() => {
    return languages
      .slice()
      .sort((a, b) => a.display_text.localeCompare(b.display_text));
  }, [languages]);

  if (sortedLanguages.length === 0) {
    return <div>No languages available for your enteprrise.</div>;
  }

  return sortedLanguages.map((language) => {
    const holidaySchedules =
      language.schedule_set.holiday_schedule_set?.windows || [];

    const headerId = `support-hours--language-${language.pk}`;

    return (
      <div className="space-y-2" key={language.pk}>
        <h4 className="font-semibold" id={headerId}>
          {language.display_text}
        </h4>
        <div className="text-sm">
          {language.schedule_set.schedules.map((schedule, idx) => (
            <ScheduleDisplay
              key={idx}
              languageTimeZone={language.schedule_set.time_zone}
              schedule={schedule}
              now={now}
            />
          ))}
        </div>
        {holidaySchedules.length > 0 && (
          <Accordion
            type="single"
            collapsible
            className="w-1/2"
            aria-labelledby={headerId}
          >
            <AccordionItem value="holiday-hours">
              <AccordionTrigger className="rounded bg-slate-600 p-1 text-sm text-white">
                Holiday schedule
              </AccordionTrigger>
              <AccordionContent className="bg-slate-200 p-2">
                <div className="space-y-2 text-sm">
                  {holidaySchedules.map((schedule, idx) => (
                    <HolidayScheduleDisplay key={idx} schedule={schedule} />
                  ))}
                </div>
              </AccordionContent>
            </AccordionItem>
          </Accordion>
        )}
      </div>
    );
  });
}

function EnterpriseHours() {
  const query = useMyEnterpriseLanguages();

  switch (query.status) {
    case 'pending':
      return <div className="loading-ellipsis">Loading hours...</div>;
    case 'error':
      return (
        <div>
          <AlertMessage title="Failed to load hours">
            <span>{svenErrorMessage(query.error)}</span>
          </AlertMessage>
        </div>
      );
    case 'success': {
      return (
        <div className="space-y-4">
          <EnterpriseHoursList languages={query.data} />
        </div>
      );
    }
  }
}

function Hours() {
  return (
    <div className="space-y-4">
      <h3 className="text-lg font-medium">Hours of Operation by Language</h3>
      <div className="space-y-2">
        <p className="font-medium">Video Interpreting Hours</p>
        <p className="text-sm">
          Sessions outside of these hours will be transferred to an audio
          interpreter. Languages that are not listed here can be accessed at any
          time by selecting "Audio Languages" on the main screen.
        </p>

        <EnterpriseHours />

        <p className="text-sm text-gray-500">Times in {localTimezone}</p>
      </div>
    </div>
  );
}

function Contact() {
  const actorRef = CallStateContext.useActorRef();
  const navigate = useNavigate();
  const profile = useAuthProfile();

  const supportMessage = profile?.enterprise.support_details;
  const supportNumber =
    profile?.enterprise.support_number || SupportPhoneNumber;

  const callSupport = () => {
    actorRef.send({
      type: 'enqueue.enqueue',
      request: {
        type: 'support',
        requestedTimestamp: new Date(),
      },
    });

    // TODO: optionally, have this be somewhere else, where we
    // auto navigate on entering a session?
    navigate('/consumer/dashboard');
  };

  // The support number is going to be US only for now, so we can use a
  // simple formatter
  const phone = React.useMemo(
    () => simpleFormatPhoneNumber(supportNumber),
    [supportNumber]
  );

  const isSupportEnabled = CallStateContext.useSelector((s) =>
    s.matches({ Session: { none: { Enqueue: 'idle' } } })
  );

  return (
    <div className="space-y-4">
      <p>
        Currently having an issue? Have a suggestion about what we can do
        better? Contact our <em>Customer Success Team</em>.
      </p>

      {supportMessage && (
        <div className="hyphens-auto whitespace-pre-line text-sm break-anywhere">
          {supportMessage}
        </div>
      )}
      <div>
        <span className="font-medium">Phone:</span> {phone}
      </div>

      <div>
        <Button
          onPress={callSupport}
          isDisabled={!isSupportEnabled}
          type="button"
        >
          Connect with support
        </Button>
      </div>

      <div className="text-sm">Version: {Version || 'not available'}</div>
    </div>
  );
}

function SpeedTest() {
  const profile = useAuthProfile();
  const [iframeLoaded, setIframeLoaded] = React.useState(false);

  const url = profile.speedtest_url;

  if (!url) {
    return (
      <div className="space-y-4">
        <InfoMessage>
          <p>A speed test has not been configured for your enterprise.</p>
        </InfoMessage>
      </div>
    );
  }

  return (
    <div className="relative">
      {!iframeLoaded && (
        <div className="absolute inset-0 flex flex-col items-center justify-center space-y-2">
          <div className="flex flex-col items-center justify-center space-y-2 rounded-md bg-white/30 p-4">
            <Spinner size={48} />
            <div>Loading speed test</div>
          </div>
        </div>
      )}

      <iframe
        sandbox="allow-scripts allow-same-origin"
        width="100%"
        height="650px"
        src={url}
        onLoad={() => setIframeLoaded(true)}
        title="Speed Test"
      />
    </div>
  );
}

type TrainingProps = {
  videoUrl: string;
};
function Training({ videoUrl }: TrainingProps) {
  const updateVolume = React.useCallback((x: HTMLVideoElement | null) => {
    if (x) {
      x.volume = 0.75;
    }
  }, []);

  return (
    <div className="space-y-2">
      <h3 className="text-lg font-medium">Training Video</h3>
      <video controls className="w-full max-w-[640px]" ref={updateVolume}>
        <source src={videoUrl} type="video/mp4" />
        No playback available.
      </video>
    </div>
  );
}

export function Support() {
  const profile = useAuthProfile();

  const trainingVideoUrl = profile.enterprise.training_video_url;

  return (
    <ContainerLayout.Root aria-label="Support">
      <ContainerLayout.Header>
        AMN Interpreting Customer Support
      </ContainerLayout.Header>
      <div className="w-full">
        <TabsRoot>
          <TabList aria-label="Support Panels">
            <Tab id="contact">Contact</Tab>
            <Tab id="hours">Language Hours</Tab>
            <Tab id="diagnostics">Media Diagnostics</Tab>
            {trainingVideoUrl && <Tab id="training">Training Videos</Tab>}
            <Tab id="speedtest">Speed test</Tab>
          </TabList>
          <TabPanel id="contact">
            <Contact />
          </TabPanel>
          <TabPanel id="hours">
            <Hours />
          </TabPanel>
          {trainingVideoUrl && (
            <TabPanel id="training">
              <Training videoUrl={trainingVideoUrl} />
            </TabPanel>
          )}
          <TabPanel id="diagnostics">
            <MediaDiagnostics />
          </TabPanel>
          <TabPanel id="speedtest">
            <SpeedTest />
          </TabPanel>
        </TabsRoot>
      </div>
    </ContainerLayout.Root>
  );
}
