/**
 * Copyright © 2021, AMN Healthcare, Inc. All rights reserved.
 */

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {
  Navigate,
  Outlet,
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import { hrefBase } from '@/constants';
import { useSearchParams } from 'react-router-dom';
import { svenBrandingRecordSchema } from '@/types/svenBranding';
import { AuthCheckProvider } from '@/auth/AuthCheckContext';
import { Logout } from '@/auth/Logout';
import { Login } from '@/auth/Login';
import { Signin } from '@/auth/Signin';
import { ProtectedPage } from './protected/ProtectedPage';
import { Support } from './protected/Support';
import { History } from './protected/History';
import { Settings } from './protected/Settings';
import { SessionRouter } from './protected/Session/SessionRouter';
import { RequestLogout } from './protected/RequestLogout';
import { HeaderNavLink, Header } from '@/shared/Header';
import { BasicLayout } from '@/shared/BasicLayout';
import { AccountsLayout } from '@/shared/AccountsLayout';
import { RequireAuth, CheckAuth } from './auth/AuthCheckers';
import { ResetPassword } from './auth/ResetPassword';
import { ForgotPassword } from './auth/ForgotPassword';
import { PublicSupport } from './PublicSupport';
import { RootNotFound } from './RootNotFound';
import { PublicNotFound } from './PublicNotFound';
import { ProtectedNotFound } from './protected/ProtectedNotFound';
import { SwitchAccounts } from './protected/account-selection/SwitchAccounts';

function UnprotectedLayout() {
  return (
    <BasicLayout
      header={
        <Header
          navLinks={
            <ul className="box-border flex list-none items-center space-x-1">
              <li>
                <HeaderNavLink to="login">Login</HeaderNavLink>
              </li>
              <li>
                <HeaderNavLink to="support">Support</HeaderNavLink>
              </li>
            </ul>
          }
        />
      }
      body={
        <div className="container mx-auto">
          <div className="px-2 py-4">
            <Outlet />
          </div>
        </div>
      }
    />
  );
}

const getRootTitle = () => {
  let defaultTitle = 'AMN Interpreting';

  const branding = svenBrandingRecordSchema.safeParse(window.svenBranding);

  if (branding.success) {
    const title = branding.data['title'];

    if (title && title.type === 'Label') {
      return title.value.content;
    }
  }

  return defaultTitle;
};

export type RouteHandle = {
  title?: string;
};

function createHandle({ title }: RouteHandle): RouteHandle {
  return { title: title };
}

// Navigates to the Consumer, where the Consumer will do the auth check
// and allow or redirect accordingly.
function NavigateToConsumer() {
  // The SSO callback URL is /oidc_api/code in SVEN
  //
  // The Django action will redirect the user to the root of this app
  // with the `sso_error` querystring parameter.
  //
  // We must carry this sso_error to the Login page, where it gets displayed
  // to the user.
  const [params] = useSearchParams();
  const ssoError = params.get('sso_error');

  return <Navigate to="/consumer" replace state={{ ssoError: ssoError }} />;
}

let router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<App />} handle={createHandle({ title: getRootTitle() })}>
      <Route path="/" element={<NavigateToConsumer />} />

      <Route
        path="/logout"
        element={<Logout />}
        handle={createHandle({ title: 'Logout' })}
      />

      <Route element={<UnprotectedLayout />} path="public">
        <Route index element={<Navigate to="login" replace />} />

        <Route element={<CheckAuth />}>
          <Route
            path="login"
            element={<Login />}
            handle={createHandle({ title: 'Log in' })}
          />
          <Route
            path="signin"
            element={<Signin />}
            handle={createHandle({ title: 'Sign in' })}
          />
        </Route>

        {/* legacy route naming */}
        <Route
          path="forgot/password"
          element={<ForgotPassword />}
          handle={createHandle({ title: 'Forgot password' })}
        />

        {/* legacy route naming */}
        <Route
          path="change/password"
          element={<ResetPassword />}
          handle={createHandle({ title: 'Change password' })}
        />

        <Route
          path="support"
          element={<PublicSupport />}
          handle={createHandle({ title: 'Support' })}
        />
        <Route
          path="*"
          element={<PublicNotFound />}
          handle={createHandle({ title: 'Not Found' })}
        />
      </Route>

      <Route element={<RequireAuth />}>
        <Route path="/home" element={<Navigate to="/consumer" replace />} />
        <Route path="/accounts" element={<AccountsLayout />}>
          <Route
            path="select"
            element={<SwitchAccounts />}
            handle={createHandle({ title: 'Switch accounts' })}
          />
          <Route
            path="support"
            element={<PublicSupport />}
            handle={createHandle({ title: 'Support' })}
          />
        </Route>
        <Route path="/consumer" element={<ProtectedPage />}>
          <Route
            path="request-logout"
            element={<RequestLogout />}
            handle={createHandle({ title: 'Logging out' })}
          />
          <Route index element={<Navigate to="dashboard" replace />} />
          <Route
            path="dashboard"
            element={<SessionRouter />}
            handle={createHandle({ title: 'Interpreting' })}
          />
          <Route
            path="support"
            element={<Support />}
            handle={createHandle({ title: 'Support' })}
          />
          <Route
            path="history"
            element={<History />}
            handle={createHandle({ title: 'Session History' })}
          />
          <Route
            path="settings"
            element={<Settings />}
            handle={createHandle({ title: 'Settings' })}
          />
          <Route
            path="*"
            element={<ProtectedNotFound />}
            handle={createHandle({ title: 'Not Found' })}
          />
        </Route>
        <Route
          path="*"
          element={<RootNotFound />}
          handle={createHandle({ title: 'Not Found' })}
        />
      </Route>
    </Route>
  ),
  { basename: hrefBase }
);

const root = ReactDOM.createRoot(
  document.getElementById('sven-app') as HTMLElement
);

root.render(
  <React.StrictMode>
    <AuthCheckProvider>
      <RouterProvider router={router} />
    </AuthCheckProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
