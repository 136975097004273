/**
 * Copyright © 2021, AMN Healthcare, Inc. All rights reserved.
 */

import { ParsedError, HTTPError, TimeoutError } from './api';
import { messages } from '@/constants';
import { z } from 'zod';

type TransformFunction = (json: unknown, status: number) => string | null;

type GetErrorMessageOptions = {
  transform?: TransformFunction;
};

const getErrorMessage = (
  error: unknown,
  options: GetErrorMessageOptions = {}
): string => {
  const { transform } = options;

  if (error instanceof z.ZodError) {
    console.error('getError: Unexpected backend response', error);
    return messages.BACKEND_MISMATCH;
  }

  if (transform) {
    let message: string | null = null;

    if (error instanceof ParsedError) {
      message = transform(error.json, error.response.status);
    } else if (error instanceof HTTPError) {
      message = transform(null, error.response.status);
    }

    if (message != null) {
      return message;
    }
  }

  if (error instanceof HTTPError) {
    if (error.response.status === 401) {
      return messages.NOT_AUTHORIZED;
    }

    if (error.response.status === 403) {
      return messages.FORBIDDEN;
    }

    return messages.BACKEND_ERROR;
  } else if (error instanceof TimeoutError) {
    return messages.TIMEOUT;
  }

  return messages.UNEXPECTED_ERROR;
};

const svenMessageTransform: TransformFunction = (json) => {
  const errorSchema = z.object({ message: z.string() });

  const result = errorSchema.safeParse(json);

  if (result.success) {
    return result.data.message;
  } else {
    console.error(
      'svenMessageTransform: Unable to parse error message',
      result
    );
  }

  return null;
};

const svenErrorMessage = (error: unknown) =>
  getErrorMessage(error, {
    transform: svenMessageTransform,
  });

export { getErrorMessage, svenMessageTransform, svenErrorMessage };
