/**
 * Copyright © 2021, AMN Healthcare, Inc. All rights reserved.
 */

import React from 'react';
import {
  svenBrandingRecordSchema,
  type SvenBranding,
} from '@/types/svenBranding';

// The actual names used in the system
type BrandedImageName =
  | 'login_logo_top'
  | 'footer_image_left'
  | 'footer_image_center'
  | 'footer_image_right';

type Props = Omit<
  React.ImgHTMLAttributes<HTMLImageElement>,
  'src' | 'children'
> & {
  itemName: BrandedImageName;
  itemSize: keyof Extract<SvenBranding, { type: 'Image' }>['value'];
  alt: string;
};

export type BrandedImageProps =
  | (Props & { src: string; children?: never })
  | (Props & { src?: never; children: React.ReactNode });

const branding = svenBrandingRecordSchema.safeParse(window.svenBranding);

// Displays a branded image, but will fallback to either an image `src`
// or what's in `children`
export function BrandedImage({
  src,
  children,
  itemName,
  itemSize,
  alt,
  ...imageProps
}: BrandedImageProps) {
  let imageSrc = src;

  if (branding.success) {
    const item = branding.data[itemName];
    if (item && item.type === 'Image') {
      imageSrc = item.value[itemSize];
    }
  }

  if (imageSrc) {
    return <img {...imageProps} src={imageSrc} alt={alt} />;
  }

  return children;
}
