/**
 * Copyright © 2021, AMN Healthcare, Inc. All rights reserved.
 */

import React from 'react';
import { SelectField, StyledSelectFieldItem } from '@/ui/fields/select-field';
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';
import { Button } from '@/ui/button';
import { AlertMessage } from '@/ui/alert-message';
import { SuccessMessage } from '@/ui/success-message';
import { svenErrorMessage } from '@/api/getError';
import { MdFolderOpen, MdStar, MdStarBorder } from 'react-icons/md';
import { fetchTiers, addFavorite } from './accountFetchers';
import type { Tiers } from './accountFetchers';
import { accountKey, useTierFavorites } from './accountQueries';
import * as R from 'remeda';
import { Key } from 'react-aria';

type Option = {
  name: string;
  id: Key;
  isSelectable: boolean;
  parent: Key | null;
};

type TierName = string;

type Slot = {
  id: string;
  name: string;
  selection: Key;
  options: {
    data: Option[];
    type: 'pending' | 'fulfilled';
  };
  next: Slot | undefined;
};

const MakeTierOptions = (tiers: Tiers) => {
  let allTiers: Map<Key, Option> = new Map();
  let tierOptions: Map<string, Option[]> = new Map();

  const makeKey = (tierName: TierName, key: Key) => `${tierName}~${key}`;

  Object.entries(tiers.enterprise_tiers).forEach(([tierName, tier]) => {
    tier.enterprises.forEach((enterprise) => {
      const parentEnterprise = enterprise.parent_enterprise;

      const option: Option = {
        name: enterprise.name,
        id: enterprise.id,
        parent: enterprise.parent_enterprise,
        isSelectable: enterprise.is_selectable,
      };

      allTiers.set(enterprise.id, option);

      if (parentEnterprise) {
        const key = makeKey(tierName as TierName, parentEnterprise);
        const options = tierOptions.get(key) || [];
        tierOptions.set(key, [...options, option]);
      }
    });
  });

  return {
    getByOptionId: (id: Key) => {
      return allTiers.get(id);
    },

    getTierOptions: (tierName: TierName, parentSelection: Key) => {
      return tierOptions.get(makeKey(tierName, parentSelection)) || [];
    },
  };
};

const EmptySelection: Key = '';

function tiersToSlots(
  tiers: Tiers,
  firstName: TierName = 'tier_1'
): { head: Slot | undefined; tierOptions: ReturnType<typeof MakeTierOptions> } {
  let lastSlot: Slot | undefined = undefined;

  const tierOptions = MakeTierOptions(tiers);

  Object.entries(tiers.enterprise_tiers)
    .reverse()
    .forEach(([tierName, tier]) => {
      const options: Slot['options'] =
        tierName === firstName
          ? {
              type: 'fulfilled',
              data: tierOptions.getTierOptions(
                firstName,
                tiers.root_enterprise.id
              ),
            }
          : { type: 'pending', data: [] };

      const slot: Slot = {
        id: tierName,
        name: tier.name,
        options: options,
        next: lastSlot,
        selection: EmptySelection,
      };

      lastSlot = slot;
    });

  return { head: lastSlot, tierOptions: tierOptions };
}

// After selecting a tier, this will
// 1) update the options for the next tiers
//    - the immeidate sibling will get their options from the current selection
//    - and all subsequent sibilings will be set to the empty list
// 2) clear the selection for the next tiers
function updateSlotSelection(
  head: Slot,
  id: string,
  selection: Key,
  tierOptions: ReturnType<typeof MakeTierOptions>
) {
  type UpdateProps = {
    selection: Key;
    newOptions: Slot['options'] | undefined;
    current: Slot;
    hasMatched: boolean;
  };

  function update({
    selection,
    newOptions,
    current,
    hasMatched,
  }: UpdateProps): Slot {
    if (current.id === id) {
      return {
        ...current,
        selection: selection,
        options: newOptions || current.options,
        // clear the next selection and setup
        // the new options
        next: current.next
          ? update({
              selection: EmptySelection,
              newOptions: {
                type: 'fulfilled',
                data: tierOptions.getTierOptions(
                  current.next.id as TierName,
                  selection
                ),
              },
              current: current.next,
              hasMatched: true,
            })
          : undefined,
      };
    }

    if (!hasMatched) {
      return {
        ...current,
        // keep the same info
        next: current.next
          ? update({
              selection,
              newOptions: newOptions,
              current: current.next,
              hasMatched,
            })
          : undefined,
      };
    }

    const nextOptions: Slot['options'] =
      newOptions?.type === 'fulfilled' && newOptions.data.length === 0
        ? { type: 'fulfilled', data: [] }
        : { type: 'pending', data: [] };

    // we've matched, so clear things going forward
    return {
      ...current,
      selection: selection,
      options: newOptions || nextOptions,
      next: current.next
        ? // clear anything going forward
          update({
            selection: EmptySelection,
            newOptions: nextOptions,
            current: current.next,
            hasMatched: true,
          })
        : undefined,
    };
  }

  return update({
    selection,
    newOptions: undefined,
    current: head,
    hasMatched: false,
  });
}

function traverseSlots(slot: Slot) {
  let slots: Slot[] = [];
  let current: Slot | undefined = slot;

  do {
    slots.push(current);
    current = current.next;
  } while (current);

  return slots;
}

const getEmptyMessage = (slot: Slot) => {
  if (slot.options.data.length > 0) {
    return 'Select an account';
  }

  if (slot.options.type === 'pending') {
    return 'Waiting for parent selection';
  }

  return 'No accounts available for selection';
};

type ManageTiersProps = { tiers: Tiers; onShortcutAdded: () => void };

function ManageTiers({ tiers, onShortcutAdded }: ManageTiersProps) {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: (id: number) => {
      return addFavorite(id);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: accountKey });
    },
  });

  const favoriteResult = useTierFavorites();

  // Used to determine if we should fill in the star icon
  const favoritesSet = React.useMemo(() => {
    if (!favoriteResult.data) {
      return new Set([]);
    }

    return new Set(favoriteResult.data.child_accounts.map((x) => x.id));
  }, [favoriteResult.data]);

  const [slotData, setSlotData] = React.useState(() => {
    const { head, tierOptions } = tiersToSlots(tiers);

    if (head) {
      return { head: head, slots: traverseSlots(head), tierOptions };
    }

    return { head: undefined, slots: [] as Slot[], tierOptions };
  });

  const update = (slot: Slot, selection: Key) => {
    setSlotData(({ head, tierOptions }) => {
      if (head) {
        const result = updateSlotSelection(
          head,
          slot.id,
          selection,
          tierOptions
        );
        const data = traverseSlots(result);
        return { tierOptions, head: result, slots: data };
      }

      return { tierOptions, head: undefined, slots: [] as Slot[] };
    });
  };

  const { slots, tierOptions } = slotData;

  const selection = R.pipe(
    slots,
    R.findLast((x) => x.selection !== EmptySelection)
  );
  const selected = selection?.selection
    ? tierOptions.getByOptionId(selection.selection)
    : undefined;
  const canSelect = Boolean(selected?.isSelectable);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const id = selection?.selection;

    if (id === undefined || id === '') return;

    mutation.mutate(Number(id));
  };

  React.useEffect(() => {
    if (mutation.status === 'success') {
      onShortcutAdded();
    }
  }, [mutation, onShortcutAdded]);

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      {mutation.status === 'error' && (
        <AlertMessage title="Unable to add shortcut">
          <p>{svenErrorMessage(mutation.error)}</p>
        </AlertMessage>
      )}

      {mutation.status === 'success' && (
        <SuccessMessage title="Shortcut added">
          <p>Shortcut has been successfully added.</p>
        </SuccessMessage>
      )}

      <div className="space-y-2">
        {slots.map((slot) => (
          <SelectField
            key={slot.id}
            name={slot.id}
            label={slot.name}
            isRequired={false}
            errorMessage={undefined}
            placeholder={getEmptyMessage(slot)}
            items={slot.options.data}
            onSelectionChange={(val) => {
              update(slot, val);
            }}
            selectedKey={slot.selection}
          >
            {(item) => (
              <StyledSelectFieldItem textValue={item.name}>
                <div className="flex items-center space-x-2">
                  {item.isSelectable ? (
                    favoritesSet.has(Number(item.id)) ? (
                      <MdStar size="1.2rem" aria-hidden />
                    ) : (
                      <MdStarBorder size="1.2rem" aria-hidden />
                    )
                  ) : (
                    <MdFolderOpen size="1.2rem" aria-hidden />
                  )}
                  <span>{item.name}</span>
                </div>
              </StyledSelectFieldItem>
            )}
          </SelectField>
        ))}

        <div className="flex items-center space-x-2">
          <div>
            <Button type="submit" isDisabled={mutation.status === 'pending'}>
              Add shortcut
            </Button>
          </div>
          {selection && !canSelect && (
            <div className="text-sm text-red-500">
              {selection.next
                ? `${selection.next.name} selection required`
                : 'Account not selectable'}
            </div>
          )}
        </div>
      </div>
    </form>
  );
}

type ManageProps = {
  onShortcutAdded: () => void;
};

export function ManageAccounts({ onShortcutAdded }: ManageProps) {
  const result = useQuery({
    queryKey: ['tiers'],
    queryFn: fetchTiers,
    retry: 0,
    staleTime: Infinity,
  });

  switch (result.status) {
    case 'error':
      return (
        <div>
          <AlertMessage title="Unable to fetch accounts">
            <p>{svenErrorMessage(result.error)}</p>
          </AlertMessage>
        </div>
      );
    case 'pending':
      return <div className="loading-ellipsis">Loading shortcuts</div>;
    case 'success':
      return (
        <ManageTiers tiers={result.data} onShortcutAdded={onShortcutAdded} />
      );
  }
}
