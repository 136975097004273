/**
 * Copyright © 2021, AMN Healthcare, Inc. All rights reserved.
 */

import React from 'react';
import { HeaderNavLink, Header } from '@/shared/Header';
import { BasicLayout } from '@/shared/BasicLayout';
import { Outlet, useNavigate } from 'react-router-dom';
import { MenuContent, MenuItem, MenuTrigger, MenuPopover } from '@/ui/menu';
import { MdSettings, MdLogout } from 'react-icons/md';
import { Button as RACButton } from 'react-aria-components';
import { useAuthProfile } from '@/protected/AuthProfileContext';
import { ProtectedFooter } from '@/protected/shared/ProtectedFooter';

enum UserMenu {
  signOut = 'sign-out',
}

export function AccountsLayout() {
  const navigate = useNavigate();
  const profile = useAuthProfile();

  // only let users that can change their enterprise access these
  // routes.
  React.useEffect(() => {
    if (!profile.login_enterprise?.must_choose_subaccount) {
      navigate('/');
    }
  }, [navigate, profile]);

  const handleAction = (action: React.Key) => {
    switch (action) {
      case UserMenu.signOut: {
        navigate('/consumer/request-logout');
        return;
      }
    }
  };

  if (!profile.login_enterprise?.must_choose_subaccount) {
    return null;
  }

  return (
    <BasicLayout
      header={
        <Header
          navLinks={
            <ul className="box-border flex list-none items-center space-x-1">
              <li>
                <HeaderNavLink to="/accounts/select">
                  Account selection
                </HeaderNavLink>
              </li>
              <li>
                <HeaderNavLink to="/accounts/support">Support</HeaderNavLink>
              </li>
            </ul>
          }
          userButton={
            <MenuTrigger>
              <RACButton className="flex h-full w-full flex-row items-center gap-3 px-4 pr-6 focus:outline-none focus-visible:ring-2 focus-visible:ring-inset focus-visible:ring-white ">
                <MdSettings size="1.5rem" aria-hidden />
                <span className="sr-only">Settings and more for</span>
                <span className="truncate">{profile.user.username}</span>
              </RACButton>
              <MenuPopover>
                <MenuContent onAction={handleAction}>
                  <MenuItem id={UserMenu.signOut}>
                    Sign out
                    <MdLogout aria-hidden />
                  </MenuItem>
                </MenuContent>
              </MenuPopover>
            </MenuTrigger>
          }
        />
      }
      body={
        <>
          <div className="container mx-auto">
            <div className="px-2 py-4">
              <Outlet />
            </div>
          </div>
          <ProtectedFooter />
        </>
      }
    />
  );
}
