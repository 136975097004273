/**
 * Copyright © 2021, AMN Healthcare, Inc. All rights reserved.
 */

import { LeaveSessionButton } from './ui/LeaveSessionButton';
import { SessionSidebarLayout } from './SessionSidebarLayout';
import { InfoMessage } from '@/ui/info-message';
import { useSession } from './SessionContext';

export function SessionAttachedRequeueing() {
  const session = useSession();

  const toLanguage = session.sessionDetails.to_language;
  const header = toLanguage
    ? `Requeue to ${toLanguage.name}`
    : 'Requeue in progress...';

  return (
    <SessionSidebarLayout
      title={header}
      sidebar={<LeaveSessionButton>Cancel</LeaveSessionButton>}
    >
      <InfoMessage title="Requeuing">
        Please wait while we fulfill your request for an interpreter.
      </InfoMessage>
    </SessionSidebarLayout>
  );
}
