/**
 * Copyright © 2021, AMN Healthcare, Inc. All rights reserved.
 */

import React from 'react';
import { MdMailOutline, MdArrowBack } from 'react-icons/md';
import { type Notification } from '@/protected/apis/protectedApiSchemas';
import { formatLongFriendly } from '@/utils/date-formatter';
import { Button } from 'react-aria-components';
import * as Modal from '@/ui/modal';
import {
  Row,
  Column,
  Cell,
  Table,
  TableBody,
  TableHeader,
} from '@/ui/simple-table';
import { useNotifications } from '@/protected/apis/protectedApiQueries';
import * as R from 'remeda';
import { cn } from '@/utils/cn';
import * as L from 'luxon';

// Colors for the notification button.  The colors were chosen by the
// product owners.
const colorMap = [
  '#5f1412',
  '#005b96',
  '#575107',
  '#22073a',
  '#344e0a',
  '#472963',
  '#2b6946',
  '#8f4668',
  '#003c3e',
  '#944637',
  '#004378',
  '#663f06',
  '#3b6187',
  '#502d00',
  '#003e58',
  '#41000a',
  '#655a70',
  '#341c00',
  '#633554',
  '#364b6f',
];

function isBeforeOneDayAgo(notification: EnhancedNotification, now: Date) {
  const scheduledDT = L.DateTime.fromJSDate(notification.scheduledDate);
  const diff = L.DateTime.fromJSDate(now).diff(scheduledDT, ['days']);
  return diff.days > 1;
}

type EnhancedNotification = Notification & {
  scheduledDate: Date;
  expiresDate: Date;
};

type NotificationDialogProps = {
  notifications: Array<EnhancedNotification>;
};

// We're either viewing the table or a single notification
type View =
  | { type: 'table' }
  | { type: 'single'; notification: EnhancedNotification };

function NotificationsDialog({ notifications }: NotificationDialogProps) {
  const [view, setView] = React.useState<View>({ type: 'table' });
  const [now] = React.useState(() => new Date());

  return (
    <Modal.Dialog>
      <div className="space-y-4">
        <Modal.Heading>Notifications</Modal.Heading>

        {view.type === 'single' && (
          <div>
            <div className="flex items-center justify-between">
              <h3>Code: {view.notification.notification_code}</h3>
              <Button
                type="button"
                onPress={() => setView({ type: 'table' })}
                className="flex items-center space-x-1 font-medium text-blue-600 outline-none hover:bg-gray-100 active:bg-gray-200 rac-focus-visible:ring-2 rac-focus-visible:ring-ring"
              >
                <MdArrowBack />
                <span>Back</span>
              </Button>
            </div>
            <div className="space-y-1">
              <h4 className="text-lg font-medium">
                {view.notification.sent_title}
              </h4>
              <div className="text-sm text-gray-700">
                {formatLongFriendly(view.notification.scheduledDate)}
              </div>
              <div className="whitespace-pre-line">
                {view.notification.sent_content}
              </div>
            </div>
          </div>
        )}

        {view.type === 'table' && (
          <div>
            <Table
              aria-label="Notifications"
              className="w-full max-w-full table-fixed overflow-hidden shadow-sm"
            >
              <TableHeader>
                <Column id="code" isRowHeader className="w-[10%]">
                  Code
                </Column>
                <Column id="sent" className="w-[15%]">
                  Sent date
                </Column>
                <Column id="title" className="w-[25%]">
                  Title
                </Column>
                <Column id="message" className="w-[50%]">
                  Message
                </Column>
              </TableHeader>
              <TableBody
                items={notifications}
                renderEmptyState={() => 'All caught up on notifications.'}
                className={(f) =>
                  f.isEmpty
                    ? '[&>tr>td]:px-4 [&>tr>td]:py-2 [&>tr>td]:italic'
                    : ''
                }
              >
                {(item) => (
                  <Row
                    className={cn(
                      isBeforeOneDayAgo(item, now) ? 'bg-gray-200' : 'bg-white'
                    )}
                  >
                    <Cell className="truncate">
                      <Button
                        type="button"
                        className="underline"
                        onPress={() =>
                          setView({
                            type: 'single',
                            notification: item,
                          })
                        }
                      >
                        {item.notification_code}
                      </Button>
                    </Cell>
                    <Cell className="truncate">
                      {formatLongFriendly(item.scheduledDate)}
                    </Cell>
                    <Cell className="truncate">{item.sent_title}</Cell>
                    <Cell className="truncate">{item.sent_content}</Cell>
                  </Row>
                )}
              </TableBody>
            </Table>
          </div>
        )}
      </div>
    </Modal.Dialog>
  );
}

/**
 * A button that shows the number of notifications, colored according to the
 * color index of the last notification (set by the backend).
 *
 * Clicking the button will bring up a modal of notifications.
 */
export function NotificationsButton() {
  const result = useNotifications();

  // The button details are determined by the most recent notification
  const [buttonDetails, notifications] = React.useMemo(() => {
    if (result.data === undefined) {
      return [];
    }

    const sortedNotifications = R.pipe(
      result.data.notifications,
      R.map((notification) => ({
        ...notification,
        scheduledDate: new Date(notification.scheduled_send_date),
        expiresDate: new Date(notification.expires_date),
      })),
      R.sortBy(
        [(notification) => notification.scheduledDate, 'desc'],
        [(notification) => notification.id, 'desc']
      )
    );

    const lastNotification = R.last(sortedNotifications);

    if (!lastNotification) return [];

    return [
      {
        count: sortedNotifications.length,
        bgColor: colorMap[lastNotification.color_index] || 'black',
        fgColor: 'white',
      },
      sortedNotifications,
    ];
  }, [result.data]);

  if (!buttonDetails || !notifications) return null;

  return (
    <Modal.DialogTrigger>
      <Button
        type="button"
        className="flex items-center space-x-1 rounded-sm border border-gray-200 p-0 px-2 py-1 leading-none text-gray-200 focus:outline-none rac-hover:border-white rac-hover:text-white rac-focus-visible:ring-2 rac-focus-visible:ring-white"
        style={{
          background: buttonDetails.bgColor,
        }}
        aria-label="Notification center"
      >
        <MdMailOutline aria-hidden /> <span>{buttonDetails.count}</span>
      </Button>
      <Modal.Root isDismissable>
        <NotificationsDialog notifications={notifications} />
      </Modal.Root>
    </Modal.DialogTrigger>
  );
}
