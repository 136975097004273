/**
 * Copyright © 2021, AMN Healthcare, Inc. All rights reserved.
 */

type SuccessMessageProps = { title?: string; children: React.ReactNode };

export function SuccessMessage({ children, title }: SuccessMessageProps) {
  return (
    <div
      className="border border-l-4 border-green-400 bg-green-100 p-2 text-green-900"
      role="alert"
    >
      {title && <p className="font-bold">{title}</p>}
      {children}
    </div>
  );
}
