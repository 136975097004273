/**
 * Copyright © 2021, AMN Healthcare, Inc. All rights reserved.
 */

// Logs the event to new relic
export function noticeError(error: Error, customAttributes?: object) {
  try {
    window.newrelic?.noticeError(error, customAttributes);
  } catch (_) {}
}
