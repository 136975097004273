/**
 * Copyright © 2021, AMN Healthcare, Inc. All rights reserved.
 */

// See https://aws.amazon.com/blogs/architecture/exponential-backoff-and-jitter/ for
// more about jitter.
//
// attemptCount: current retry attempt.  attempts start at 1.
// options:
// - factor:
//   - amount: exponential backoff, should be greater than 1.  defaults to 2.5.
//   - cappedDelay: sets the maximum total delay.  defaults to undefined.  defaults to 120s.
//                  it is recommended to use a cappedDelay when maxAttempts isn't set.
// - maxAttempts: returns null when attemptCount > maxAttempts.  defaults to undefined.
// - retryInterval: time between attempts (also used as the factor multiplier).  defaults to 1500.
// - jitter: takes a number from the minJitterDelay and the calculated delay.  defaults to true.
// - minJitterDelay: sets a miniminum on the delay when jitter is enabled.  defaults to 100.

const random = (min: number, max: number) => Math.random() * (max - min) + min;

export type BackoffOptions = {
  factor?: { amount: number; cappedDelay?: number };
  jitter?: boolean;
  maxAttempts?: number;
  minJitterDelay?: number;
  retryInterval?: number;
};

export function backoff(
  attemptCount: number,
  {
    factor = { amount: 2.5, cappedDelay: 120_000 },
    jitter = true,
    maxAttempts,
    minJitterDelay = 100,
    retryInterval = 1_000,
  }: BackoffOptions
): number | null {
  if (maxAttempts !== undefined && attemptCount > maxAttempts) {
    return null;
  }

  let delay = retryInterval;

  if (factor) {
    // exponential factor decay
    delay *= Math.pow(factor.amount, attemptCount - 1);

    if (factor.cappedDelay) {
      // cap the delay
      delay = Math.min(delay, factor.cappedDelay);
    }
  }

  if (jitter) {
    if (minJitterDelay < delay) {
      // jitter the value, by choosing a value from minJitter
      // delay (default 0) to the delay.
      delay = Math.floor(random(minJitterDelay, delay + 1));
    } else {
      return minJitterDelay;
    }
  }

  return Math.round(delay);
}
